import React, {useState} from "react";
import {Alert, Box, Button, ColumnLayout, Modal, SpaceBetween} from "@amzn/awsui-components-react-v3";
import {Input} from "@amzn/awsui-components-react-v3/polaris";
import constants from "../../../constants/strings";

export interface MatrixDeleteModalProps {
    buttonName: string;
    buttonLoading?: boolean;
    onClickConfirm: () => void;
    buttonClassName?: string;
    alertMessage?: string;
}

// TODO: Add reason / comment for delete to store in Audit Trial.
export default (props: MatrixDeleteModalProps) => {
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
    const [deleteInputText, setDeleteInputText] = useState(constants.EMPTY_STRING);
    const deleteConsentText = "confirm";
    const inputMatchesConsentText = deleteInputText.toLowerCase() === deleteConsentText;
    return (
        <>
            {
                <Button className={props.buttonClassName} variant="primary" loading={props.buttonLoading} onClick={() => setIsDeleteModalVisible(true)}>{props.buttonName}</Button>
            }
            <Modal
                visible={isDeleteModalVisible}
                onDismiss={() => setIsDeleteModalVisible(false)}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setIsDeleteModalVisible(false)}>{constants.CANCEL}</Button>
                            <Button variant="primary"
                                    disabled={!inputMatchesConsentText}
                                    onClick={() => {setIsDeleteModalVisible(false); props.onClickConfirm()}}
                            >
                                {constants.DELETE}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={"Please confirm before proceeding."}
            >
                <div>
                    {
                        <SpaceBetween size="m">
                            <Alert
                                type={"warning"}
                                header={"Known issues / limitations"}
                            >
                                {props.alertMessage || constants.DEFAULT_ALERT_MESSAGE}
                            </Alert>
                            <Box variant="p">
                                <Box variant="span">
                                    <Box variant="span" fontWeight="bold">{constants.DELETE}</Box>
                                    {" when clicked will "}
                                    <Box variant="span" fontWeight="bold">{"delete"}</Box>
                                    {" values from the backend. Please click "}
                                    <Box variant="span" fontWeight="bold">{constants.DELETE}</Box>
                                    {" with caution."}
                                </Box>
                            </Box>
                            <SpaceBetween size="s">
                                <Box>{"To avoid accidental deletions we ask you to provide additional written consent."}</Box>
                                <Box variant="span">
                                    {"Type "}
                                    <Box variant="span" fontWeight="bold">
                                        {deleteConsentText}
                                    </Box>
                                    {" to agree."}
                                </Box>
                            </SpaceBetween>
                            <ColumnLayout columns={2}>
                                <Input
                                    placeholder={deleteConsentText}
                                    onChange={event => setDeleteInputText(event.detail.value)}
                                    value={deleteInputText}
                                    ariaRequired={true}
                                />
                            </ColumnLayout>
                        </SpaceBetween>
                    }
                </div>
            </Modal>
        </>
    );
}
