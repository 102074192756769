import * as React from "react";
import {Button, Container, FlashbarProps, Form, Header, Input, SpaceBetween} from "@amzn/awsui-components-react-v3/polaris";
import {ChargeGroupConfigEditProps, ChargeGroupConfigProps} from "../../../interfaces";
import {DEFAULT_CHARGE_GROUP_CONFIG, DEFAULT_EMPTY_FLASH_MESSAGE} from "../../../constants/objects";
import constants from "../../../constants/strings";
import {AxiosError, AxiosResponse} from "axios";
import {placePostRequest} from "../../../utils";
import {isChargeGroupConfigsValid} from "../../../utils/errorChecks";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";
import MatrixFlashMessage from "../CommonComponents/MatrixFlashMessage";


function ChargeGroupConfigAddOrEdit(props: ChargeGroupConfigEditProps) {
    const [chargeGroup, setChargeGroup] = React.useState(constants.EMPTY_STRING);
    const [clientId, setClientId] = React.useState(constants.EMPTY_STRING);
    const [clientProgram, setClientProgram] = React.useState(constants.EMPTY_STRING);
    const [costCenterType, setCostCenterType] = React.useState(constants.EMPTY_STRING);
    const [businessUnitId, setBusinessUnitId] = React.useState(constants.EMPTY_STRING);
    const [contractualLocationNodeId, setContractualLocationNodeId] = React.useState(constants.EMPTY_STRING);
    const [contractualLocationNamespace, setContractualLocationNamespace] = React.useState(constants.EMPTY_STRING);
    const [planner, setPlanner] = React.useState(constants.EMPTY_STRING);
    const [message, setMessage] = React.useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE)


    function saveChargeGroupConfig(chargeGroupConfig: ChargeGroupConfigProps) {
        if (!isChargeGroupConfigsValid([chargeGroupConfig])) {
            return;
        }
        let chargeGroupConfigs: ChargeGroupConfigProps[] = [];
        Object.values(props.accountPopupProps.popupAccountObject.chargeGroupConfigMap).forEach(value => {
            chargeGroupConfigs.push(value)
        });
        chargeGroupConfigs.push(chargeGroupConfig)

        const data = {
            "operation": constants.SAVE_ACCOUNT_CONFIGURATION,
            "request_body": {
                "scac": props.accountPopupProps.scac,
                "shipmentAccountType": props.accountPopupProps.accountType,
                "accountNumbers": new Array(props.accountPopupProps.popupAccountObject.accountNumber),
                "businessType": props.accountPopupProps.popupAccountObject.businessType,
                "contractType": props.accountPopupProps.popupAccountObject.contractType,
                "countryCode": props.accountPopupProps.popupAccountObject.countryCode,
                "inputVersionMap": {[props.accountPopupProps.popupAccountObject.accountNumber]
                        : props.accountPopupProps.popupAccountObject.version},
                "invoiceMatchPropertyConfigs": props.accountPopupProps.popupAccountObject.InvoiceMatchPropertyList,
                "chargeGroupConfigs": chargeGroupConfigs
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setMessage({
                type: `success`,
                content: `ChargeGroup config ${props.addNewChargeGroup? "added" : "updated"} successfully`
            })
        }
        const onError = (err: AxiosError) => {
            setMessage(getErrorFlashMessageProps(err))
        }
        placePostRequest(data, onSuccess, onError);
    }


    return (
        <Container>
            <SpaceBetween size="l">
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setMessage(DEFAULT_EMPTY_FLASH_MESSAGE)
                                    const chargeGroupConfig : ChargeGroupConfigProps= {
                                        chargeGroup: chargeGroup || props.addOrEditChargeGroupConfig.chargeGroup,
                                        clientId: clientId,
                                        clientProgram: clientProgram,
                                        costCenterType: costCenterType,
                                        businessUnitId: businessUnitId,
                                        contractualLocationNodeId: contractualLocationNodeId,
                                        contractualLocationNamespace: contractualLocationNamespace,
                                        planner: planner
                                    }
                                    props.setAddOrEditChargeGroupConfig(chargeGroupConfig);
                                    saveChargeGroupConfig(chargeGroupConfig);
                                }}
                            >
                                {props.addNewChargeGroup? constants.ADD : constants.UPDATE}
                            </Button>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    props.setAddOrEditChargeGroupConfig(DEFAULT_CHARGE_GROUP_CONFIG);
                                    setMessage(DEFAULT_EMPTY_FLASH_MESSAGE)
                                    props.setShowAddOrEditChargeGroup(false);
                                }}
                            > Close </Button>
                        </SpaceBetween>
                    }
                    header={
                        <Header variant="h1">
                            {props.addNewChargeGroup ?
                                `Add New ChargeGroup` :
                                `Edit ChargeGroup: ${props.addOrEditChargeGroupConfig.chargeGroup}`
                            }
                        </Header>
                    }
                >

                    {props.addNewChargeGroup ?
                        <div>
                            <h2>ChargeGroup</h2>
                            <Input
                                onChange={({ detail }) => setChargeGroup(detail.value)}
                                value={chargeGroup}
                                placeholder={constants.ENTER_CHARGE_GROUP}
                            />
                        </div> : constants.EMPTY_STRING
                    }

                    <SpaceBetween direction="vertical" size="l">
                        <div>
                            <h3>ClientId</h3>
                            <Input
                                onChange={({ detail }) => setClientId(detail.value)}
                                value={clientId}
                                placeholder={constants.ENTER_CLIENT_ID}
                            />
                        </div>

                        <div>
                            <h3>ClientProgram</h3>
                            <Input
                                onChange={({ detail }) => setClientProgram(detail.value)}
                                value={clientProgram}
                                placeholder={constants.ENTER_CLIENT_PROGRAM}
                            />
                        </div>

                        <div>
                            <h3>CostCenterType</h3>
                            <Input
                                onChange={({ detail }) => setCostCenterType(detail.value)}
                                value={costCenterType}
                                placeholder={constants.ENTER_COST_CENTER_TYPE}
                            />
                        </div>

                        <div>
                            <h3>BusinessUnitId</h3>
                            <Input
                                onChange={({ detail }) => setBusinessUnitId(detail.value)}
                                value={businessUnitId}
                                placeholder={constants.ENTER_BUSINESS_UNIT_ID}
                            />
                        </div>

                        <div>
                            <h3>ContractualLocationNodeId</h3>
                            <Input onChange={({ detail }) => setContractualLocationNodeId(detail.value)}
                                   value={contractualLocationNodeId}
                                   placeholder={constants.ENTER_CONTRACTUAL_LOCATION_NODE_ID}
                            />
                        </div>

                        <div>
                            <h3>ContractualLocationNamespace</h3>
                            <Input
                                onChange={({ detail }) => setContractualLocationNamespace(detail.value)}
                                value={contractualLocationNamespace}
                                placeholder={constants.ENTER_CONTRACTUAL_LOCATION_NAMESPACE}
                            />
                        </div>

                        <div>
                            <h3>Planner</h3>
                            <Input
                                onChange={({ detail }) => setPlanner(detail.value)}
                                value={planner}
                                placeholder={constants.ENTER_PLANNER}
                            />
                        </div>
                    </SpaceBetween>
                </Form>
                <MatrixFlashMessage content={[message]} />
            </SpaceBetween>
        </Container>
    );
}

export default ChargeGroupConfigAddOrEdit;
