import {InvoiceMatchPropertyConfigTableProps} from "../../../interfaces";
import {Box, Button, Table} from "@amzn/awsui-components-react-v3";
import constants from "../../../constants/strings"
import React from "react";

function InvoiceMatchPropertyConfigTable(props: InvoiceMatchPropertyConfigTableProps) {

    return (
        <Table
            columnDefinitions={[
                {
                    id: constants.INVOICE_KEY,
                    header: constants.INVOICE_KEY,
                    cell: item => item.invoiceKey || constants.EMPTY_STRING
                },
                {
                    id: constants.MANIFEST_KEY,
                    header: constants.MANIFEST_KEY,
                    cell: item => item.manifestKey || constants.EMPTY_STRING
                },
                {
                    id: constants.DELETE,
                    header: constants.DELETE,
                    cell: item =>
                        <Button
                            variant="primary"
                            iconName="remove"
                            onClick={() => {
                                props.setAddorDeleteInvoiceMatchPropertyConfig(item);
                                props.setShowAddInvoiceMatchPropertyConfig(false);
                                props.setDeletePopover(true);
                            }}
                        />
                }
            ]}

            items={props.InvoiceMatchPropertyConfig}
            empty={
                <Box textAlign="center" color="inherit">
                    <Box
                        padding={{bottom: "l"}}
                        variant="p"
                        color="inherit"
                    >
                        {constants.NO_RESOURCES_TO_DISPLAY}
                    </Box>
                </Box>
            }
        />
    )
}

export default InvoiceMatchPropertyConfigTable;