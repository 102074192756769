import React, { useState } from 'react';
import "./index.css"
import { Grid, Button, Container, Header, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import CarrierSelectionComponent from "./CarrierSelectionComponent";
import ConfigurationsComponent from "../ConfigurationDisplayComponent";
import CarrierLaunchComponent from "../CarrierLaunchComponent";
import constants from "../../../constants/strings";
import LaunchScacComponent from "../LaunchScacComponent";
import LSMConfigUpload from "../LSMConfiguration/LSMConfigUpload";
import ImpsConfigUpload from "../IMPSBulkConfiguration/IMPSConfigUpload"


/**
 * This is Home Page for the CarrierConfiguration Component. Here, user can either:
 * 1. Check configuration for an existing carrier (or)
 * 2. Launch a new Carrier
 */
function HomePageComponent() {

    const [isConfigurationDisplayed, setIsConfigurationDisplayed] = useState<boolean>(false);
    const [scacValue, setScacValue] = useState<string>(constants.EMPTY_STRING);
    const [accountTypeValue, setAccountTypeValue] = useState<string>(constants.EMPTY_STRING);
    const [isCarrierLaunchComponentDisplayed, setIsCarrierLaunchComponentDisplayed] = useState<boolean>(false);
    const [isLaunchScacComponentDisplayed, setIsLaunchScacComponentDisplayed] = useState<boolean>(false);
    const [isUploadLSMConfigDisplayed, setIsUploadLSMConfigDisplayed] = useState<boolean>(false);

    function updateValues(scac: string, accountType: string, areConfigurationsToDisplay: boolean) {
        setIsConfigurationDisplayed(areConfigurationsToDisplay);
        setScacValue(scac);
        setAccountTypeValue(accountType);
        if(areConfigurationsToDisplay) {
            setIsCarrierLaunchComponentDisplayed(false);
            setIsLaunchScacComponentDisplayed(false);
            setIsUploadLSMConfigDisplayed(false);
        }
    }

    function ShowConfigurations() {
        if (isConfigurationDisplayed) {
            return (
                <div>
                    <ConfigurationsComponent scac={scacValue} accountType={accountTypeValue}/>
                </div>
            );
        } else if (isCarrierLaunchComponentDisplayed) {
            return (
                <div>
                    <CarrierLaunchComponent/>
                </div>
            );
        } else if (isLaunchScacComponentDisplayed) {
            return (
                <div>
                    <LaunchScacComponent/>
                </div>
            );
        } else if (isUploadLSMConfigDisplayed) {
            return (
                <div>
                    <Container>
                        <LSMConfigUpload/>
                    </Container>
                    <Container>
                        <ImpsConfigUpload/>
                    </Container>
                </div>
            );
        }
        else {
            return null;
        }
    }

    function launchCarrierOnClick() {
        setIsCarrierLaunchComponentDisplayed(true);
        setIsLaunchScacComponentDisplayed(false);
        setIsConfigurationDisplayed(false);
        setIsUploadLSMConfigDisplayed(false)
    }

    function launchScacOnClick() {
        setIsLaunchScacComponentDisplayed(true);
        setIsCarrierLaunchComponentDisplayed(false);
        setIsConfigurationDisplayed(false);
        setIsUploadLSMConfigDisplayed(false)
    }

    function uploadLSMConfig() {
        setIsUploadLSMConfigDisplayed(true)
        setIsCarrierLaunchComponentDisplayed(false);
        setIsConfigurationDisplayed(false);
        setIsLaunchScacComponentDisplayed(false);
    }

    return (
        <Grid className="flex-grid" gridDefinition={[{ colspan: 9}]}>
            <div>
                <Container
                       header = {
                           <Header
                               variant="h1"
                               description={constants.CHOOSE_CARRIER}
                               actions={
                                   <SpaceBetween
                                       direction="horizontal"
                                       size="xs"
                                   >
                                       <Button
                                           href={constants.WIKI_LINK}
                                           target={"_blank"}
                                           iconAlign="right"
                                           iconName="external"
                                       >
                                           Wiki
                                       </Button>
                                       <Button
                                           href={constants.FEATURE_REQUEST_SIM_LINK}
                                           target={"_blank"}
                                           iconAlign="right"
                                           iconName="external"
                                       >
                                           Request a feature
                                       </Button>
                                       <Button
                                           href={constants.REPORT_BUG_TT_LINK}
                                           target={"_blank"}
                                           iconAlign="right"
                                           iconName="external"
                                       >
                                           Report a bug
                                       </Button>
                                   </SpaceBetween>
                               }
                           >
                               {constants.CARRIER_CONFIGURATION}
                           </Header>
                       }
                >
                    <SpaceBetween size={"l"}>
                        <CarrierSelectionComponent scac={scacValue} accountType={accountTypeValue} updateValues={updateValues}/>
                        <Grid className="flex-grid">
                            <Button variant="normal" onClick={launchCarrierOnClick}>+ Launch a Shipment Account Type</Button>
                            <Button variant="normal" onClick={launchScacOnClick}>+ Launch a Scac</Button>
                            <Button variant="normal" onClick={uploadLSMConfig}>+ Upload Account Config</Button>
                        </Grid>
                    </SpaceBetween>
                </Container>
                <br />
                <ShowConfigurations />
            </div>
        </Grid>
    );
}

export default HomePageComponent;
