import React, {useState} from 'react';
import {Button, SpaceBetween, Header, ContentLayout} from "@amzn/awsui-components-react-v3/polaris";
import constants from "../../../constants/strings";
import {AccountPopupProps, ChargeGroupConfigProps} from "../../../interfaces";
import ChargeGroupConfigTable from "./ChargeGroupConfigTable";
import ChargeGroupConfigAddOrEdit from "./ChargeGroupConfigAddOrEdit";
import {DEFAULT_CHARGE_GROUP_CONFIG} from "../../../constants/objects";



function AccountConfigPopup(props: AccountPopupProps) {
    const [showAddOrEditChargeGroup, setShowAddOrEditChargeGroup] = useState<boolean>(false);
    const [addOrEditChargeGroupConfig, setAddOrEditChargeGroupConfig] = useState<ChargeGroupConfigProps>(DEFAULT_CHARGE_GROUP_CONFIG);
    const [addNewChargeGroup, setAddNewChargeGroup] = useState<boolean>(false);

    function getChargeGroupConfigs() {
        let chargeGroupConfigs: ChargeGroupConfigProps[] = [];
        Object.values(props.popupAccountObject.chargeGroupConfigMap? props.popupAccountObject.chargeGroupConfigMap:{})
            .forEach(value => {
            chargeGroupConfigs.push(value)
        });
        return chargeGroupConfigs;
    }

    return(
        <ContentLayout header={
            <Header variant="h1"
                    actions={
                        <SpaceBetween direction="horizontal" size="l">
                            <Button
                                variant="primary"
                                onClick={() => props.setShowAccountDetails(false)}> Close
                            </Button>
                        </SpaceBetween>
                    }
            >
                <Button iconAlign="left"
                        variant="normal"
                        onClick={() => {
                            setAddNewChargeGroup(true);
                            setAddOrEditChargeGroupConfig(DEFAULT_CHARGE_GROUP_CONFIG);
                            setShowAddOrEditChargeGroup(true);}
                        }
                >
                    + Add ChargeGroup
                </Button>
            </Header>
        }>
            <SpaceBetween direction="vertical" size="xxl">
                <SpaceBetween direction="vertical" size="xxl">

                    <ChargeGroupConfigTable
                        chargeGroupConfigs = {getChargeGroupConfigs()}
                        setShowAddOrEditChargeGroup={setShowAddOrEditChargeGroup}
                        setAddOrEditChargeGroupConfig={setAddOrEditChargeGroupConfig}
                        setAddNewChargeGroup={setAddNewChargeGroup}
                    />
                    {showAddOrEditChargeGroup ?
                        <ChargeGroupConfigAddOrEdit
                            accountPopupProps={props}
                            addOrEditChargeGroupConfig={addOrEditChargeGroupConfig}
                            setShowAddOrEditChargeGroup={setShowAddOrEditChargeGroup}
                            setAddOrEditChargeGroupConfig={setAddOrEditChargeGroupConfig}
                            addNewChargeGroup={addNewChargeGroup}
                        />
                        : constants.EMPTY_STRING}

                </SpaceBetween>
            </SpaceBetween>
        </ContentLayout>
    );
}

export default AccountConfigPopup;
