import {Button, Container, ContentLayout, FlashbarProps, Header, SpaceBetween} from "@amzn/awsui-components-react-v3";
import React, {useState} from "react";
import {
    AccountTableObject,
    ChargeGroupConfigProps,
    InputAccountConfiguration,
    InvoiceMatchPropertyConfigs,
    InvoicePropertyPopupProps,
} from "../../../interfaces";
import {
    DEFAULT_ACCOUNT_TABLE_OBJECT,
    DEFAULT_CHARGE_GROUP_CONFIG_MAP,
    DEFAULT_EMPTY_FLASH_MESSAGE,
    DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG,
    DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG_LIST
} from "../../../constants/objects";
import InvoiceMatchPropertyAddKey from "./InvoiceMatchPropertyAddKey";
import constants from "../../../constants/strings";
import InvoiceMatchPropertyConfigTable from "./InvoiceMatchPropertyConfigTable";
import {AxiosError, AxiosResponse} from "axios";
import {getErrorMessage} from "../../../utils/errorMessage";
import {placePostRequest} from "../../../utils";
import MatrixFlashMessage from "../CommonComponents/MatrixFlashMessage";
import IMPSDeletePopup from "./IMPSDeletePopup";
import {MatrixDeleteModalProps} from "../CommonComponents/MatrixDeleteModal";

function InvoiceMatchPropertyConfigPopup(props: InvoicePropertyPopupProps) {

    const [message, setMessage] = React.useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE)
    const [showAddInvoiceMatchPropertyConfig, setShowAddInvoiceMatchPropertyConfig] = useState<boolean>(false)
    const [deletePopover, setDeletePopover] = useState<boolean>(false);
    const [addorDeleteInvoiceMatchPropertyConfig, setAddorDeleteInvoiceMatchPropertyConfig] = useState<InvoiceMatchPropertyConfigs>(DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG)

    function saveInvoiceMatchPropertyConfig(IMPSConfig: InvoiceMatchPropertyConfigs, isDeleteEnabled: boolean) {
        const manifestChargeCodePattern: RegExp = new RegExp(/^[A-Z_/]+$/);

        if (!manifestChargeCodePattern.test(IMPSConfig.invoiceKey) || !manifestChargeCodePattern.test(IMPSConfig.manifestKey)) {
            setMessage({
                type: `error`,
                content: constants.IMPS_KEY_FORMAT_ERROR_TEXT
            })
            return;
        } else if (props.popupAccountObject.InvoiceMatchPropertyList.length >= 5 && !isDeleteEnabled) {
            setMessage({
                type: `error`,
                content: constants.IMPS_KEYS_LIMIT_EXCEEDED_ERROR_TEXT
            })
            return;
        }

        let impsConfigs: InvoiceMatchPropertyConfigs[] = [], isDuplicatesFound = false;

        Object.values(props.popupAccountObject.InvoiceMatchPropertyList).filter(value => {
            if (value.invoiceKey == IMPSConfig.invoiceKey && value.manifestKey == value.manifestKey && !isDeleteEnabled) {
                isDuplicatesFound = true;
            } else if (value != IMPSConfig) {
                impsConfigs.push(value)
            }
        });

        if (isDuplicatesFound) {
            setMessage({
                type: `error`,
                content: constants.KEYS_DUPLICATE_ERROR_TEXT
            })
            return;
        }

        if (!isDeleteEnabled) impsConfigs.push(IMPSConfig)

        setShowAddInvoiceMatchPropertyConfig(false);
        setMessage({
            type: `info`,
            loading: true,
            content: `loading`
        })

        let chargeGroupConfigs: ChargeGroupConfigProps[] = [];

        Object.values(props.popupAccountObject.chargeGroupConfigMap ? props.popupAccountObject.chargeGroupConfigMap : {})
            .forEach(value => {
                chargeGroupConfigs.push(value)
            });

        const data = {
            "operation": constants.SAVE_ACCOUNT_CONFIGURATION,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "accountNumbers": new Array(props.popupAccountObject.accountNumber),
                "businessType": props.popupAccountObject.businessType,
                "contractType": props.popupAccountObject.contractType,
                "countryCode": props.popupAccountObject.countryCode,
                "inputVersionMap": Object.fromEntries(new Map(
                    [[props.popupAccountObject.accountNumber, props.popupAccountObject.version]])),
                "invoiceMatchPropertyConfigs": impsConfigs,
                "chargeGroupConfigs": chargeGroupConfigs
            }
        }

        const onSuccess = (res: AxiosResponse) => {
            getAccountConfiguration();
            setMessage({
                type: `success`,
                content: `IMPS config ${!isDeleteEnabled ? "added" : "deleted"} successfully`
            })
        }
        const onError = (err: AxiosError) => {
            setMessage({
                type: `error`,
                content: `${getErrorMessage(err)}`
            })
        }
        placePostRequest(data, onSuccess, onError);
        setShowAddInvoiceMatchPropertyConfig(false);
        setDeletePopover(false);
    }

    function getAccountConfiguration() {
        const data = {
            "operation": constants.GET_ACCOUNT_CONFIGURATION,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "accountNumber": props.popupAccountObject.accountNumber
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            const inputAccountConfigurationList: InputAccountConfiguration[] = res.data["accountConfigurationOutput"]["inputAccountConfigurationList"] || [];
            inputAccountConfigurationList.forEach(inputAccountConfiguration => {
                if (inputAccountConfiguration.accountNumber == props.popupAccountObject.accountNumber) {
                    const accountTableObject: AccountTableObject = {
                        accountNumber: inputAccountConfiguration.accountNumber,
                        status: inputAccountConfiguration.enabled,
                        businessType: inputAccountConfiguration.businessType,
                        contractType: inputAccountConfiguration.contractType,
                        countryCode: inputAccountConfiguration.countryCode,
                        chargeGroupConfigMap: inputAccountConfiguration.chargeGroupConfigurationMap
                            || DEFAULT_CHARGE_GROUP_CONFIG_MAP,
                        InvoiceMatchPropertyList: inputAccountConfiguration.invoiceMatchPropertySetConfiguration ?
                            inputAccountConfiguration.invoiceMatchPropertySetConfiguration['matchKeys']
                            : DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG_LIST,
                        version: inputAccountConfiguration.version || constants.EMPTY_STRING,
                    }
                    props.setPopupAccountObject(accountTableObject);
                }
            });
        };

        const onError = (err: AxiosError) => {
            props.setPopupAccountObject(DEFAULT_ACCOUNT_TABLE_OBJECT);
        }
        placePostRequest(data, onSuccess, onError);
    }

    return (
        <Container>
            <ContentLayout header={
                <SpaceBetween size="m">
                    <Header variant="h1"
                            actions={
                                <Button
                                    variant="primary"
                                    onClick={() => props.setShowIMPSDetails(false)}>
                                    Close
                                </Button>
                            }
                    >
                        <SpaceBetween direction="horizontal" size="xxl">
                            <Button iconAlign="left"
                                    variant="normal"
                                    onClick={() => {
                                        setShowAddInvoiceMatchPropertyConfig(true)
                                        setDeletePopover(false)
                                    }}
                            >
                                {constants.ADD_IMPS_BUTTON}
                            </Button>
                        </SpaceBetween>
                    </Header>
                </SpaceBetween>
            }>
                <SpaceBetween direction="vertical" size="xxl">
                    <SpaceBetween direction="vertical" size="xxl">
                        {
                            deletePopover ?
                                <IMPSDeletePopup
                                    setDeletePopover={setDeletePopover}
                                    saveInvoiceMatchPropertyConfig={saveInvoiceMatchPropertyConfig}
                                    addorDeleteInvoiceMatchPropertyConfig={addorDeleteInvoiceMatchPropertyConfig}
                                />
                                : constants.EMPTY_STRING
                        }

                        <MatrixFlashMessage content={[message]}/>
                        <InvoiceMatchPropertyConfigTable
                            setDeletePopover={setDeletePopover}
                            InvoiceMatchPropertyConfig={props.popupAccountObject.InvoiceMatchPropertyList}
                            setShowAddInvoiceMatchPropertyConfig={setShowAddInvoiceMatchPropertyConfig}
                            setAddorDeleteInvoiceMatchPropertyConfig={setAddorDeleteInvoiceMatchPropertyConfig}
                        />
                        {
                            showAddInvoiceMatchPropertyConfig ?
                                <InvoiceMatchPropertyAddKey
                                    saveInvoiceMatchPropertyConfig={saveInvoiceMatchPropertyConfig}
                                    addorDeleteInvoiceMatchPropertyConfig={addorDeleteInvoiceMatchPropertyConfig}
                                    setShowAddInvoiceMatchPropertyConfig={setShowAddInvoiceMatchPropertyConfig}
                                    impsConfigCount={props.popupAccountObject.InvoiceMatchPropertyList.length}
                                    setAddorDeleteInvoiceMatchPropertyConfig={setAddorDeleteInvoiceMatchPropertyConfig}
                                />
                                : constants.EMPTY_STRING
                        }
                    </SpaceBetween>
                </SpaceBetween>
            </ContentLayout>
        </Container>
    );
}

export default InvoiceMatchPropertyConfigPopup;