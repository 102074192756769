import React, {Dispatch, SetStateAction} from "react";
import {
    ControlInputProps,
    MapOfStringTypeValueProps
} from "../../../interfaces";
import {AttributeEditor, Box, Input, Select, Table} from "@amzn/awsui-components-react-v3/polaris";
import {memo, useCallback, useEffect, useMemo, useState} from "react";
import {DEFAULT_MAP_OF_STRING_TYPE_VALUE_OBJECT} from "../../../constants/objects";
import constants from "../../../constants/strings";

export interface RuleArgumentDataProps {
    ruleArgumentName: string;
    modifiedRuleArgumentsMap: any;
    setModifiedRuleArgumentsMap: Dispatch<SetStateAction<any>>;
    possibleValuesList: string[];
}

function RuleArgumentDataComponent(props: RuleArgumentDataProps) {

    const ruleArgumentDataType : string = props.modifiedRuleArgumentsMap[props.ruleArgumentName]["ruleArgumentDataType"];
    const ruleArgumentValue : any = props.modifiedRuleArgumentsMap[props.ruleArgumentName]["value"];
    const listofPossibleValuesExist : boolean = props.modifiedRuleArgumentsMap[props.ruleArgumentName]["listofPossibleValuesExist"];

    function RuleArgumentDataForStringTypeValue() {
        // For adding or updating rules "__type" field is required.
        let modifiedRuleArgumentsMap = props.modifiedRuleArgumentsMap;
        modifiedRuleArgumentsMap[props.ruleArgumentName]["__type"] = "com.amazon.tipscarrierconfiguration.structures#PrimitiveRuleArgumentData";
        props.setModifiedRuleArgumentsMap(modifiedRuleArgumentsMap);

        const [value, setValue] = useState<string>(ruleArgumentValue);

        useEffect(() => {
            // Set the new value in the associated RuleArgumentsMap.
            let modifiedRuleArgumentsMap = props.modifiedRuleArgumentsMap;
            modifiedRuleArgumentsMap[props.ruleArgumentName]["value"] = (value === constants.EMPTY_STRING ? null : value);
            props.setModifiedRuleArgumentsMap(modifiedRuleArgumentsMap);
        }, [value]);

        return (
            <Input
                onChange={({ detail }) => { setValue(detail.value); }}
                value={value}
            />
        );
    }

    function RuleArgumentDataForMapOfStringTypeValue() {
        // For adding or updating rules "__type" field is required.
        let modifiedRuleArgumentsMap = props.modifiedRuleArgumentsMap;
        modifiedRuleArgumentsMap[props.ruleArgumentName]["__type"] = "com.amazon.tipscarrierconfiguration.structures#MapRuleArgumentData";
        props.setModifiedRuleArgumentsMap(modifiedRuleArgumentsMap);

        let itemsList: MapOfStringTypeValueProps[] = [];
        if (ruleArgumentValue != null) {
            Object.keys(ruleArgumentValue).forEach(key => {
                itemsList.push({key: key, value: ruleArgumentValue[key]});
            });
        }

        const i18nStrings = {
            addButtonText: 'Add new Key-Value pair',
            removeButtonText: 'Remove',
            empty: 'No Key-Value pair(s) present',
        };
        const Control = memo(({ value, index, placeholder, setItems, prop } : ControlInputProps) => {
            if (prop === "value" && listofPossibleValuesExist) {
                console.log(props.possibleValuesList);
                return (
                    <Select
                        selectedOption={(value === constants.EMPTY_STRING) ? {value:"Choose a value"} : {value:value}}
                        onChange={({ detail }) =>
                            setItems((items: MapOfStringTypeValueProps[]) => {
                                const updatedItems = [...items];
                                updatedItems[index] = {
                                    ...updatedItems[index],
                                    [prop]: detail.selectedOption.value || constants.EMPTY_STRING,
                                };
                                return updatedItems;
                            })
                        }
                        options={
                            props.possibleValuesList.sort().map(option => {
                                return {
                                    value: option
                                }
                            })
                        }
                        expandToViewport={true}
                        filteringType="auto"
                        selectedAriaLabel="Selected"
                    />
                );
            }
            return (
                <Input
                    value={value}
                    placeholder={placeholder}
                    onChange={({ detail }) => {
                        setItems((items: MapOfStringTypeValueProps[]) => {
                            const updatedItems = [...items];
                            updatedItems[index] = {
                                ...updatedItems[index],
                                [prop]: detail.value,
                            };
                            return updatedItems;
                        });
                    }}
                />
            );
        });
        const [items, setItems] = useState(itemsList);

        useEffect(() => {
            let itemsMap : Map<string, string> = new Map<string, string>();
            items.forEach(item => {
                itemsMap.set(item.key, item.value);
            });
            // Set the new value in the associated RuleArgumentsMap.
            let modifiedRuleArgumentsMap = props.modifiedRuleArgumentsMap;
            modifiedRuleArgumentsMap[props.ruleArgumentName]["value"] = (itemsMap.size === 0) ? null : Object.fromEntries(itemsMap);
            props.setModifiedRuleArgumentsMap(modifiedRuleArgumentsMap);
        }, [items]);

        const definition = useMemo(
            () => [
                {
                    label: 'Key',
                    control: ({ key = '' }, itemIndex : number) => (
                        <Control
                            prop="key"
                            value={key}
                            index={itemIndex}
                            placeholder="Enter Key"
                            setItems={setItems}
                        />
                    )
                },
                {
                    label: 'Value',
                    control: ({ value = '' }, itemIndex : number) => (
                        <Control
                            prop="value"
                            value={value}
                            index={itemIndex}
                            placeholder="Enter Value"
                            setItems={setItems}
                        />
                    )
                },
            ],
            []
        );
        const onAddButtonClick = useCallback(() => {
            setItems(items => [...items, DEFAULT_MAP_OF_STRING_TYPE_VALUE_OBJECT]);
        }, []);
        const onRemoveButtonClick = useCallback(({ detail: { itemIndex } }) => {
            setItems(items => {
                const newItems = items.slice();
                newItems.splice(itemIndex, 1);
                return newItems;
            });
        }, []);
        return (
            <AttributeEditor
                {...i18nStrings}
                items={items}
                definition={definition}
                onAddButtonClick={onAddButtonClick}
                onRemoveButtonClick={onRemoveButtonClick}
            />
        );
    }

    if (ruleArgumentDataType === "StringTypeValue") { // String
        return <RuleArgumentDataForStringTypeValue/>;
    } else if (ruleArgumentDataType === "MapOfStringTypeValue") { // Map<String,String>
        return <RuleArgumentDataForMapOfStringTypeValue/>;
    }

    // Code should not reach here.
    return <Box textAlign="left">NO VALUE</Box>;

}

export default RuleArgumentDataComponent;
