import {Container, Grid, Header, ProgressBar, SpaceBetween} from "@amzn/awsui-components-react-v3";
import {IMPS_EXCEL_COLUMNS} from "../../../constants/objects";
import IMPSExcelFileParse from "./IMPSExcelFileParse";
import IMPSConfigUploadReport from "./IMPSConfigUploadReport";
import constants from "../../../constants/strings";
import React, {useState} from "react";
import {IMPSConfigUploadReportObject} from "../../../interfaces";
import BulkUploadCardComponent from "../CommonComponents/BulkUploadCardComponent";

function IMPSConfigUpload() {
    const [showImpsConfigUploadReport, setShowImpsConfigUploadReport] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [impsConfigUploadReportObjects, setImpsConfigUploadReportObjects] = useState<IMPSConfigUploadReportObject[]>([]);

    return (
        <Container header={<Header>Upload Invoice Match Property Configuration</Header>}>
            <SpaceBetween size={"xs"}>
                <BulkUploadCardComponent
                    values={[`{${IMPS_EXCEL_COLUMNS.toString().split(',').join(', ')}}`,
                        "Supported File Extension(s): [\"{" + constants.LSM_EXCEL_FILE_EXTENSION + "}\"]",
                        "Maximum number of records allowed for scac, accountNumber: {" + constants.IMPS_MAX_EXCEL_RECORDS + "}",
                        "Maximum number of records : {" + constants.LSM_MAX_EXCEL_RECORDS + "}"
                    ]}
                />

                <Container>
                    <Grid gridDefinition={[{colspan: 5}, {colspan: 5}]}>
                        <IMPSExcelFileParse
                            setShowImpsConfigUploadReport={setShowImpsConfigUploadReport}
                            setUploadProgress={setUploadProgress}
                            setImpsConfigUploadReportObjects={setImpsConfigUploadReportObjects}
                        />
                        {showImpsConfigUploadReport ?
                            <ProgressBar
                                label={"File upload progress"}
                                value={uploadProgress}
                                status={uploadProgress >= 100 ? "success" : "in-progress"}
                                resultText="Upload Successful"
                            /> : constants.EMPTY_STRING
                        }
                    </Grid>
                </Container>
                {
                    showImpsConfigUploadReport ?
                        <IMPSConfigUploadReport
                            impsConfigUploadReportObjects={impsConfigUploadReportObjects}
                        /> : constants.EMPTY_STRING
                }
            </SpaceBetween>
        </Container>
    )
}

export default IMPSConfigUpload;