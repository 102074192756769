import {Auth} from "aws-amplify";

/**
 * Here we are extracting the alias of the user who is currently launching.
 * For eg., Auth returns the username as FederateClient_nagaveer and we want nagaveer.
 */
export function fetchUserAlias(setAlias: Function) {
    Auth.currentAuthenticatedUser({ bypassCache: true }).then(user => {
        const USERNAME_SPLIT_REGEX: string = "_";
        const arr: string[] = user.username.split(USERNAME_SPLIT_REGEX);
        if (arr.length > 0) {
            setAlias(arr[arr.length - 1]);
        }
    });
}

