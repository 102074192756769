import React, {useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    Header,
    ProgressBar,
    SpaceBetween
} from "@amzn/awsui-components-react-v3/polaris";
import LSMConfigUploadReport from "./LSMConfigUploadReport";
import {LSMConfigUploadReportObject} from "../../../interfaces";
import LSMExcelFileParse from "./LSMExcelFileParse";
import constants from "../../../constants/strings";
import {LSM_EXCEL_COLUMNS} from "../../../constants/objects";
import BulkUploadCardComponent from "../CommonComponents/BulkUploadCardComponent";

function LSMConfigUpload() {
    const [lsmConfigUploadReportObjects, setLSMConfigUploadReportObjects] =
        useState<LSMConfigUploadReportObject[]>([]);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [showLSMConfigUploadReport, setShowLSMConfigUploadReport] = useState<boolean>(false);

    const downloadTemplate = () => {
        /** TODO
         * give s3 url and download file
         */
    }

    return (
        <Container header={<Header variant="h2"> Upload Account Configuration </Header>}>
            <SpaceBetween direction="vertical" size="xs">
                <BulkUploadCardComponent
                    values = {[`{${LSM_EXCEL_COLUMNS.toString().split(',').join(', ')}}`,
                        "Supported File Extension(s): [\"{" + constants.LSM_EXCEL_FILE_EXTENSION + "}\"]",
                        "Maximum number of records : {" + constants.LSM_MAX_EXCEL_RECORDS +"}"
                    ]}
                />
                <Container>
                   {/* TODO: remove disabled attribute from  Download Account Template button , once s3 url is available*/}
                <Grid gridDefinition={[{colspan:12}]}>
                    <div style={{float:'right'}}>
                <Button disabled iconName='download' iconAlign='left' variant="primary" onClick={downloadTemplate}>Download Account Template</Button>
                </div>
                </Grid>
                    <Grid gridDefinition={[{ colspan: 5 }, { colspan: 5 }]}>
                        <LSMExcelFileParse
                            uploadProgress={uploadProgress}
                            setUploadProgress={setUploadProgress}
                            lsmConfigUploadReportObjects={lsmConfigUploadReportObjects}
                            setLSMConfigUploadReportObjects={setLSMConfigUploadReportObjects}
                            setShowLSMConfigUploadReport={setShowLSMConfigUploadReport}
                        />
                        {showLSMConfigUploadReport ?
                            <ProgressBar
                                value={uploadProgress}
                                label="File upload progress"
                                status={uploadProgress>=100 ? "success" : "in-progress"}
                                resultText="Upload Successful"
                            /> : constants.EMPTY_STRING
                        }
                    </Grid>
                </Container>
                {showLSMConfigUploadReport ?
                    <LSMConfigUploadReport lsmConfigUploadReportObjects={lsmConfigUploadReportObjects}/> :
                    constants.EMPTY_STRING
                }
            </SpaceBetween>
        </Container>
    )
}
export default LSMConfigUpload;