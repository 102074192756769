import {AxiosError, AxiosResponse} from "axios";
import constants from "../../../constants/strings";
import {placeGetRequest} from "../../../utils";

/**
 * This function is used for fetching all the possible values to be displayed in the dropdown from which the user can
 * select a value. This is required for only those rules which has "listofPossibleValuesExist" as true.
 */
export function getPossibleValueForRuleArgument(ruleArgumentName: string, ruleType: string, scac: string,
                                                accountType: string, listofPossibleValuesExist: boolean) {
    let possibleValuesList: string[] = [];
    if (listofPossibleValuesExist) {
        const data = {
            "operation": constants.GET_POSSIBLE_VALUE_FOR_RULE_ARGUMENT,
            "request_body": {
                "ruleArgumentName": ruleArgumentName,
                "ruleType": ruleType,
                "scac": scac,
                "shipmentAccountType": accountType,
                "valueType": "Values"
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            (res.data[ruleArgumentName]["listOfPossibleValues"]).forEach((val: string) => {
                possibleValuesList.push(val);
            });
        };
        const onError = (err: AxiosError) => {};
        placeGetRequest(data, onSuccess, onError);
    }
    return possibleValuesList;
}

export const isCompanyCode = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 11) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 11) === constants.COMPANY_CODE);
}

export const isLocation = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 8) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 8) === constants.LOCATION);
}

export const isCostCenter = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 10) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 10) === constants.COST_CENTER);
}

export const isAccount = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 7) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 7) === constants.ACCOUNT);
}

export const isProductLine = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 11) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 11) === constants.PRODUCT_LINE);
}

export const isSalesChannel = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 12) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 12) === constants.SALES_CHANNEL);
}

export const isProject = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 7) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 7) === constants.PROJECT);
}

export const isDescription = (ruleConfigurationKey: string) => {
    return (ruleConfigurationKey.length >= 11) && (ruleConfigurationKey.slice(ruleConfigurationKey.length - 11) === constants.DESCRIPTION);
}
