import React, { useState } from 'react';
import "./index.css";
import { AxiosError, AxiosResponse } from "axios";
import { CarrierSelectionComponentProps } from "../../../interfaces";
import {
    Grid,
    Button,
    Input,
    FormField,
    SelectProps,
    Select
} from "@amzn/awsui-components-react-v3/polaris";
import { placeGetRequest } from "../../../utils";
import constants from "../../../constants/strings";
import { isScacValid } from "../../../utils/errorChecks";
import {DEFAULT_SHIPMENT_ACCOUNT_TYPE_OPTION} from "../../../constants/objects";


/**
 * This component lets the user enter the SCAC and Account Type for which they want to check the configurations.
 *
 * @param props CarrierSelectionComponentProps
 */
function CarrierSelectionComponent(props: CarrierSelectionComponentProps) {

    const [scacValue, setScacValue] = useState<string>(props.scac);
    const [scacErrorText, setScacErrorText] = useState<string>(constants.EMPTY_STRING);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [accountTypeValue, setAccountTypeValue] = useState<SelectProps.Option>(DEFAULT_SHIPMENT_ACCOUNT_TYPE_OPTION);
    const [accountTypeDisabled, setAccountTypeDisabled] = useState<boolean>(true);
    const [accountTypeList, setAccountTypeList] = useState<string[]>([]);

    /**
     * This function is called whenever the user clicks on 'Show Configurations button'.
     * If the account type has been selected then the configurations are displayed.
     */
    function showConfigurationsClicked() {
        if (accountTypeValue.value) {
            if(!accountTypeDisabled && accountTypeValue.value.length > 0) {
                props.updateValues(scacValue, accountTypeValue.value, true);
            } else {
                props.updateValues(scacValue, accountTypeValue.value, false);
            }
        }
    }

    /**
     * For a corresponding SCAC, when the user hits the Go button, then an API call
     * is made to get all Account Types for that SCAC.
     */
    function fetchAllAccountTypes() {
        setAccountTypeDisabled(true);
        setAccountTypeList([]);
        setAccountTypeValue(DEFAULT_SHIPMENT_ACCOUNT_TYPE_OPTION);
        setLoading(true);

        // Make necessary null validations and error checks.
        if (!isScacValid(scacValue, setScacErrorText)) {
            setLoading(false);
            return;
        }

        const data = {
            "operation": constants.FETCH_ALL_ACCOUNT_TYPES,
            "request_body": {
                "scac": scacValue
            }
        }
        //Enable account type if response is valid
        const onSuccess = (res: AxiosResponse) => {
            if (res.data["scacValidity"]["isValid"]) {
                setScacErrorText(constants.EMPTY_STRING);
                if (res.data["accountTypes"].hasOwnProperty("accountTypesList")) {
                    setAccountTypeList(res.data["accountTypes"]["accountTypesList"]);
                    setAccountTypeDisabled(false);
                }
            } else {
                setScacErrorText(constants.INVALID_SCAC_ERROR_TEXT);
            }
            setLoading(false);
        }
        const onError = (err: AxiosError) => {
            console.log(err);
            setLoading(false);
        }
        placeGetRequest(data, onSuccess, onError);
    }

    return (
        <Grid className="flex-grid">
            <FormField errorText={scacErrorText}>
                <Grid
                    gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
                >
                    <Input
                        onChange={({ detail }) => {
                            isScacValid(detail.value, setScacErrorText);
                            setScacValue(detail.value.toUpperCase());
                        }}
                        value={scacValue}
                        placeholder={constants.SCAC}
                    />
                    <Button loading={isLoading} variant="normal" onClick={fetchAllAccountTypes}>{constants.GO}</Button>
                </Grid>
            </FormField>
            <FormField>
                <Select
                    selectedOption={accountTypeValue}
                    onChange={({ detail }) => {
                        setAccountTypeValue(detail.selectedOption);
                    }}
                    options={
                        accountTypeList.sort().map(option => {
                            return {
                                value: option
                            }
                        })
                    }
                    disabled={accountTypeDisabled}
                    expandToViewport={true}
                    filteringType="auto"
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <FormField>
                <Button variant="primary" onClick={showConfigurationsClicked}>{constants.SHOW_CONFIGURATION}</Button>
            </FormField>
        </Grid>
    );
}

export default CarrierSelectionComponent;
