import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from "axios";
import {
    Container,
    Header,
    Button,
    Form,
    FormField,
    Tiles,
    SpaceBetween,
    FlashbarProps
} from "@amzn/awsui-components-react-v3/polaris";
import "./index.css"
import { LaunchConfigurationProps, CarrierObject } from "../../../interfaces";
import {
    getStatus,
    getEnabledStatus,
    placePostRequest
} from "../../../utils";
import constants from "../../../constants/strings";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";
import MatrixFlashMessage from "../CommonComponents/MatrixFlashMessage";
import {DEFAULT_EMPTY_FLASH_MESSAGE, UPDATE_SUCCESS_FLASH_MESSAGE} from "../../../constants/objects";
import MatrixSaveModal from "../CommonComponents/MatrixSaveModal";

function LaunchConfigurationDetail(props: LaunchConfigurationProps) {
    const [carrierStatusValue, setCarrierStatusValue] = useState<string>(getStatus(props.launchData.enabled));
    const [paymentStatusValue, setPaymentStatusValue] = useState<string>(getStatus(props.launchData.paymentEnabled));
    const [complianceCheckValue, setComplianceCheckValue] = useState<string>(getStatus(props.launchData.complianceCheckEnabled));
    const [isLaunchDataUpdating, setLaunchDataUpdating] = useState<boolean>(false);
    const [message, setMessage] = useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);

    function setStateToProps() {
        setCarrierStatusValue(getStatus(props.launchData.enabled));
        setPaymentStatusValue(getStatus(props.launchData.paymentEnabled));
        setComplianceCheckValue(getStatus(props.launchData.complianceCheckEnabled));
        setMessage(DEFAULT_EMPTY_FLASH_MESSAGE);
    }

    useEffect(() => {
        setStateToProps();
    }, [props.launchData]);

    function updateConfigurationClicked() {

        // If values are same do not make a call.
        if (props.launchData.enabled === getEnabledStatus(carrierStatusValue)
            && props.launchData.complianceCheckEnabled === getEnabledStatus(complianceCheckValue)
            && props.launchData.paymentEnabled === getEnabledStatus(paymentStatusValue)) {
            return;
        }

        setLaunchDataUpdating(true);
        const updatedCarrierConfiguration: CarrierObject = {
            id: props.launchData.id,
            carrierName: props.launchData.carrierName,
            clientName: props.launchData.clientName,
            complianceCheckEnabled: getEnabledStatus(complianceCheckValue),
            enabled: getEnabledStatus(carrierStatusValue),
            launchDate: props.launchData.launchDate,
            launchedBy: props.launchData.launchedBy,
            paymentEnabled: getEnabledStatus(paymentStatusValue),
            scac: props.launchData.scac,
            shipmentAccountType: props.launchData.shipmentAccountType
        };

        const data = {
            "operation": constants.UPDATE_LAUNCH_CONFIGURATION,
            "request_body": {
                "scac": props.launchData.scac,
                "shipmentAccountType": props.launchData.shipmentAccountType,
                "launchDate": updatedCarrierConfiguration.launchDate,
                "enabled": updatedCarrierConfiguration.enabled,
                "paymentEnabled": updatedCarrierConfiguration.paymentEnabled,
                "complianceCheckEnabled": updatedCarrierConfiguration.complianceCheckEnabled
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            props.updateLaunchConfiguration(updatedCarrierConfiguration);
            setLaunchDataUpdating(false);
            setMessage(UPDATE_SUCCESS_FLASH_MESSAGE);
        }
        const onError = (err: AxiosError) => {
            setLaunchDataUpdating(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    return(
        <div>
            <SpaceBetween size={"l"}>
                <MatrixFlashMessage content={[message]} />
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="l">
                            <Button variant="normal" onClick={setStateToProps}>{constants.RESET}</Button>
                            <MatrixSaveModal buttonName={constants.UPDATE_CARRIER_CONFIGURATION} buttonLoading={isLaunchDataUpdating} onClickConfirm={updateConfigurationClicked}/>
                        </SpaceBetween>
                    }>
                    <Container
                        header={
                            <Header variant="h2">
                                {constants.LAUNCH_CONFIGURATION}
                            </Header>
                        }>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label={`${constants.CARRIER_STATUS}:`}>
                                <Tiles
                                    onChange={({ detail }) => {
                                        setCarrierStatusValue(detail.value);
                                    }}
                                    value={carrierStatusValue}
                                    items={[
                                        { label: constants.ENABLED, value: constants.ENABLED },
                                        { label: constants.DISABLED, value: constants.DISABLED }
                                    ]}
                                />
                            </FormField>
                            <FormField label={`${constants.PAYMENT_STATUS}:`}>
                                <Tiles
                                    onChange={({ detail }) => {
                                        setPaymentStatusValue(detail.value);
                                    }}
                                    value={paymentStatusValue}
                                    items={[
                                        { label: constants.ENABLED, value: constants.ENABLED },
                                        { label: constants.DISABLED, value: constants.DISABLED }
                                    ]}
                                />
                            </FormField>
                            <FormField label={`${constants.COMPLIANCE_CHECK}:`}>
                                <Tiles
                                    onChange={({ detail }) => {
                                        setComplianceCheckValue(detail.value);
                                    }}
                                    value={complianceCheckValue}
                                    items={[
                                        { label: constants.ENABLED, value: constants.ENABLED },
                                        { label: constants.DISABLED, value: constants.DISABLED }
                                    ]}
                                />
                            </FormField>
                        </SpaceBetween>
                    </Container>
                </Form>
            </SpaceBetween>
        </div>
    );
}

export default LaunchConfigurationDetail;
