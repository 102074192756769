import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_GATEWAY_ENDPOINT } from "../constants/endpoints";
import {Auth} from "aws-amplify";

export function placeGetRequest(data: Object, onSuccess: (response: AxiosResponse) => void, onError: (error: AxiosError) => void) {
    Auth.currentSession().then(user => {
        axios({
            method: "GET",
            url: API_GATEWAY_ENDPOINT,
            headers: {
                Authorization: user.getIdToken().getJwtToken(),
                'Content-Type': 'application/json'
            },
            params: {
                "body": data
            }
        }).then(res => {
            if(res.status === 200) {
                onSuccess(res);
            }
        }).catch(err => {
            onError(err);
        });
    });
}

export function placePostRequest(data: Object, onSuccess: (response: AxiosResponse) => void, onError: (error: AxiosError) => void) {
    Auth.currentSession().then(user => {
        axios({
            method: "POST",
            url: API_GATEWAY_ENDPOINT,
            headers: {
                Authorization: user.getIdToken().getJwtToken(),
                'Content-Type': 'application/json'
            },
            data: data
        }).then(res => {
            if(res.status === 200) {
                onSuccess(res);
            }
        }).catch(err => {
            onError(err);
        });
    });
}
