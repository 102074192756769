import {AxiosError} from "axios";
import constants from "../constants/strings";
import { FlashbarProps } from "@amzn/awsui-components-react-v3";

export function getErrorMessage(err: AxiosError) {
    const retryMessage : string = err.response?.data["isErrorRetryable"] ?
        "Please retry after sometime." : "Should not retry.";
    return (err.response?.data?.errorMessage || err.response?.data?.message ||constants.BACKEND_EXCEPTION_TEXT) + " " + retryMessage;
}

export function getErrorFlashMessageProps(err: AxiosError) {
    const errorMessageProps : FlashbarProps.MessageDefinition = {
        type: "error",
        content: getErrorMessage(err)
    };
    return errorMessageProps;
}
