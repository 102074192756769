import moment from "moment";

export function formatDate(date: string, oldSeparator: string, newSeparator: string, reverse: boolean) {
    return reverse ?
        date.split(oldSeparator).reverse().join(newSeparator) :
        date.split(oldSeparator).join(newSeparator);
}

export function convertEpochTimeToDDMMYYYY(launchDate: number) {
    //moment.unix(epochTime).format() return in this format "2014-09-08T08:02:17-05:00" (ISO 8601, no fractional seconds)
    return formatDate(moment.unix(launchDate).format().split("T")[0], "-", "-", true);
}

export function getCurrentDate() {
    let currentDate: string = moment().format().split("T")[0];
    return formatDate(currentDate, "-", "-", true);
}

export function convertJavaDateToEpochTimestamp(launchDate: string) {
    // launchDate is like -
    // Apr 20, 2021 12:00:00 AM
    // May 6, 2020 12:00:00 AM
    // Return epoch timestamp in seconds.
    return moment.utc(launchDate, "MMM DD YYYY hh:mm:ss A").unix();
}

export function getFormattedDate(launchDate : string) {
    return convertEpochTimeToDDMMYYYY(convertJavaDateToEpochTimestamp(launchDate));
}
