import React from 'react';
import { Box, Table, Button } from "@amzn/awsui-components-react-v3/polaris";
import {accountTableProps} from "../../../interfaces";
import constants from "../../../constants/strings";
import StatusButton from "./StatusButton";

function AccountConfigTable(props: accountTableProps) {

    return(
        <>
            <Table
                columnDefinitions={[
                    {
                        id: constants.ACCOUNT_NUMBER,
                        header: constants.ACCOUNT_NUMBER,
                        cell: item => item.accountNumber
                    },
                    {
                        id: constants.STATUS,
                        header: constants.STATUS,
                        cell: item =>
                            <StatusButton
                                accountNumber={item.accountNumber}
                                isAccountStatusEnabled={item.status}
                                scac={props.scac}
                                accountType={props.accountType}
                                getAccountConfiguration={props.getAccountConfiguration}
                                disableSearchAccountNumber={props.disableSearchAccountNumber}
                                setToggleStatusMessage={props.setToggleStatusMessage}
                            />
                    },
                    {
                        id: constants.BUSINESS_TYPE,
                        header: constants.BUSINESS_TYPE,
                        cell: item => item.businessType
                    },
                    {
                        id: constants.CONTRACT_TYPE,
                        header: constants.CONTRACT_TYPE,
                        cell: item => item.contractType
                    },{
                        id: constants.COUNTRY_CODE,
                        header: constants.COUNTRY_CODE,
                        cell: item => item.countryCode
                    },
                    {
                        id: constants.DETAILS,
                        header: constants.DETAILS,
                        cell: item =>
                            <Button
                                iconName="angle-right-double"
                                variant="primary"
                                onClick={() => {
                                    props.setPopupAccountObject(item);
                                    props.setShowAccountDetails(true);
                                    props.setShowIMPSDetails(true);
                                }}
                            />
                    }
                ]}
                items={props.tableData}
                empty={
                    <Box textAlign="center" color="inherit">
                        <Box
                            padding={{ bottom: "s" }}
                            variant="p"
                            color="inherit"
                        >
                            {constants.NO_RESOURCES_TO_DISPLAY}
                        </Box>
                    </Box>
                }
            />

        </>
    );
}

export default AccountConfigTable;
