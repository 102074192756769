import React, { useState } from 'react';
import {
    LSMConfigObject,
    LSMConfigBatch,
    LSMFileUploadProps,
    ChargeGroupConfigProps, LSMConfigUploadReportObject
} from "../../../interfaces";
import * as XLSX from 'xlsx';
import constants from "../../../constants/strings";
import { Alert, Button, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import { AxiosError, AxiosResponse } from "axios";
import { placePostRequest } from "../../../utils";
import { LSM_EXCEL_COLUMNS, excelSheetMessage } from "../../../constants/objects";
import { getErrorMessage } from "../../../utils/errorMessage";

function LSMExcelFileParse(props: LSMFileUploadProps) {
    const [lsmConfigObjects, setLSMConfigObjects] = useState<LSMConfigObject[]>([]);
    const [fileTypeAlertVisible, setFileTypeAlertVisible] = React.useState(false);
    const [fileSizeAlertVisible, setFileSizeAlertVisible] = React.useState(false);
    const [fileColumnsAlertVisible, setFileColumnsAlertVisible] = React.useState({ set: false, value: "" });

    function getLSMConfigListToConfigBatchList(lsmConfigObjects: LSMConfigObject[]) {
        const lsmConfigBatchMap = new Map();

        lsmConfigObjects.forEach((lsmConfigObject) => {
            const key = lsmConfigObject.scac + lsmConfigObject.accountNumber + lsmConfigObject.businessType +
                lsmConfigObject.contractType + lsmConfigObject.countryCode

            const chargeGroupConfig: ChargeGroupConfigProps = {
                chargeGroup: lsmConfigObject.chargeGroup,
                clientId: lsmConfigObject.clientId,
                clientProgram: lsmConfigObject.clientProgram,
                costCenterType: lsmConfigObject.costCenterType,
                businessUnitId: lsmConfigObject.businessUnitId,
                contractualLocationNodeId: lsmConfigObject.contractualLocationNodeId,
                contractualLocationNamespace: lsmConfigObject.contractualLocationNamespace,
                planner: lsmConfigObject.planner
            }

            if (lsmConfigBatchMap.has(key)) {
                let lsmConfigBatch: LSMConfigBatch = {
                    scac: lsmConfigObject.scac,
                    accountNumber: lsmConfigObject.accountNumber,
                    businessType: lsmConfigObject.businessType,
                    contractType: lsmConfigObject.contractType,
                    countryCode: lsmConfigObject.countryCode,
                    chargeGroupConfigs: [...lsmConfigBatchMap.get(key).chargeGroupConfigs, chargeGroupConfig]
                };
                lsmConfigBatchMap.set(key, lsmConfigBatch)
            } else {
                lsmConfigBatchMap.set(key, {
                    scac: lsmConfigObject.scac,
                    accountNumber: lsmConfigObject.accountNumber,
                    businessType: lsmConfigObject.businessType,
                    contractType: lsmConfigObject.contractType,
                    countryCode: lsmConfigObject.countryCode,
                    chargeGroupConfigs: [chargeGroupConfig]
                });
            }
        })

        return Array.from(lsmConfigBatchMap.values())
    }


    function getLSMConfigListFromConfigBatch(lsmConfigBatch: LSMConfigBatch) {
        const lsmConfigList: LSMConfigObject[] = [];

        lsmConfigBatch.chargeGroupConfigs.forEach((chargeGroupConfig) => {
            const lsmConfig: LSMConfigObject = {
                scac: lsmConfigBatch.scac,
                accountNumber: lsmConfigBatch.accountNumber,
                businessType: lsmConfigBatch.businessType,
                contractType: lsmConfigBatch.contractType,
                countryCode: lsmConfigBatch.countryCode,
                chargeGroup: chargeGroupConfig.chargeGroup,
                clientId: chargeGroupConfig.clientId,
                clientProgram: chargeGroupConfig.clientProgram,
                costCenterType: chargeGroupConfig.costCenterType,
                businessUnitId: chargeGroupConfig.businessUnitId,
                contractualLocationNodeId: chargeGroupConfig.contractualLocationNodeId,
                contractualLocationNamespace: chargeGroupConfig.contractualLocationNamespace,
                planner: chargeGroupConfig.planner,
            }
            lsmConfigList.push(lsmConfig)
        })

        return lsmConfigList;
    }


    function saveLSMConfig(lsmConfigBatch: LSMConfigBatch, incr: number) {
        const data = {
            "operation": constants.SAVE_LSM_CONFIGURATION,
            "request_body": {
                "scac": lsmConfigBatch.scac,
                "accountNumber": lsmConfigBatch.accountNumber,
                "businessType": lsmConfigBatch.businessType,
                "contractType": lsmConfigBatch.contractType,
                "countryCode": lsmConfigBatch.countryCode,
                "chargeGroupConfigs": lsmConfigBatch.chargeGroupConfigs
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            props.setUploadProgress(uploadProgress => uploadProgress + incr * lsmConfigBatch.chargeGroupConfigs.length)
        }
        const onError = (err: AxiosError) => {
            const failedLSMConfigUploadReportObjects: LSMConfigUploadReportObject[] = []
            const lsmConfigList: LSMConfigObject[] = getLSMConfigListFromConfigBatch(lsmConfigBatch)
            for (const lsmConfigObject of lsmConfigList) {
                failedLSMConfigUploadReportObjects.push(
                    {
                        lsmConfigObject: lsmConfigObject,
                        lsmErrorText: `Failed to save account configuration | Message: ${getErrorMessage(err)}`
                    })
            }

            props.setLSMConfigUploadReportObjects(
                lsmConfigUploadReportObjects => [...lsmConfigUploadReportObjects, ...failedLSMConfigUploadReportObjects])
        }
        placePostRequest(data, onSuccess, onError);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setUploadProgress(() => 0)
        props.setShowLSMConfigUploadReport(false)
        props.setLSMConfigUploadReportObjects(() => [])
        setFileTypeAlertVisible(false)
        setFileColumnsAlertVisible({ set: false, value: "" })
        setFileSizeAlertVisible(false)
        const files = e.target.files;
        if (files) {
            const f = files[0];
            if (!f.name.endsWith(constants.LSM_EXCEL_FILE_EXTENSION)) {
                setFileTypeAlertVisible(true)
                return;
            }
            const reader = new FileReader();
            reader.onload = function (e) {
                if (e.target) {
                    let lsmConfigObjects: LSMConfigObject[] = [];
                    const data = e.target.result;
                    let rawData = XLSX.read(data, { type: 'binary' });
                    const sheetName = rawData.SheetNames[0];
                    const ws: any = rawData.Sheets[sheetName];
                    let dataParse: any = XLSX.utils.sheet_to_json(ws, {
                        raw: true,
                        defval: constants.EMPTY_STRING,
                    });
                    const range = XLSX.utils.decode_range(ws['!ref']);
                    if (dataParse[0][excelSheetMessage]) {
                        range.s.r = 2;
                    }

                    ws['!ref'] = XLSX.utils.encode_range(range);
                    dataParse = XLSX.utils.sheet_to_json(ws, {
                        raw: true,
                        defval: constants.EMPTY_STRING,
                    });

                    const excel_columns = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
                    const sorted_Lsm_excel_columns = Object.assign([], LSM_EXCEL_COLUMNS).sort();

                    // @ts-ignore
                    if (excel_columns.sort().join(',') !== sorted_Lsm_excel_columns.join(',')) {
                        const columnMissing = sorted_Lsm_excel_columns.filter((column) => {
                            // @ts-ignore
                            return !excel_columns.sort().join(',').includes(column) && column
                        });
                        if (columnMissing.length > 0) {
                            setFileColumnsAlertVisible({ set: true, value: `${columnMissing} are missing` });
                            setLSMConfigObjects(lsmConfigObjects)
                            return;
                        }
                    }

                    for (let i = 0; i < dataParse.length; i++) {
                        const lsmConfigObject = {
                            // @ts-ignore
                            scac: dataParse[i].scac,
                            // @ts-ignore
                            accountNumber: dataParse[i].accountNumber,
                            // @ts-ignore
                            businessType: dataParse[i].businessType,
                            // @ts-ignore
                            contractType: dataParse[i].contractType,
                            // @ts-ignore
                            countryCode: dataParse[i].countryCode,
                            // @ts-ignore
                            chargeGroup: dataParse[i].chargeGroup,
                            // @ts-ignore
                            clientId: dataParse[i].clientId,
                            // @ts-ignore
                            clientProgram: dataParse[i].clientProgram,
                            // @ts-ignore
                            costCenterType: dataParse[i].costCenterType,
                            // @ts-ignore
                            businessUnitId: dataParse[i].businessUnitId,
                            // @ts-ignore
                            contractualLocationNodeId: dataParse[i].contractualLocationNodeId,
                            // @ts-ignore
                            contractualLocationNamespace: dataParse[i].contractualLocationNamespace,
                            // @ts-ignore
                            planner: dataParse[i].planner,
                        }
                        lsmConfigObjects.push(lsmConfigObject)
                    }
                    if (lsmConfigObjects.length > constants.LSM_MAX_EXCEL_RECORDS) {
                        setFileSizeAlertVisible(true)
                        return;
                    }
                    if(lsmConfigObjects.length===0) {
                        setFileColumnsAlertVisible({ set: true, value: constants.LSM_CONFIG_MISSING_VALUE_ERROR });
                        return;
                    }

                    setLSMConfigObjects(lsmConfigObjects)
                }
            };
            reader.readAsBinaryString(f)
        }
    }

    const onClick = () => {
        props.setUploadProgress(() => 0)
        props.setShowLSMConfigUploadReport(true)
        props.setLSMConfigUploadReportObjects(() => [])

        const rows = lsmConfigObjects.length;
        const incr = Math.ceil(10000 * (100 / rows)) / 10000;
        const lsmConfigBatchList = getLSMConfigListToConfigBatchList(lsmConfigObjects)

        for (let i = 0; i < lsmConfigBatchList.length; i++) {
            saveLSMConfig(lsmConfigBatchList[i], incr)
        }
    }

    return (
        <div>
            <SpaceBetween direction="vertical" size="l">
                <SpaceBetween direction="horizontal" size="l">
                    <input
                        type="file"
                        onChange={(e) => handleChange(e)}
                    />
                    {(fileTypeAlertVisible || fileColumnsAlertVisible.set || fileSizeAlertVisible) ?
                        <Button disabled iconName="upload" variant="primary"> Upload </Button> :
                        <Button disabled={lsmConfigObjects.length ? false : true} iconName="upload" variant="primary" onClick={onClick}> Upload </Button>}
                </SpaceBetween>
                <Alert
                    onDismiss={() => setFileTypeAlertVisible(false)}
                    visible={fileTypeAlertVisible}
                    dismissAriaLabel="Close alert"
                    dismissible
                    type="error"
                    header="Error"
                >
                    Invalid file type!!!
                </Alert>
                <Alert
                    onDismiss={() => setFileColumnsAlertVisible({ set: false, value: "" })}
                    visible={fileColumnsAlertVisible.set}
                    dismissAriaLabel="Close alert"
                    dismissible
                    type="error"
                    header="Error"
                >
                    {fileColumnsAlertVisible.value}
                </Alert>
                <Alert
                    onDismiss={() => setFileSizeAlertVisible(false)}
                    visible={fileSizeAlertVisible}
                    dismissAriaLabel="Close alert"
                    dismissible
                    type="error"
                    header="Error"
                >
                    Total record(s) can't exceed {constants.LSM_MAX_EXCEL_RECORDS}!!!
                </Alert>
            </SpaceBetween>
        </div>
    )
}
export default LSMExcelFileParse;
