const constants = {
    CHOOSE_CARRIER: "Choose or launch a carrier",
    CHOOSE_ACCOUNT_TYPE: "Choose Account Type",
    INPUT_ACCOUNT_TYPE: "Input New Shipment Account Type",
    CARRIER_CONFIGURATION: "Carrier Configuration",
    LAUNCH_CONFIGURATION: "Launch Configuration",
    PAYMENT_CONFIGURATION: "Payment Configuration",
    CARRIER_STATUS: "Carrier Status",
    PAYMENT_STATUS: "Payment Status",
    CARRIER_DETAILS: "Carrier Details",
    CARRIER_NAME: "Carrier Name",
    DUMMY_API: "To be replaced by correct API url",
    SCAC: "SCAC",
    TRANSPORT_CARRIER_ID: "Transport Carrier ID",
    SHOW_CONFIGURATION: "Show Configuration",
    CLIENT_NAME: "Client Name",
    CHOOSE_CLIENT_NAME: "Choose Client Name",
    ACCOUNT_TYPE: "Account type",
    GL_SEGMENT_HEADING: "Along with the above mapping, the following GL segment mapping is also required:",
    LAUNCH_DATE: "Launch Date",
    LAUNCHED_BY: "Launched By",
    ACCOUNT_TYPE_DESCRIPTION: "Please choose account type from given list or Input new account type in the below input field",
    LAUNCH_DATA_ERROR: "Data for Launch Configuration could not be loaded. Please Refresh.",
    LAUNCH_DATA_UPDATE_ERROR: "There was an error updating the Launch Configuration. Please refresh and try again.",
    GL_DATA_UPDATE_ERROR: "There was an error updating the GL Configuration. Please refresh and try again.",
    LAUNCH: "Launch",
    LAUNCH_CARRIER: "Launch Carrier",
    LAUNCH_SHIPMENT_ACCOUNT_TYPE: "Launch Shipment Account Type",
    LAUNCH_SCAC: "Launch New Scac",
    PAYMENT: "Payment",
    CHARGE_CODE: "Charge Code",
    GL: "GL",
    EMAIL: "Email-IDs to notify",
    LOADING: "Loading",
    UPDATE_CARRIER_CONFIGURATION: "Update Carrier Configuration",
    RESET: "Reset",
    ENABLED: "Enabled",
    DISABLED: "Disabled",
    ADD_ACCOUNTS: "Add Account(s)",
    MANIFEST_CHARGE_CODE: "Manifest Charge Code",
    BUSINESS_TYPE: "BusinessType",
    CHOOSE_BUSINESS_TYPE: "Choose Business Type",
    CONTRACT_TYPE: "ContractType",
    COMMENTS : "Comments",
    CHOOSE_CONTRACT_TYPE: "Choose Contract Type",
    COUNTRY_CODE: "CountryCode",
    CHOOSE_COUNTRY_CODE: "Choose Country Code",
    CHOOSE_A_VALUE: "Choose a value",
    GO_BACK: "Go Back",
    PREVIOUS: "Previous",
    NEXT: "Next",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    DELETE: "Delete",
    ACCOUNT_NUMBER: "AccountNumber",
    STATUS: "Status",
    NO_RESOURCES_TO_DISPLAY: "No resources to display.",
    PAYMENT_DATA_UPDATE_ERROR: "There was an error while updating the Payment Configuration. Please refresh and try again.",
    SAVE: "Save",
    OFA_VENDOR: "OFA Vendor Number",
    SITE_NAME: "Site Name",
    PAYEE_CODE: "Payee Code",
    CURRENCY: "Currency",
    OFA_ORG: "OFA Org",
    PAY_GROUP: "Pay Group",
    OFA_HEADER_DESCRIPTION: "OFA Header Description",
    INVOICE_CHARGE_CODE: "Invoice Charge Code",
    CHOOSE_CHARGE_TYPE: "Choose Charge Type",
    CHOOSE_CHARGE_CODE: "Choose Charge Code",
    ADD_NEW_CHARGE_CODE: "Add a new Charge Code",
    DELETE_CHARGE_CODE: "Delete a Charge Code",
    CHARGE_TYPE: "Charge Type",
    INVOICE_CHARGE_CODES: "Invoice Charge Codes",
    TFS_MATRIX: "TFSMatrix",
    ROOT: "root",
    SEARCH: "Search",
    GO: "Go",
    NA: "N/A",
    COMPLIANCE_CHECK: "Compliance Check",
    EMPTY_STRING: "",
    FIRST_VERSION: "1",
    ZERO_VERSION: "0",
    CLIENT_NAME_LIST: "clientNameList",
    ACCOUNT_TYPES_LIST: "accountTypesList",
    NOT_SELECTED: "Not Selected",
    ACCOUNT_NUMBERS: "Account Numbers",
    CHARGE_CODE_CONFIGURATION: "Charge Code Configuration",
    CHARGE_CODE_DATA_UPDATE_ERROR: "There was an error while updating the Charge Code Configuration. Please refresh and try again.",
    CONFIGURED_RULES_FOR_GL_CODING: "Configured Rules For GLCoding and GLDescription",
    EMAIL_IDS_FOR_NOTIFICATION: "Configure List of Email-IDs",
    ACCOUNT_DATA_UPDATE_ERROR: "There was an error while updating the Account Configuration. Please refresh and try again.",
    RULE_NAME: "Rule Name",
    VALUE: "value",
    RULE_ARGUMENT_NAME: "Rule Argument Name",
    RULE_ARGUMENT_VALUE: "Rule Argument Value",
    RULE_ARGUMENT_MAP: "ruleArgumentMap",
    GL_CODING: "GLCoding",
    GL_DESCRIPTION: "GLDescription",
    RULE_CONFIGURATION_MAP: "ruleConfigurationMap",
    FIXED_COMPANY_CODE: "fixedCompanyCode",
    FIXED_LOCATION: "fixedLocation",
    FIXED_COST_CENTER: "fixedCostCenter",
    FIXED_ACCOUNT: "fixedAccount",
    FIXED_PRODUCT_LINE: "fixedProductLine",
    FIXED_SALES_CHANNEL: "fixedSalesChannel",
    FIXED_PROJECT: "fixedProject",
    FIXED_GL_CODING_DESCRIPTION: "FixedGLCodingDescription",
    FIXED_GL_CODING_DESCRIPTION_1: "fixedGlCodingDescription",
    GL_CODING_DESCRIPTION: "glCodingDescription",
    COMPANY_CODE: "CompanyCode",
    LOCATION: "Location",
    COST_CENTER: "CostCenter",
    ACCOUNT: "Account",
    PRODUCT_LINE: "ProductLine",
    SALES_CHANNEL: "SalesChannel",
    PROJECT: "Project",
    DESCRIPTION: "Description",
    INDEX_OF_RULE_ARGUMENT: "0",
    UNKNOWN_ALIAS: "UNKNOWN",
    ACCOUNT_TYPE_SUCCESS_TEXT: "Account type successfully launched",
    SCAC_SUCCESS_TEXT: "SCAC successfully launched",
    EMPTY_SCAC_ERROR_TEXT: "SCAC is Required.",
    CARRIER_NAME_ERROR_TEXT: "Carrier name is Required.",
    COMMENTS_ERROR_TEXT: "Comments are Required. Enter Reason for deletion, Sim Link.",
    EMPTY_TRANSPORT_CARRIER_ID_ERROR_TEXT: "Transport Carrier ID is Required.",
    TRANSPORT_CARRIER_ID_SHOULD_BE_NUMBER: "Transport Carrier ID should be Number.",
    SCAC_LENGTH_ERROR_TEXT: "SCAC must be between 3 to 6 characters long.",
    ACCOUNT_TYPE_LENGTH_ERROR_TEXT: "Account Type should be less than 30 characters.",
    INVALID_SCAC_ERROR_TEXT: "Invalid SCAC.",
    EMPTY_CLIENT_NAME_ERROR_TEXT: "Client Name is required.",
    EMPTY_ACCOUNT_TYPE_ERROR_TEXT: "Account Type is required.",
    MANIFEST_CHARGE_CODE_ERROR_TEXT: "Enter a Manifest Charge Code.",
    MANIFEST_CHARGE_CODE_FORMAT_ERROR : "Invalid Manifest Charge Code, must be alphanumeric (underscore is allowed)",
    SELECTED_CHARGE_TYPE_ERROR_TEXT: "Charge Type is required.",
    SELECTED_CHARGE_CODE_ERROR_TEXT: "Invoice Charge Code is required.",
    INVOICE_CHARGE_CODES_ERROR_TEXT: "At least one Invoice Charge Code is Required.",
    INVOICE_CHARGE_CODE_FORMAT_ERROR : "Invalid Invoice Charge Code(s), must be alphanumeric(underscore allowed) separated by comma",
    ACCOUNT_NUMBERS_ERROR_TEXT: "At least one Account Number is Required.",
    BUSINESS_TYPE_ERROR_TEXT: "Business Type is required.",
    CONTRACT_TYPE_ERROR_TEXT: "Contract Type is required.",
    COUNTRY_CODE_ERROR_TEXT: "Country Code is required.",
    CHARGE_GROUP_CONFIG_ERROR_TEXT: "Charge Group can't be empty",
    VENDOR_NUMBER_ERROR_TEXT: "Vendor Number is Required",
    SITE_ERROR_TEXT: "Vendor Site Code is Required",
    REQUIRED_GENERIC_ERROR_TEXT: "This field is mandatory",
    PAYEE_ERROR_TEXT: "Payee Code is Required",
    CURRENCY_ERROR_TEXT: "Payment Currency Code is Required",
    OFA_ORG_ERROR_TEXT: "Region is Required",
    PAY_GROUP_ERROR_TEXT: "Pay Group is Required",
    KEYS_DUPLICATE_ERROR_TEXT: "Keys can't be duplicate",
    IMPS_KEYS_LIMIT_EXCEEDED_ERROR_TEXT: "Keys can't be added for more than 5",
    IMPS_KEY_FORMAT_ERROR_TEXT: "Invalid Key(s). Invoice Match Property Set (IMPS) only supports ‘[A-Z]’ & ’_’",
    BACKEND_EXCEPTION_TEXT: "Exception occurred in the backend call. For more information please check the API logs.",
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_PAGE_TOKEN: 0,
    DEFAULT_ALERT_MESSAGE: "TIPSCCS PreProd and Prod tables are same.",
    WIKI_LINK: "https://w.amazon.com/bin/view/Transportation/TransportationFinancialSystems/Auditing/TFSMatrix/",
    FEATURE_REQUEST_SIM_LINK: "https://issues.amazon.com/issues/create?template=2b5a17e9-cfdf-4bd3-ab79-74ee7215cc21",
    REPORT_BUG_TT_LINK: "https://t.corp.amazon.com/create/templates/d2e14e90-3271-4332-af4a-c34bd12d6cf6",
    ADD_IMPS_CONFIG: "Add a new Invoice Match Property Set",
    INVOICE_KEY_ENTRY: "Enter invoice key",
    MANIFEST_KEY_ENTRY: "Enter manifest key",
    DELETE_IMPS_CONFIRMATION: "Are you sure sure you want to delete the selected Invoice Match Property Set (IMPS) key. This can not be undone",

    // API strings.
    FETCH_ALL_ACCOUNT_TYPES: "FETCH_ALL_ACCOUNT_TYPES",
    GET_ACCOUNT_CONFIGURATION: "GET_ACCOUNT_CONFIGURATION",
    GET_ALL_CLIENTS: "GET_ALL_CLIENTS",
    GET_CHARGE_CODE_MAPPING: "GET_CHARGE_CODE_MAPPING",
    GET_LAUNCHED_CARRIERS: "GET_LAUNCHED_CARRIERS",
    GET_PAYMENT_CONFIGURATION: "GET_PAYMENT_CONFIGURATION",
    GET_ACCOUNT_TYPE_FOR_ACCOUNT_NUMBER: "GET_ACCOUNT_TYPE_FOR_ACCOUNT_NUMBER",
    SAVE_LAUNCH_CONFIGURATION: "SAVE_LAUNCH_CONFIGURATION", //post
    LAUNCH_NEW_SCAC : "LAUNCH_NEW_SCAC",
    UPDATE_LAUNCH_CONFIGURATION: "UPDATE_LAUNCH_CONFIGURATION", // post
    CONFIGURE_CHARGE_CODE_MAPPING: "CONFIGURE_CHARGE_CODE_MAPPING", // post
    REMOVE_CHARGE_CODE_FOR_SCAC: "REMOVE_CHARGE_CODE_FOR_SCAC", // post
    SAVE_PAYMENT_CONFIGURATION: "SAVE_PAYMENT_CONFIGURATION", // post
    SAVE_ACCOUNT_CONFIGURATION: "SAVE_ACCOUNT_CONFIGURATION", // post
    SAVE_LSM_CONFIGURATION: "SAVE_LSM_CONFIGURATION",
    SAVE_IMPS_CONFIGURATION: "SAVE_IMPS_CONFIGURATION",
    UPDATE_RULE_ADD_ACCOUNT_NUMBER: "UPDATE_RULE_ADD_ACCOUNT_NUMBER", // post
    UPDATE_RULE_REMOVE_ACCOUNT_NUMBER: "UPDATE_RULE_REMOVE_ACCOUNT_NUMBER", // post
    //TFSCommunicationService APIs Names
    ADD_EMAIL_IDS: "AddEmailIds",       // post
    REMOVE_EMAIL_IDS: "RemoveEmailIds", // post
    GET_EMAIL_IDS: "GetEmailIds",

    // GL page
    CONFIGURE_NEW_SIMPLE_RULE: "CONFIGURE_NEW_SIMPLE_RULE",
    DELETE_SIMPLE_RULE_CONFIGURATION: "DELETE_SIMPLE_RULE_CONFIGURATION",
    GET_ALL_RULES_FOR_RULE_VALIDATION_TYPE: "GET_ALL_RULES_FOR_RULE_VALIDATION_TYPE",
    GET_CONFIGURED_SIMPLE_RULES: "GET_CONFIGURED_SIMPLE_RULES",
    GET_POSSIBLE_VALUE_FOR_RULE_ARGUMENT: "GET_POSSIBLE_VALUE_FOR_RULE_ARGUMENT",
    GET_SIMPLE_RULE_DETAILS: "GET_SIMPLE_RULE_DETAILS",
    UPDATE_SIMPLE_RULE_ARGUMENTS: "UPDATE_SIMPLE_RULE_ARGUMENTS",

    // LSM config
    LSM_MAX_EXCEL_RECORDS: 100,
    LSM_EXCEL_FILE_EXTENSION: ".xls",
    CHARGE_GROUP: "ChargeGroup",
    CLIENT_ID: "ClientId",
    CLIENT_PROGRAM: "ClientProgram",
    COST_CENTER_TYPE: "CostCenterType",
    BUSINESS_UNIT_ID:"BusinessUnitId",
    CONTRACTUAL_LOCATION_NAMESPACE:"ContractualLocationNamespace",
    CONTRACTUAL_LOCATION_NODE_ID:"ContractualLocationNodeId",
    PLANNER:"Planner",
    LSM_ERROR_TEXT: "Error",
    LSM_CONFIG_MISSING_VALUE_ERROR: "Empty file. Please enter values in respected columns",

    ENTER_CHARGE_GROUP: "Enter ChargeGroup",
    ENTER_CLIENT_ID: "Enter ClientId",
    ENTER_CLIENT_PROGRAM: "Enter ClientProgram",
    ENTER_COST_CENTER_TYPE: "Enter CostCenterType",
    ENTER_BUSINESS_UNIT_ID:"Enter BusinessUnitId",
    ENTER_CONTRACTUAL_LOCATION_NAMESPACE:"Enter CLNamespace",
    ENTER_CONTRACTUAL_LOCATION_NODE_ID:"Enter CLNodeId",
    ENTER_PLANNER:"Enter Planner",

    ADD: "Add",
    EDIT: "Edit",
    UPDATE: "Update",
    REMOVE: "Remove",
    DETAILS: "Details",

    // IMPS Config
    IMPS_MAX_EXCEL_RECORDS: 5,
    INVOICE_KEY: "Invoice Key",
    MANIFEST_KEY: "Manifest Key",
    ADD_IMPS_BUTTON: "+ Add Invoice Match Property Set",
    KEYS_DUPLICATE_ALERT_BULK: "Provided Invoice Match Property Set (IMPS) is already present for scac and account number combination. Kindly provide a different value",
    FILE_SIZE_ALERT_BULK: "Rows in the file can't exceed 100",
    IMPS_KEYS_SIZE_ALERT_BULK: "Invoice Match Property Set (IMPS) can not have more than 5 sets for same scac and account number",
    IMPS_KEY_FORMAT_ALERT_TEXT_BULK: "Invoice Match Property Set (IMPS) only supports ‘[A-Z]’ & ’_’",
    INVALID_COLUMNS_ALERT_TEXT: "Invalid Columns",
    FILE_FORMAT_ALERT_TEXT_BULK: "Incorrect file type. Only .xls file type is supported",
    ATTACH_A_FILE_BULK: "Please attach an excel file with the following columns:",

    ENABLE_ACCOUNT_MESSAGE:"All Account Numbers are in ENABLED state.",
    DISABLE_ACCOUNT_MESSAGE:"All Account Numbers are in DISABLED state.",
    ERROR_LENGTH_THRESHOLD: 200,
    ERROR_CONTENT_LENGTH: 150,
}

export default constants;
