import React from 'react';
import { LaunchConfigurationProps } from "../../../interfaces";
import CarrierDetailComponent from "./CarrierDetailComponent";
import LaunchConfigurationDetail from "./LaunchConfigurationDetail";
import { StatusIndicator } from "@amzn/awsui-components-react-v3";
import constants from "../../../constants/strings";

/*
    This shows the Launch Configuration for the Carrier. The Status and the launch date can be changed. The Launch Date change
    will be reflected instantaneously.
 */
function LaunchConfiguration(props: LaunchConfigurationProps) {
    return (
        <div>
            {
                props.launchDataLoading ?
                    <StatusIndicator type="loading">{constants.LOADING}</StatusIndicator> :
                    <div>
                        {
                            <div>
                                <CarrierDetailComponent launchData={props.launchData}/>
                                <br/>
                                <LaunchConfigurationDetail launchErrorText={props.launchErrorText}
                                                           launchDataLoading={props.launchDataLoading}
                                                           launchData={props.launchData}
                                                           updateLaunchConfiguration={props.updateLaunchConfiguration}/>
                            </div>
                        }
                    </div>
            }
        </div>
    );
}

export default LaunchConfiguration;
