import { useEffect, useState } from 'react';
import { Box, Button, Container, FlashbarProps, FormField, Header, Input, Modal, SpaceBetween, Table } from '@amzn/awsui-components-react-v3';
import constants from "../../../constants/strings";
import { placePostRequest } from "../../../utils";
import { isEmailValid } from "../../../utils/errorChecks";
import { AxiosError } from 'axios';
import { UnknownObject, EmailIdListConfigurationProps } from '../../../interfaces';
import { getErrorFlashMessageProps } from '../../../utils/errorMessage';
import MatrixFlashMessage from '../CommonComponents/MatrixFlashMessage';
import MatrixDeleteModal from '../CommonComponents/MatrixDeleteModal';
import "./index.css";

function EmailIdListConfiguration(props: EmailIdListConfigurationProps) {
    const { accountType, scac, emailIds: items, version} = props.emailIdData;
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [emailInput, setEmailInput] = useState("");
    const [flashMessage, setflashMessage] = useState<FlashbarProps.MessageDefinition>();
    const [isSaving, setIsSaving] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");
    const loadError: FlashbarProps.MessageDefinition = props.emailIDFetchError ? { type: 'error', content: props.emailIDFetchError } : {};

    useEffect(() => {
        setIsLoading(props.isEmailIdDataLoading);
    }, [props.isEmailIdDataLoading]);

    const loadEmailIds = () => {
        setSelectedItems([]);
        props.getEmailIdData();
    };

    const handleEmailAddition = () => {
        if (emailInput.trim().length) {
            const newEmailList = emailInput.split(",").map((email) => email.trim()).filter(email => !!email);

            let incorrectEmail = 0;
            newEmailList.forEach(email => {
                const result = isEmailValid(email);
                if (!result)
                    incorrectEmail++;
            });
            if (incorrectEmail || !newEmailList.length) {
                setEmailErrorText(`Email IDs are not in correct format!`);
                return;
            }

            setIsSaving(true);
            let getArgs = {};
            const requestParams = {
                "scac": scac,
                "shipmentAccountType": accountType,
                "version": version,
                "emailIds": newEmailList
            };

            getArgs = {
                "operation": constants.ADD_EMAIL_IDS,
                "request_body": {
                    ...requestParams
                }
            }
            const onError = (err: AxiosError) => {
                setEmailErrorText("");
                setIsSaving(false);
                setIsLoading(false);
                handleCloseModal();
                setflashMessage(getErrorFlashMessageProps(err));
            };
            const onSuccess = () => {
                setflashMessage({ type: 'success', content: 'Email IDs added successfully.' });
                setEmailErrorText("");
                setIsSaving(false);
                setEmailInput("");
                handleCloseModal();
                loadEmailIds();
            };
            placePostRequest(getArgs, onSuccess, onError);
        }
    }

    const handleDeleteSelected = () => {
        let getArgs: UnknownObject = {
            "request_body": {
                "scac": scac,
                "shipmentAccountType": accountType,
                "version": version
            }
        };
        if (selectedItems.length) {
            if (selectedItems.length === items.length) {
                getArgs.operation = constants.REMOVE_EMAIL_IDS;
                getArgs.request_body.emailIds = items;
            }
            else {
                const newList = items.filter(email => selectedItems.includes(email));
                getArgs.operation = constants.REMOVE_EMAIL_IDS;
                getArgs.request_body.emailIds = newList;
            }
            const onError = (err: AxiosError) => {
                setflashMessage(getErrorFlashMessageProps(err));
                setIsLoading(false);
            };
            const onSuccess = () => {
                setflashMessage({ content: "Selected Email IDs Deleted.", type: "success" });
                loadEmailIds();
                setIsLoading(false);
            };
            setIsLoading(true);
            placePostRequest(getArgs, onSuccess, onError);
        }
    };

    const handleCloseModal = () => {
        setEmailErrorText("");
        setModalVisible(false);
    }

    return (
        <Container header={
            <Header variant="h2">
                {constants.EMAIL_IDS_FOR_NOTIFICATION}
            </Header>
        }>
            <SpaceBetween size="m">
                <Modal
                    onDismiss={handleCloseModal}
                    visible={modalVisible}
                    closeAriaLabel="Close modal"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={handleCloseModal}>Cancel</Button>
                                <Button variant="primary" onClick={handleEmailAddition} loading={isSaving}>{isSaving ? "Adding" : "Add"}</Button>
                            </SpaceBetween>
                        </Box>
                    }
                    header="Add Email-IDs"
                >
                    <FormField
                        label="Email ID"
                        errorText={emailErrorText}
                        description="Multiple Email IDs can be separated by comma."
                    >
                        <Input value={emailInput} onChange={(input) => setEmailInput(input.detail.value)} />
                    </FormField>
                </Modal>

                {!!props.emailIDFetchError && <MatrixFlashMessage content={[loadError]} />}
                {!!flashMessage?.content && <MatrixFlashMessage content={[flashMessage]} />}

                <SpaceBetween className="button-container" direction="horizontal" size="m">
                    {!!selectedItems.length && <MatrixDeleteModal buttonClassName="delete-all" buttonName={`Delete Selected (${selectedItems.length})`} onClickConfirm={handleDeleteSelected} />}
                    <Button variant="primary" onClick={() => setModalVisible(true)}>Add Email ID</Button>
                </SpaceBetween>
                <Table
                    onSelectionChange={({ detail }) =>
                        setSelectedItems(detail.selectedItems)
                    }
                    selectedItems={selectedItems}
                    columnDefinitions={[
                        {
                            id: "Email--ID",
                            header: "Email ID",
                            cell: e => e
                        },
                    ]}
                    items={items}
                    loading={isLoading}
                    loadingText="Fetching Email IDs..."
                    selectionType="multi"
                    visibleColumns={[
                        "Email--ID",
                    ]}
                    empty={
                        <Box textAlign="center" color="inherit">
                            <Box
                                padding={{ bottom: "s" }}
                                variant="p"
                                color="inherit"
                            >
                                No Email IDs to display.
                            </Box>
                        </Box>
                    }
                />
            </SpaceBetween>
        </Container>
    )
};

export default EmailIdListConfiguration;