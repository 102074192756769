import {Alert, Box} from "@amzn/awsui-components-react-v3";
import React from "react";
import strings from "../../../constants/strings";

export interface bulkUploadCardComponentProps {
    values: Array<string>;
}

function BulkUploadCardComponent(props: bulkUploadCardComponentProps) {
    let rows = props.values.map((value: string) => {
        return (
            <p><strong>
                {value}
            </strong></p>
        )
    });

    return (
        <Box variant="p">
            <Alert
                visible={true}
                dismissAriaLabel="Close alert"
                header="Excel File Format"
            >
                {strings.ATTACH_A_FILE_BULK}
                {rows}
            </Alert>
        </Box>
    )
}

export default BulkUploadCardComponent;