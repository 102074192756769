import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonDropdown,
    Input,
    Container,
    Form,
    FormField,
    Header,
    SpaceBetween,
    StatusIndicator,
    Grid
} from "@amzn/awsui-components-react-v3/polaris";
import { getCurrentDate, placeGetRequest, placePostRequest } from "../../../utils";
import { AxiosError, AxiosResponse } from "axios";
import constants from "../../../constants/strings";
import moment from "moment";
import { isAccountTypeValid, isScacValid } from "../../../utils/errorChecks";
import { fetchUserAlias } from "../../../utils/authUser";


/**
 * This component is called when the user clicks the "Launch a Shipment Account Type" button.
 */
function CarrierLaunchComponent() {

    const [scac, setScac] = useState<string>(constants.EMPTY_STRING);
    const [scacErrorText, setScacErrorText] = useState<string>(constants.EMPTY_STRING);

    const [clientName, setClientName] = useState<string>(constants.EMPTY_STRING);
    const [clientNameErrorText, setClientNameErrorText] = useState<string>(constants.EMPTY_STRING);
    const [clientNameList, setClientNameList] = useState<string[]>([]);

    const [accountType, setAccountType] = useState<string>(constants.EMPTY_STRING);
    const [accountTypeErrorText, setAccountTypeErrorText] = useState<string>(constants.EMPTY_STRING);
    const [accountTypeDisabled, setAccountTypeDisabled] = useState<boolean>(true);
    const [accountTypesList, setAccountTypesList] = useState<string[]>([]);

    const [alias, setAlias] = useState<string>(constants.UNKNOWN_ALIAS);

    const [launchCarrierUpdateSuccess, setLaunchCarrierUpdateSuccess] = useState<string>(constants.EMPTY_STRING);

    const [launchCarrierUpdateError, setLaunchCarrierUpdateError] = useState<string>(constants.EMPTY_STRING);

    const [launchCarrierUpdateLoading, setLaunchCarrierUpdateLoading] = useState<boolean>(false);


    // Setting up user alias.
    fetchUserAlias(setAlias);

    /**
     * When the user clicks the "Launch a Shipment Account Type" button this function is called. All the client names
     * get populated in the dropdown.
     */
    useEffect(() => {
        const data = {
            "operation": constants.GET_ALL_CLIENTS,
            "request_body": {}
        }
        const onSuccess = (res: AxiosResponse) => {
            setClientNameList(res.data[constants.CLIENT_NAME_LIST]);
        }
        const onError = (err: AxiosError) => {
            console.log(err);
        }

        placeGetRequest(data, onSuccess, onError);
    }, []);

    /**
     * Reset all the state values.
     */
    function setDefaultValues() {
        setScac(constants.EMPTY_STRING);
        setClientName(constants.EMPTY_STRING);
        setAccountTypeDisabled(true);
        setAccountType(constants.EMPTY_STRING);
        setScacErrorText(constants.EMPTY_STRING);
        setClientNameErrorText(constants.EMPTY_STRING);
        setAccountTypeErrorText(constants.EMPTY_STRING);
    }

    /**
     * This function will be called when the "Launch Shipment Account Type" Button is clicked and will make a POST
     * request to add that carrier. Validations are added. If the values added does not pass the
     * validations, no POST call will be made.
     */
    function launchNewCarrier() {
        setLaunchCarrierUpdateLoading(true);

        // Make necessary null validations and error checks.
        if (!isScacValid(scac, setScacErrorText)) {
            return;
        }

        if (clientName === constants.EMPTY_STRING) {
            setClientNameErrorText(constants.EMPTY_CLIENT_NAME_ERROR_TEXT);
            return;
        }

        if (!isAccountTypeValid(accountType, setAccountTypeErrorText)) {
            return;
        }

        const data = {
            "operation": constants.SAVE_LAUNCH_CONFIGURATION,
            "request_body": {
                "scac": scac,
                "shipmentAccountType": accountType,
                "launchedBy": alias,
                "launchDate": moment().utc().unix(),
                "clientName": clientName,
                "enabled": false,
                "paymentEnabled": false,
                "complianceCheckEnabled": false
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setLaunchCarrierUpdateSuccess(constants.ACCOUNT_TYPE_SUCCESS_TEXT);
            setLaunchCarrierUpdateLoading(false);
        }
        const onError = (err: AxiosError) => {
            setLaunchCarrierUpdateError(constants.BACKEND_EXCEPTION_TEXT);
            setLaunchCarrierUpdateLoading(false);
        }
        placePostRequest(data, onSuccess, onError);
    }

    /**
     * Gets all the Account Types corresponding to the clientName selected.
     * Make the API Call using the clientName state.
     *
     * @param clientName selected client name
     */
    function getAccountTypes(clientName: string) {
        setAccountType(constants.EMPTY_STRING);
        setAccountTypeDisabled(true);
        const data = {
            "operation": constants.FETCH_ALL_ACCOUNT_TYPES,
            "request_body": {
                "clientName": clientName
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setAccountTypesList(res.data["accountTypes"][constants.ACCOUNT_TYPES_LIST]);
            setAccountTypeDisabled(false);
        }
        const onError = (err: AxiosError) => {
            console.log(err);
        }
        placeGetRequest(data, onSuccess, onError);
    }

    function getStatusIndicator() {
        if (launchCarrierUpdateError !== constants.EMPTY_STRING) {
            return <StatusIndicator type="error">{launchCarrierUpdateError}</StatusIndicator>;
        } else if (launchCarrierUpdateSuccess !== constants.EMPTY_STRING) {
            return <StatusIndicator type="success">{launchCarrierUpdateSuccess}</StatusIndicator>;
        } else if (launchCarrierUpdateLoading) {
            return <StatusIndicator type="loading">{constants.LOADING}</StatusIndicator>;
        }
    }

    return (
        <Container header= {
            <Header variant="h2">
                {constants.LAUNCH_SHIPMENT_ACCOUNT_TYPE}
            </Header>
        }>
            {
                ((launchCarrierUpdateError !== constants.EMPTY_STRING) || (launchCarrierUpdateSuccess !== constants.EMPTY_STRING) || launchCarrierUpdateLoading) ?
                    getStatusIndicator():
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={setDefaultValues}>{constants.RESET}</Button>
                                <Button variant="primary" onClick={launchNewCarrier}>{constants.LAUNCH_SHIPMENT_ACCOUNT_TYPE}</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField
                                errorText={scacErrorText}
                                label={constants.SCAC}>
                                <Input value={scac} onChange={({detail}) => {
                                    isScacValid(detail.value, setScacErrorText);
                                    setScac(detail.value.toUpperCase());
                                }}/>
                            </FormField>
                            <FormField
                                errorText={clientNameErrorText}
                                label={constants.CLIENT_NAME}>
                                <ButtonDropdown
                                    items={clientNameList.map((clientName) => {
                                        return {
                                            text: clientName, id: clientName, disabled: false
                                        }
                                    })}
                                    onItemClick={({detail}) => {
                                        setClientName(detail.id);
                                        getAccountTypes(detail.id);
                                        setClientNameErrorText(constants.EMPTY_STRING);
                                    }}
                                    disabled={false}
                                >
                                    {clientName === constants.EMPTY_STRING ? constants.CHOOSE_CLIENT_NAME : clientName}
                                </ButtonDropdown>
                            </FormField>
                            <FormField
                                errorText={accountTypeErrorText}
                                label={constants.ACCOUNT_TYPE}
                                description={constants.ACCOUNT_TYPE_DESCRIPTION}>
                                <Grid
                                    gridDefinition={[{ colspan: 5 }, { colspan: 6 }]}>
                                        <ButtonDropdown
                                            items={accountTypesList.map((account) => {
                                                return {
                                                    text: account, id: account, disabled: false
                                                }
                                            })}
                                            onItemClick={({detail}) => {
                                                setAccountType(detail.id);
                                                setAccountTypeErrorText(constants.EMPTY_STRING);
                                            }}
                                            disabled={accountTypeDisabled}
                                        >
                                            {accountType === constants.EMPTY_STRING ? constants.CHOOSE_ACCOUNT_TYPE : accountType}
                                        </ButtonDropdown>

                                        <Input
                                            value={accountType}
                                            onChange={({detail}) => {
                                                isAccountTypeValid(detail.value, setAccountTypeErrorText);
                                                setAccountType(detail.value);
                                            }}
                                            disabled={accountTypeDisabled}
                                            placeholder={constants.INPUT_ACCOUNT_TYPE}
                                        />
                                </Grid>
                            </FormField>
                            <div>
                                <strong>{constants.LAUNCH_DATE}:</strong> {getCurrentDate()}
                            </div>
                            <div>
                                <strong>{constants.LAUNCHED_BY}:</strong> {alias}
                            </div>
                        </SpaceBetween>
                    </Form>
            }
        </Container>
    );
}

export default CarrierLaunchComponent;
