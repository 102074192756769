import {IMPSConfigUploadReportProps} from "../../../interfaces";
import constants from "../../../constants/strings";
import {Box, Header, Table} from "@amzn/awsui-components-react-v3";
import React from "react";

function IMPSConfigUploadReport(props: IMPSConfigUploadReportProps) {

    return (
        <Table
            columnDefinitions={[
                {
                    id: constants.SCAC,
                    header: constants.SCAC,
                    cell: item => item.impsConfigObject.scac || constants.EMPTY_STRING
                },
                {
                    id: constants.ACCOUNT_NUMBER,
                    header: constants.ACCOUNT_NUMBER,
                    cell: item => item.impsConfigObject.accountNumber || constants.EMPTY_STRING
                },
                {
                    id: constants.INVOICE_KEY,
                    header: constants.INVOICE_KEY,
                    cell: item => item.impsConfigObject.invoiceKey || constants.EMPTY_STRING
                },
                {
                    id: constants.MANIFEST_KEY,
                    header: constants.MANIFEST_KEY,
                    cell: item => item.impsConfigObject.manifestKey || constants.EMPTY_STRING
                },
                {
                    id: constants.LSM_ERROR_TEXT,
                    header: constants.LSM_ERROR_TEXT,
                    cell: item => item.impsErrorText || constants.EMPTY_STRING
                }
            ]}
            items={props.impsConfigUploadReportObjects}

            empty={
                <Box textAlign="center" color="inherit">
                    <Box
                        padding={{bottom: "s"}}
                        variant="p"
                        color="inherit"
                    >
                        {constants.NO_RESOURCES_TO_DISPLAY}
                    </Box>
                </Box>
            }
            header={<Header> Failure Report </Header>}
        />
    )
}
export default IMPSConfigUploadReport