import {IMPSDeletePopupProps} from "../../../interfaces";
import {Alert, SpaceBetween} from "@amzn/awsui-components-react-v3";
import * as React from "react";
import {Button} from "@amzn/awsui-components-react-v3/polaris";
import constants from "../../../constants/strings";

function IMPSDeletePopup(props: IMPSDeletePopupProps) {

    return (
        <SpaceBetween size={"xxs"} direction={"vertical"}>

            <Alert
                onDismiss={() => props.setDeletePopover(false)}
                dismissAriaLabel="Close alert"
                dismissible
                type="error"
                header={"Confirm Deletion"}
            >
                <SpaceBetween size={"xxl"} direction={"horizontal"}>
                    {constants.DELETE_IMPS_CONFIRMATION}
                    <SpaceBetween size={"xxl"} direction={"horizontal"}>
                        <Button
                            variant={"normal"}
                            onClick={() => props.setDeletePopover(false)}
                        > Cancel</Button>
                        <Button
                            variant={"primary"}
                            onClick={() => {
                                props.saveInvoiceMatchPropertyConfig(props.addorDeleteInvoiceMatchPropertyConfig, true)
                            }}
                        > Delete
                        </Button>
                    </SpaceBetween>

                </SpaceBetween>
            </Alert>
        </SpaceBetween>
    )
}

export default IMPSDeletePopup;