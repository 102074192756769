import { Flashbar, FlashbarProps, ExpandableSection } from "@amzn/awsui-components-react-v3";
import { useEffect, useState } from "react";
import constants from "../../../constants/strings";

export interface MatrixFlashMessageProps {
    [key: string]: FlashbarProps.MessageDefinition;
}

/**
 *  this adds ability to add multiple error messages at once, Flash error messages will be dissimasable and if error message is more than 200 letter,
    expandable icon(accordion) is visible for better user experience.
*/
const MatrixFlashMessage = (props: { content: FlashbarProps.MessageDefinition[] }) => {
    const [items, setItems] = useState<FlashbarProps.MessageDefinition[]>([])
    const errArray: FlashbarProps.MessageDefinition[] = [];

    useEffect(() => {
        props.content.forEach((e, index) => {
            if (e.content && e.type) {
                const err: FlashbarProps.MessageDefinition = {
                    type: e.type,
                    content: e.content,
                    dismissible: true,
                    dismissLabel: "Dismiss message",
                    id: index.toString(),
                    onDismiss: () => setItems(items => items.filter(item => item.id !== index.toString()))
                }
                errArray.push(err)
                setItems(errArray)
            }
        });
    }, [props.content])

    const accordionHeaderString = (error: any) => {
        let len = error.content.length - (error.content.length - constants.ERROR_CONTENT_LENGTH)
        const str: string = error.content;
        const minStr = str.slice(0, len);
        return minStr
    }

    return <>
        {!!items?.length && items?.map((item: any) => {

            return <>
                {item.content?.length >= constants.ERROR_LENGTH_THRESHOLD &&
                    <div className="expand-css">
                        <ExpandableSection headerText={accordionHeaderString(item)} >
                            <Flashbar items={[item]} />
                        </ExpandableSection>
                    </div>}
                {item.content?.length < constants.ERROR_LENGTH_THRESHOLD &&
                    <Flashbar items={[item]} />}
            </>
        })}
    </>
}

export default MatrixFlashMessage;
