import {useState} from "react";
import {Alert} from "@amzn/awsui-components-react-v3";
import React from "react";
import {AlertProps} from "@amzn/awsui-components-react-v3/polaris/alert/interfaces";

export interface MatrixAlertProps extends AlertProps {
    message: string;
}

function MatrixAlert(props: MatrixAlertProps) {
    const [visible, setVisible] = useState(true);
    return (
        <Alert
            onDismiss={() => setVisible(false)}
            visible={visible}
            dismissAriaLabel="Close alert"
            dismissible={props.dismissible}
            type={props.type}
            header={props.header}
        >
            {props.message}
        </Alert>
    );
}

export default MatrixAlert;
