import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import HomePageComponent from "./Components/CarrierConfiguration/HomePage/index";
import Navbar from "./Components/Navbar/index";
import LandingPageComponent from "./Components/LandingPage/index";

function Routes() {
    return (
        <BrowserRouter>
            <Route path="/" exact><Navbar content = {<LandingPageComponent />} /></Route>
            <Route path="/carrierConfiguration" exact><Navbar content = {<HomePageComponent />}/></Route>
        </BrowserRouter>
    );
}

export default Routes;
