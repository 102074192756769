import React, {useEffect, useState} from 'react';
import {FlashbarProps, Form, Grid, SpaceBetween, Table} from "@amzn/awsui-components-react-v3/polaris";
import constants from "../../../constants/strings";
import { GlCodingProps, RuleArgumentsMapPage } from "../../../interfaces";
import RuleArgumentDataComponent from "./RuleArgumentData";
import {AxiosError, AxiosResponse} from "axios";
import {placePostRequest} from "../../../utils";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";
import MatrixFlashMessage from "../CommonComponents/MatrixFlashMessage";
import {DEFAULT_EMPTY_FLASH_MESSAGE, UPDATE_SUCCESS_FLASH_MESSAGE} from "../../../constants/objects";
import MatrixSaveModal from "../CommonComponents/MatrixSaveModal";
import MatrixDeleteModal from "../CommonComponents/MatrixDeleteModal";

function GLCoding(props: GlCodingProps) {

    const [modifiedRuleArgumentsMap, setModifiedRuleArgumentsMap] = useState(props.rule.ruleArgumentsMap);
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ruleItems, setRuleItems] = useState<RuleArgumentsMapPage[]>([]);

    const [message, setMessage] = useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);
    const TIMEOUT: number = 3000;  // After 3 seconds, page will auto refresh.

    useEffect(() => {
        setModifiedRuleArgumentsMap(props.rule.ruleArgumentsMap);
        setRuleItems(getRuleItems);
    }, [props.rule.ruleArgumentsMap]);

    const getRuleItems = () => {
        let lines: RuleArgumentsMapPage[] = [];
        const ruleArgumentsMap: any = props.rule.ruleArgumentsMap;
        Object.keys(ruleArgumentsMap).forEach(ruleArgumentName => {
            lines.push({
                ruleArgumentName: ruleArgumentName,
                content:
                    <RuleArgumentDataComponent
                        ruleArgumentName={ruleArgumentName}
                        modifiedRuleArgumentsMap={modifiedRuleArgumentsMap}
                        setModifiedRuleArgumentsMap={setModifiedRuleArgumentsMap}
                        possibleValuesList={props.rule.mapOfRuleArgumentValues.get(ruleArgumentName) || []}
                    />
            });
        });
        return lines;
    }

    function onClickSave() {
        setIsLoading(true);
        const data = {
            "operation": constants.UPDATE_SIMPLE_RULE_ARGUMENTS,
            "request_body": {
                "scac": props.rule.scac,
                "shipmentAccountType": props.rule.accountType,
                "ruleValidationType": props.rule.ruleValidationType,
                "ruleTreeId": props.rule.ruleTreeId,
                "ruleName": props.rule.ruleName,
                "ruleArgumentsMap": modifiedRuleArgumentsMap
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setIsLoading(false);
            setMessage(UPDATE_SUCCESS_FLASH_MESSAGE);
            setTimeout(() => {
                props.getGlConfiguration();
            }, TIMEOUT);
        }
        const onError = (err: AxiosError) => {
            setIsLoading(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    function onClickDelete() {
        setIsDeleteLoading(true);
        const data = {
            "operation": constants.DELETE_SIMPLE_RULE_CONFIGURATION,
            "request_body": {
                "ruleName": props.rule.ruleName,
                "ruleTreeId": props.rule.ruleTreeId,
                "ruleValidationType": props.rule.ruleValidationType,
                "scac": props.rule.scac,
                "shipmentAccountType": props.rule.accountType,
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setIsDeleteLoading(false);
            setMessage({type: "success", content: "Simple Rule Configuration successfully deleted."});
            setTimeout(() => {
                props.getGlConfiguration();
            }, TIMEOUT);
        }
        const onError = (err: AxiosError) => {
            setIsDeleteLoading(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    return (
        <SpaceBetween size={"l"}>
            <MatrixFlashMessage content={[message]} />
            <Grid>
                <strong>{constants.RULE_NAME} : </strong>{props.rule.ruleName}
            </Grid>
            <Grid>
                <strong>{constants.DESCRIPTION} : </strong>{props.rule.description}
            </Grid>
            <Table items={ruleItems}
                   columnDefinitions={[
                       {
                           id: constants.RULE_ARGUMENT_NAME,
                           header: constants.RULE_ARGUMENT_NAME,
                           cell: item => item.ruleArgumentName
                       },
                       {
                           id: constants.RULE_ARGUMENT_VALUE,
                           header: constants.RULE_ARGUMENT_VALUE,
                           cell: item => item.content
                       }
                   ]}
            />
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <MatrixDeleteModal buttonName={"Delete"} buttonLoading={isDeleteLoading} onClickConfirm={onClickDelete}/>
                        <MatrixSaveModal buttonName={constants.SAVE} buttonLoading={isLoading} onClickConfirm={onClickSave}/>
                    </SpaceBetween>
                }
            >
            </Form>
        </SpaceBetween>
    )
}

export default GLCoding;
