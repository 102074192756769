import React, {useContext, useEffect, useState} from 'react';
import GLCoding from "./GLCoding";
import {
    Button, ButtonDropdown, ButtonDropdownProps,
    Container,
    FlashbarProps,
    Form, FormField, Grid,
    Header,
    SpaceBetween,
    StatusIndicator, Table,
    Tabs
} from "@amzn/awsui-components-react-v3/polaris";
import constants from "../../../constants/strings";
import {GlConfigurationProps, RuleArgumentProps, RuleArgumentsMapPage, SimpleRuleProps} from "../../../interfaces";
import {placeGetRequest, placePostRequest} from "../../../utils";
import {AxiosError, AxiosResponse} from "axios";
import RuleArgumentDataComponent from "./RuleArgumentData";
import {
    getPossibleValueForRuleArgument,
    isAccount,
    isCompanyCode,
    isCostCenter, isDescription,
    isLocation,
    isProductLine, isProject, isSalesChannel
} from "./helper";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";
import MatrixFlashMessage from "../CommonComponents/MatrixFlashMessage";
import {DEFAULT_EMPTY_FLASH_MESSAGE} from "../../../constants/objects";
import MatrixSaveModal from "../CommonComponents/MatrixSaveModal";
import { GlCodingContext } from '../../../contexts/contexts';


function GLConfiguration(props: GlConfigurationProps) {
    const [activeTabId, setActiveTabId] = useState<string>(constants.COMPANY_CODE);

    const [configureNewRule, setConfigureNewRule] = useState<boolean>(false);
    const [ruleValidationType, setRuleValidationType] = useState<string>("Select Rule Validation Type");
    const [simpleRuleMap, setSimpleRuleMap] = useState<Map<string, SimpleRuleProps>>(new Map<string, SimpleRuleProps>());
    const [simpleRuleName, setSimpleRuleName] = useState<string>("Select Rule to Configure");
    const [simpleRuleNameButtonDropdownLoading, setSimpleRuleNameButtonDropdownLoading] = useState<boolean>(false);
    const [tableItems, setTableItems] = useState<RuleArgumentsMapPage[]>([]);
    const [modifiedRuleArgumentsMap, setModifiedRuleArgumentsMap] = useState<any>({});
    const [displayTable, setDisplayTable] = useState<boolean>(false);
    const [ruleTreeId, setRuleTreeId] = useState<number>(0);
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);
    const { gLCodingRuleConfigurationMap } = useContext(GlCodingContext);

    const tabs = [
        {
            label: constants.COMPANY_CODE,
            id: constants.COMPANY_CODE,
            content: <GLCoding rule={props.companyCode} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        },
        {
            label: constants.LOCATION,
            id: constants.LOCATION,
            content: <GLCoding rule={props.locationCode} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        },
        {
            label: constants.COST_CENTER,
            id: constants.COST_CENTER,
            content: <GLCoding rule={props.costCenter} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        },
        {
            label: constants.ACCOUNT,
            id: constants.ACCOUNT,
            content: <GLCoding rule={props.account} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        },
        {
            label: constants.PRODUCT_LINE,
            id: constants.PRODUCT_LINE,
            content: <GLCoding rule={props.productLine} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        },
        {
            label: constants.SALES_CHANNEL,
            id: constants.SALES_CHANNEL,
            content: <GLCoding rule={props.salesChannel} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        },
        {
            label: constants.PROJECT,
            id: constants.PROJECT,
            content: <GLCoding rule={props.projectCode} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        },
        {
            label: constants.DESCRIPTION,
            id: constants.DESCRIPTION,
            content: <GLCoding rule={props.description} isGlConfigTableLoading={props.isGlConfigTableLoading} getGlConfiguration={props.getGlConfiguration}/>,
        }
    ];

    function toggleConfigureNewRule() {
        setConfigureNewRule(!configureNewRule);
    }

    function getAllRulesForRuleValidationType(ruleValidationType: string) {
        setSimpleRuleNameButtonDropdownLoading(true);
        setDisplayTable(false);
        setSimpleRuleName("Select Rule to Configure");
        const data = {
            "operation": constants.GET_ALL_RULES_FOR_RULE_VALIDATION_TYPE,
            "request_body": {
                "ruleValidationType": ruleValidationType
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            let simpleRuleMap: Map<string, SimpleRuleProps> = new Map<string, SimpleRuleProps>();
            const simpleRuleList: SimpleRuleProps[] = res.data[ruleValidationType]["simpleRuleList"];
            simpleRuleList.forEach(simpleRule => {
                simpleRuleMap.set(simpleRule.ruleName, simpleRule);
            });
            setSimpleRuleMap(simpleRuleMap);
            setSimpleRuleNameButtonDropdownLoading(false);
        };
        const onError = (err: AxiosError) => {};
        placeGetRequest(data, onSuccess, onError);
    }

    function getSimpleRuleDetails(simpleRuleName: string) {
        setDisplayTable(false);
        const data = {
            "operation": constants.GET_SIMPLE_RULE_DETAILS,
            "request_body": {
                "simpleRuleName": simpleRuleName
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            const ruleArgumentList: RuleArgumentProps[] = res.data[simpleRuleName]["ruleArgumentList"];
            const ruleType: string = res.data[simpleRuleName]["ruleMetaData"]["ruleType"];
            let mapOfPossibleValuesList: Map<string, string[]> = new Map<string, string[]>();
            let ruleArgumentsMap: any = new Map();
            ruleArgumentList.forEach(value => {
                let tempMap : any = new Map();
                tempMap.set("listofPossibleValuesExist", value.ruleArgumentDetails.listofPossibleValuesExist);
                tempMap.set("ruleArgumentDataType", value.ruleArgumentDetails.ruleArgumentDataType);
                tempMap.set("ruleArgumentDescription", value.ruleArgumentDetails.ruleArgumentDescription);
                tempMap.set("value", null);
                ruleArgumentsMap.set(value.ruleArgumentName, Object.fromEntries(tempMap));
                mapOfPossibleValuesList.set(
                    value.ruleArgumentName,
                    getPossibleValueForRuleArgument(value.ruleArgumentName, ruleType, props.scac, props.accountType,
                        value.ruleArgumentDetails.listofPossibleValuesExist)
                );
            });
            console.log(mapOfPossibleValuesList);
            setModifiedRuleArgumentsMap(Object.fromEntries(ruleArgumentsMap));
            let lines: RuleArgumentsMapPage[] = [];
            ruleArgumentList.forEach(value => {
                lines.push({
                    ruleArgumentName: value.ruleArgumentName,
                    content:
                        <RuleArgumentDataComponent
                            ruleArgumentName={value.ruleArgumentName}
                            modifiedRuleArgumentsMap={Object.fromEntries(ruleArgumentsMap)}
                            setModifiedRuleArgumentsMap={setModifiedRuleArgumentsMap}
                            possibleValuesList={mapOfPossibleValuesList.get(value.ruleArgumentName) || []}
                        />
                });
            });
            setTableItems(lines);
            setDisplayTable(true);
            setSimpleRuleNameButtonDropdownLoading(false);
        };
        const onError = (err: AxiosError) => {};
        placeGetRequest(data, onSuccess, onError);
    }

    useEffect(() => {
        setSimpleRuleNameButtonDropdownLoading(true);
        setMessage(DEFAULT_EMPTY_FLASH_MESSAGE);
        if (isCompanyCode(simpleRuleName)) {
            setRuleTreeId(props.companyCode.ruleTreeId);
        } else if (isLocation(simpleRuleName)) {
            setRuleTreeId(props.locationCode.ruleTreeId);
        } else if (isCostCenter(simpleRuleName)) {
            setRuleTreeId(props.costCenter.ruleTreeId);
        } else if (isAccount(simpleRuleName)) {
            setRuleTreeId(props.account.ruleTreeId);
        } else if (isProductLine(simpleRuleName)) {
            setRuleTreeId(props.productLine.ruleTreeId);
        } else if (isSalesChannel(simpleRuleName)) {
            setRuleTreeId(props.salesChannel.ruleTreeId);
        } else if (isProject(simpleRuleName)) {
            setRuleTreeId(props.projectCode.ruleTreeId);
        } else if (isDescription(simpleRuleName)) {
            setRuleTreeId(props.description.ruleTreeId);
        }
    }, [simpleRuleName]);

    function saveNewGlRule() {
        setIsSaveLoading(true);
        const data = {
            "operation": constants.CONFIGURE_NEW_SIMPLE_RULE,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "ruleValidationType": ruleValidationType,
                "ruleTreeId": ruleTreeId,
                "ruleName": simpleRuleName,
                "ruleArgumentsMap": modifiedRuleArgumentsMap
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            props.getGlConfiguration();
            setIsSaveLoading(false);
            setMessage({type: "success", content: "New Simple Rule successfully configured."});
        }
        const onError = (err: AxiosError) => {
            setIsSaveLoading(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    const getSimpleRuleSegregatedItems = () => {
        const simpleRules: ReadonlyArray<ButtonDropdownProps.ItemOrGroup> = Array.from(simpleRuleMap.keys())
            .map(value => {
                const ruleType = simpleRuleMap.get(value)?.ruleType
                const ruleTypeExist = Object.keys(gLCodingRuleConfigurationMap)?.find((ruleConfigurationKey) => {
                    return ruleType === gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleType"]
                })
                return {
                    text: value,
                    id: value,
                    disabled: ruleTypeExist ? true : false
                }
            });
        let simpleRuleSegregatedItems: Array<ButtonDropdownProps.ItemOrGroup> = [];
        [
            constants.COMPANY_CODE,
            constants.LOCATION,
            constants.COST_CENTER,
            constants.ACCOUNT,
            constants.PRODUCT_LINE,
            constants.SALES_CHANNEL,
            constants.PROJECT,
            constants.DESCRIPTION
        ].forEach(value => {
            let items: ReadonlyArray<ButtonDropdownProps.ItemOrGroup> = [];
            if (value === constants.COMPANY_CODE) {
                items = simpleRules.filter(simpleRule => isCompanyCode(simpleRule.id || constants.EMPTY_STRING));                
            } else if (value === constants.LOCATION) {
                items = simpleRules.filter(simpleRule => isLocation(simpleRule.id || constants.EMPTY_STRING));
            } else if (value === constants.COST_CENTER) {
                items = simpleRules.filter(simpleRule => isCostCenter(simpleRule.id || constants.EMPTY_STRING));
            } else if (value === constants.ACCOUNT) {
                items = simpleRules.filter(simpleRule => isAccount(simpleRule.id || constants.EMPTY_STRING));
            } else if (value === constants.PRODUCT_LINE) {
                items = simpleRules.filter(simpleRule => isProductLine(simpleRule.id || constants.EMPTY_STRING));
            } else if (value === constants.SALES_CHANNEL) {
                items = simpleRules.filter(simpleRule => isSalesChannel(simpleRule.id || constants.EMPTY_STRING));
            } else if (value === constants.PROJECT) {
                items = simpleRules.filter(simpleRule => isProject(simpleRule.id || constants.EMPTY_STRING));
            } else if (value === constants.DESCRIPTION) {
                items = simpleRules.filter(simpleRule => isDescription(simpleRule.id || constants.EMPTY_STRING));
            }
            if (items.length > 0) {
                simpleRuleSegregatedItems.push(
                    {
                        id: value,
                        text: value,
                        disabled: false,
                        items: items
                    }
                );
            }
        });
        return simpleRuleSegregatedItems;
    }

    return (
        <Container header = {
            <Header variant="h2">
                {constants.CONFIGURED_RULES_FOR_GL_CODING}
            </Header>
        }>
            {
                props.isGlConfigTableLoading ?
                    <StatusIndicator type="loading">{constants.LOADING}</StatusIndicator>
                    :
                    <div>
                        {
                            configureNewRule ?
                                <SpaceBetween size={"l"}>
                                    <MatrixFlashMessage content={[message]} />
                                    <Form
                                        actions={
                                            <SpaceBetween direction="horizontal" size="xs">
                                                <Button variant="link" onClick={toggleConfigureNewRule}>{constants.CANCEL}</Button>
                                                <MatrixSaveModal buttonName={constants.SAVE} buttonLoading={isSaveLoading} onClickConfirm={saveNewGlRule}/>
                                            </SpaceBetween>
                                        }
                                        header={
                                            <Header
                                                variant="h3"
                                            >
                                                {"Add new Rule to GLCoding / GLDescription rules"}
                                            </Header>
                                        }
                                    >
                                        <SpaceBetween direction="vertical" size="xxl">
                                            <SpaceBetween direction="horizontal" size="xxl">
                                                <FormField
                                                    label={"Rule Validation Type"}>
                                                    <ButtonDropdown
                                                        items={[
                                                            { text: constants.GL_CODING, id: constants.GL_CODING, disabled: false },
                                                            { text: constants.GL_DESCRIPTION, id: constants.GL_DESCRIPTION, disabled: false },
                                                        ]}
                                                        onItemClick={({detail}) => {
                                                            setRuleValidationType(detail.id);
                                                            getAllRulesForRuleValidationType(detail.id);
                                                        }}
                                                        disabled={false}
                                                    >
                                                        {ruleValidationType}
                                                    </ButtonDropdown>
                                                </FormField>
                                                <FormField
                                                    label={"Select Rule to Configure"}>
                                                    <ButtonDropdown
                                                        items={getSimpleRuleSegregatedItems()}
                                                        expandableGroups={true}
                                                        onItemClick={({detail}) => {
                                                            setSimpleRuleName(detail.id);
                                                            getSimpleRuleDetails(detail.id);
                                                        }}
                                                        disabled={false}
                                                        loading={simpleRuleNameButtonDropdownLoading}
                                                    >
                                                        {simpleRuleName}
                                                    </ButtonDropdown>
                                                </FormField>
                                            </SpaceBetween>
                                            {
                                                displayTable ?
                                                    <SpaceBetween direction="vertical" size={"l"}>
                                                        <Grid>
                                                            <strong>{constants.RULE_NAME} : </strong>{simpleRuleName}
                                                        </Grid>
                                                        <Grid>
                                                            <strong>{constants.DESCRIPTION} : </strong>{simpleRuleMap.get(simpleRuleName)?.description}
                                                        </Grid>
                                                        <Table items={tableItems}
                                                               columnDefinitions={[
                                                                   {
                                                                       id: constants.RULE_ARGUMENT_NAME,
                                                                       header: constants.RULE_ARGUMENT_NAME,
                                                                       cell: item => item.ruleArgumentName
                                                                   },
                                                                   {
                                                                       id: constants.RULE_ARGUMENT_VALUE,
                                                                       header: constants.RULE_ARGUMENT_VALUE,
                                                                       cell: item => item.content
                                                                   }
                                                               ]}
                                                        />
                                                    </SpaceBetween>
                                                    :
                                                    null
                                            }
                                        </SpaceBetween>
                                    </Form>
                                </SpaceBetween>
                                :
                                <SpaceBetween size={"l"} direction={"vertical"}>
                                    <Button variant={"normal"} onClick={toggleConfigureNewRule}>+ Configure a new rule</Button>
                                    <Tabs
                                        activeTabId={activeTabId}
                                        tabs={tabs}
                                        onChange={event => {
                                            setActiveTabId(event.detail.activeTabId);
                                        }}
                                    />
                                </SpaceBetween>
                        }
                    </div>
            }
        </Container>
    );
}

export default GLConfiguration;
