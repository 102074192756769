import React, { useState } from "react";
import { SideNavigation } from "@amzn/awsui-components-react-v3/polaris";
import constants from "../../constants/strings";

/*
    Component for Side Navigation Bar
 */
function SideNavbar(){
    const [activeHref, setActiveHref] = useState<string>("/");
    return (
        <SideNavigation
            activeHref={activeHref}
            header={{ href: "/", text: constants.TFS_MATRIX }}
            onFollow={event => {
                if (!event.detail.external) {
                    setActiveHref(event.detail.href);
                }
            }}
            items={[
                { type: "link", text: constants.CARRIER_CONFIGURATION, href: "/carrierConfiguration" },
                { type: "divider" },
            ]}
        />
    );
}

export default SideNavbar;
