export const API_GATEWAY_ENDPOINT = getUrl();

function getUrl() : string {
    try {
        const url = window.location.href.split(".")[0].split("//")[1]
        switch (url) {
            // beta stack
            case "na-beta":
                return "https://j3p1f3egpb.execute-api.us-east-1.amazonaws.com/na-beta"
            case "eu-beta":
                return "https://tde1amjiz2.execute-api.eu-west-1.amazonaws.com/eu-beta"
            case "fe-beta":
                return "https://b3dgovsp5a.execute-api.ap-northeast-1.amazonaws.com/fe-beta"
            case "cn-beta":
                return "https://ky8byyju02.execute-api.ap-northeast-1.amazonaws.com/cn-beta"
            // gamma stack
            case "na-gamma":
                return "https://qcqniw7ba0.execute-api.us-east-1.amazonaws.com/na-gamma"
            case "eu-gamma":
                return "https://xci69o4mp0.execute-api.eu-west-1.amazonaws.com/eu-gamma"
            case "fe-gamma":
                return "https://im8lbyjgkh.execute-api.ap-northeast-1.amazonaws.com/fe-gamma"
            case "cn-gamma":
                return "https://8e5anp03p0.execute-api.ap-northeast-1.amazonaws.com/cn-gamma"
            // preprod stack
            case "na-preprod":
                return "https://o9o1yzzhoi.execute-api.us-east-1.amazonaws.com/na-preprod"
            case "eu-preprod":
                return "https://k888lucnx8.execute-api.eu-west-1.amazonaws.com/eu-preprod"
            case "fe-preprod":
                return "https://yutvngmjci.execute-api.ap-northeast-1.amazonaws.com/fe-preprod"
            case "cn-preprod":
                return "https://uopjobpdg2.execute-api.ap-northeast-1.amazonaws.com/cn-preprod"
            case "na-uat2":
                return "https://ngcedyg5ql.execute-api.us-east-1.amazonaws.com/na-uat2"
            case "eu-uat2":
                return "https://yhmmnifup7.execute-api.eu-west-1.amazonaws.com/eu-uat2"
            // prod stack
            case "na":
                return "https://26eksoj36g.execute-api.us-east-1.amazonaws.com/na-prod"
            case "eu":
                return "https://e4b0kqie9f.execute-api.eu-west-1.amazonaws.com/eu-prod"
            case "fe":
                return "https://qrtych5zl9.execute-api.ap-northeast-1.amazonaws.com/fe-prod"
            case "cn":
                return "https://lg9v472wu0.execute-api.ap-northeast-1.amazonaws.com/cn-prod"
            default:
                // Localhost testing. Replace with required endpoint to hit. Use beta endpoints.
                // Should use the same stage and region as used for authentication.
                return "https://ky8byyju02.execute-api.ap-northeast-1.amazonaws.com/cn-beta"
        }
    } catch (error) {
        throw error
    }
}
