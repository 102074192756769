import React, { useState } from 'react';
import {
    Button,
    Input,
    Container,
    Form,
    FormField,
    Header,
    SpaceBetween,
    StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";
import { getCurrentDate, placePostRequest } from "../../../utils";
import { AxiosError, AxiosResponse } from "axios";
import constants from "../../../constants/strings";
import moment from "moment";
import { isScacValid, isTransportCarrierIDValid, isFieldValid } from "../../../utils/errorChecks";
import { fetchUserAlias } from "../../../utils/authUser";


/**
 * This component is called when the user clicks the "Launch a Scac" button.
 */
function LaunchScacComponent() {

    const [scac, setScac] = useState<string>(constants.EMPTY_STRING);
    const [scacErrorText, setScacErrorText] = useState<string>(constants.EMPTY_STRING);

    const [carrierName, setCarrierName] = useState<string>(constants.EMPTY_STRING);
    const [carrierNameErrorText, setCarrierNameErrorText] = useState<string>(constants.EMPTY_STRING);

    const [transportCarrierID, setTransportCarrierID] = useState<string>(constants.EMPTY_STRING);
    const [transportCarrierIDErrorText, setTransportCarrierIDErrorText] = useState<string>(constants.EMPTY_STRING);


    const [alias, setAlias] = useState<string>(constants.UNKNOWN_ALIAS);

    const [launchCarrierUpdateError, setLaunchCarrierUpdateError] = useState<string>(constants.EMPTY_STRING);
    const [launchCarrierUpdateSuccess, setLaunchCarrierUpdateSuccess] = useState<string>(constants.EMPTY_STRING);
    const [launchCarrierUpdateLoading, setLaunchCarrierUpdateLoading] = useState<boolean>(false);


    // Setting up user alias.
    fetchUserAlias(setAlias);


    /**
     * Reset all the state values.
     */
    function setDefaultValues() {
        setScac(constants.EMPTY_STRING);
        setScacErrorText(constants.EMPTY_STRING);
        setCarrierName(constants.EMPTY_STRING);
        setCarrierNameErrorText(constants.EMPTY_STRING);
        setTransportCarrierID(constants.EMPTY_STRING);
        setTransportCarrierIDErrorText(constants.EMPTY_STRING);
    }

    /**
     * This function will be called when the "Launch New Scac" Button is clicked and will make a POST
     * request to add that carrier to transport carriers table. Validations are added. If the values added does not pass the
     * validations, no POST call will be made.
     */
    function launchNewScac() {

        if (!isTransportCarrierIDValid(transportCarrierID, setTransportCarrierIDErrorText)) {
            return;
        }

        if (!isScacValid(scac, setScacErrorText)) {
            return;
        }

        if (!isFieldValid(carrierName, constants.CARRIER_NAME_ERROR_TEXT, setCarrierNameErrorText)) {
            return;
        }

        setLaunchCarrierUpdateLoading(true);

        const data = {
            "operation": constants.LAUNCH_NEW_SCAC,
            "request_body": {
                "transportCarrierId": transportCarrierID,
                "scac": scac,
                "carrierName": carrierName,
                "launchedBy": alias,
                "launchDate": moment().utc().unix()
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setLaunchCarrierUpdateSuccess(constants.SCAC_SUCCESS_TEXT);
            setLaunchCarrierUpdateLoading(false);
        }
        const onError = (err: AxiosError) => {
            console.log(err);
            setLaunchCarrierUpdateError(constants.BACKEND_EXCEPTION_TEXT);
            setLaunchCarrierUpdateLoading(false);
        }
        placePostRequest(data, onSuccess, onError);
    }


    function getStatusIndicator() {
        if (launchCarrierUpdateError !== constants.EMPTY_STRING) {
            return <StatusIndicator type="error">{launchCarrierUpdateError}</StatusIndicator>;
        } else if (launchCarrierUpdateSuccess !== constants.EMPTY_STRING) {
            return <StatusIndicator type="success">{launchCarrierUpdateSuccess}</StatusIndicator>;
        } else if (launchCarrierUpdateLoading) {
            return <StatusIndicator type="loading">{constants.LOADING}</StatusIndicator>;
        }
    }

    return (
        <Container header= {
            <Header variant="h2">
                {constants.LAUNCH_SCAC}
            </Header>
        }>
            {
                ((launchCarrierUpdateError !== constants.EMPTY_STRING) || (launchCarrierUpdateSuccess !== constants.EMPTY_STRING) || launchCarrierUpdateLoading) ?
                    getStatusIndicator():
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={setDefaultValues}>{constants.RESET}</Button>
                                <Button variant="primary" onClick={launchNewScac}>{constants.LAUNCH_SCAC}</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField
                                errorText={transportCarrierIDErrorText}
                                label={constants.TRANSPORT_CARRIER_ID}>
                                <Input value={transportCarrierID} onChange={({detail}) => {
                                    isTransportCarrierIDValid(detail.value, setTransportCarrierIDErrorText);
                                    setTransportCarrierID(detail.value);
                                }}
                                       placeholder={"Input Transport Carrier ID"}
                                />
                            </FormField>
                            <FormField
                                errorText={scacErrorText}
                                label={constants.SCAC}>
                                <Input value={scac} onChange={({detail}) => {
                                    isScacValid(detail.value, setScacErrorText);
                                    setScac(detail.value.toUpperCase());
                                }}
                                       placeholder={"Input Standard Carrier Alpha Code"}
                                />
                            </FormField>
                            <FormField
                                errorText={carrierNameErrorText}
                                label={constants.CARRIER_NAME}>
                                <Input value={carrierName} onChange={({detail}) => {
                                    isFieldValid(detail.value, constants.CARRIER_NAME_ERROR_TEXT, setCarrierNameErrorText);
                                    setCarrierName(detail.value.toUpperCase());
                                }}
                                       placeholder={"Input Carrier Name"}/>
                            </FormField>

                            <div>
                                <strong>{constants.LAUNCH_DATE}:</strong> {getCurrentDate()}
                            </div>
                            <div>
                                <strong>{constants.LAUNCHED_BY}:</strong> {alias}
                            </div>
                        </SpaceBetween>
                    </Form>
            }
        </Container>
    );
}

export default LaunchScacComponent;
