import React, {useState} from "react";
import {Alert, Box, Button, Modal, SpaceBetween} from "@amzn/awsui-components-react-v3";
import constants from "../../../constants/strings";

export interface MatrixSaveModalProps {
    buttonName: string;
    buttonLoading: boolean;
    onClickConfirm: () => void;
}

export default (props: MatrixSaveModalProps) => {
    const [isSaveModalVisible, setIsSaveModalVisible] = useState<boolean>(false);
    return (
        <>
            {
                <Button variant="primary" loading={props.buttonLoading} onClick={() => setIsSaveModalVisible(true)}>{props.buttonName}</Button>
            }
            <Modal
                visible={isSaveModalVisible}
                onDismiss={() => setIsSaveModalVisible(false)}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setIsSaveModalVisible(false)}>{constants.CANCEL}</Button>
                            <Button variant="primary"
                                    onClick={() => {setIsSaveModalVisible(false); props.onClickConfirm()}}
                            >
                                {constants.CONFIRM}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={"Please confirm before proceeding."}
            >
                <div>
                    {
                        <SpaceBetween size="m">
                            <Alert
                                type={"warning"}
                                header={"Known issues / limitations"}
                            >
                                {"TIPSCCS PreProd and Prod tables are same."}
                            </Alert>
                            <Box variant="p">
                                <Box variant="span">
                                    <Box variant="span" fontWeight="bold">{constants.CONFIRM}</Box>
                                    {" when clicked will "}
                                    <Box variant="span" fontWeight="bold">{"update"}</Box>
                                    {" values in the backend. Please click "}
                                    <Box variant="span" fontWeight="bold">{constants.CONFIRM}</Box>
                                    {" with caution."}
                                </Box>
                            </Box>
                        </SpaceBetween>
                    }
                </div>
            </Modal>
        </>
    );
}
