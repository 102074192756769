import {
    AccountTableObject,
    LSMConfigObject, LSMConfigUploadReportObject,
    CarrierObject,
    ChargeGroupConfigProps,
    MapOfStringTypeValueProps,
    PaymentConfigurationObject,
    GLCodeObject,
    GLCodeErrorObject,
    EmailIdDataProps,
    InvoiceMatchPropertyConfigs
} from "../interfaces";
import constants from "./strings";
import {FlashbarProps, SelectProps} from "@amzn/awsui-components-react-v3";

export const DEFAULT_CARRIER_OBJECT : CarrierObject = {
    id: constants.EMPTY_STRING,
    carrierName: constants.EMPTY_STRING,
    clientName: constants.EMPTY_STRING,
    complianceCheckEnabled: false,
    enabled: false,
    launchDate: constants.EMPTY_STRING,
    launchedBy: constants.EMPTY_STRING,
    paymentEnabled: false,
    scac: constants.EMPTY_STRING,
    shipmentAccountType: constants.EMPTY_STRING
};

export const DEFAULT_PAYMENT_OBJECT : PaymentConfigurationObject = {
    id: constants.EMPTY_STRING,
    ofaVendor: constants.EMPTY_STRING,
    site: constants.EMPTY_STRING,
    payee: constants.EMPTY_STRING,
    currency: constants.EMPTY_STRING,
    ofaOrg: constants.EMPTY_STRING,
    payGroup: constants.EMPTY_STRING,
    ofaHeader: constants.EMPTY_STRING,
    spsVersionId: constants.EMPTY_STRING
};

export const DEFAULT_CHARGE_GROUP_CONFIG : ChargeGroupConfigProps = {
    chargeGroup: constants.EMPTY_STRING,
    clientId: constants.EMPTY_STRING,
    clientProgram: constants.EMPTY_STRING,
    costCenterType: constants.EMPTY_STRING,
    businessUnitId: constants.EMPTY_STRING,
    contractualLocationNodeId: constants.EMPTY_STRING,
    contractualLocationNamespace: constants.EMPTY_STRING,
    planner: constants.EMPTY_STRING
}

export const DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG : InvoiceMatchPropertyConfigs = {
    invoiceKey: constants.EMPTY_STRING,
    manifestKey: constants.EMPTY_STRING
}

export const DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG_LIST : Array<InvoiceMatchPropertyConfigs> = new Array ();

export const DEFAULT_CHARGE_GROUP_CONFIG_MAP : Map<String, ChargeGroupConfigProps> = new Map (
    [[constants.NA, DEFAULT_CHARGE_GROUP_CONFIG]]
)

export const DEFAULT_ACCOUNT_TABLE_OBJECT : AccountTableObject = {
    accountNumber: constants.NA,
    status: false,
    businessType: constants.NA,
    contractType: constants.NA,
    countryCode: constants.NA,
    chargeGroupConfigMap: DEFAULT_CHARGE_GROUP_CONFIG_MAP,
    InvoiceMatchPropertyList: DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG_LIST,
    version: constants.EMPTY_STRING
}

export const DEFAULT_LSM_CONFIG_OBJECT : LSMConfigObject = {
    scac: constants.EMPTY_STRING,
    accountNumber: constants.EMPTY_STRING,
    businessType: constants.EMPTY_STRING,
    contractType: constants.EMPTY_STRING,
    countryCode: constants.EMPTY_STRING,
    chargeGroup: constants.EMPTY_STRING,
    clientId: constants.EMPTY_STRING,
    clientProgram: constants.EMPTY_STRING,
    costCenterType: constants.EMPTY_STRING,
    businessUnitId: constants.EMPTY_STRING,
    contractualLocationNodeId: constants.EMPTY_STRING,
    contractualLocationNamespace: constants.EMPTY_STRING,
    planner: constants.EMPTY_STRING,
}

export const DEFAULT_EMAIL_ID_DATA_OBJECT: EmailIdDataProps = {
    scac: '',
    accountType: '',
    version: 0,
    emailIds: [],
}

export const DEFAULT_LSM_CONFIG_UPLOAD_REPORT_OBJECT : LSMConfigUploadReportObject = {
    lsmConfigObject: DEFAULT_LSM_CONFIG_OBJECT,
    lsmErrorText: constants.EMPTY_STRING,
}

export const LSM_EXCEL_COLUMNS: string[] = ["scac", "accountNumber", "businessType", "contractType", "countryCode",
    "chargeGroup", "clientId", "clientProgram", "costCenterType", "businessUnitId", "contractualLocationNodeId",
    "contractualLocationNamespace", "planner"]

export const IMPS_EXCEL_COLUMNS: string[] = ["scac", "accountNumber", "invoiceKey", "manifestKey"]

export const DEFAULT_INPUT_ACCOUNT_TABLE_OBJECT : AccountTableObject = {
    accountNumber: constants.EMPTY_STRING,
    status: false,
    businessType: "-",
    contractType: "-",
    countryCode: "-",
    chargeGroupConfigMap: DEFAULT_CHARGE_GROUP_CONFIG_MAP,
    InvoiceMatchPropertyList: DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG_LIST,
    version: constants.EMPTY_STRING
}

export const DEFAULT_MAP_OF_STRING_TYPE_VALUE_OBJECT : MapOfStringTypeValueProps = {
    key: constants.EMPTY_STRING,
    value: constants.EMPTY_STRING
}

export const DEFAULT_EMPTY_FLASH_MESSAGE : FlashbarProps.MessageDefinition = {
    content: constants.EMPTY_STRING
}

export const UPDATE_SUCCESS_FLASH_MESSAGE : FlashbarProps.MessageDefinition = {
    type: "success",
    content: "Values successfully updated."
}

export const DELETE_SUCCESS_FLASH_MESSAGE : FlashbarProps.MessageDefinition = {
    type: "success",
    content: "Value successfully deleted."
}

export const DEFAULT_BUSINESS_TYPE_OPTION : SelectProps.Option = {
    value: constants.CHOOSE_BUSINESS_TYPE
}

export const DEFAULT_CONTRACT_TYPE_OPTION : SelectProps.Option = {
    value: constants.CHOOSE_CONTRACT_TYPE
}

export const DEFAULT_COUNTRY_CODE_OPTION : SelectProps.Option = {
    value: constants.CHOOSE_COUNTRY_CODE
}

export const DEFAULT_GL_CODE_OPTION : SelectProps.Option = {
    value: constants.CHOOSE_A_VALUE
}

export const DEFAULT_CHARGE_TYPE_OPTION : SelectProps.Option = {
    value: constants.CHOOSE_CHARGE_TYPE
}

export const DEFAULT_CHARGE_CODE_OPTION : SelectProps.Option = {
    value: constants.CHOOSE_CHARGE_CODE
}

export const DEFAULT_SHIPMENT_ACCOUNT_TYPE_OPTION : SelectProps.Option = {
    value: constants.CHOOSE_ACCOUNT_TYPE
}

export const DEFAULT_GL_CODE_OBJECT: GLCodeObject = {
    accountToAccount: DEFAULT_GL_CODE_OPTION,
    accountToCompanyCode: DEFAULT_GL_CODE_OPTION,
    accountToCostCenter: DEFAULT_GL_CODE_OPTION,
    accountToLocation: DEFAULT_GL_CODE_OPTION,
    accountToProductLine: DEFAULT_GL_CODE_OPTION,
    accountToProject: DEFAULT_GL_CODE_OPTION,
    accountToSalesChannel: DEFAULT_GL_CODE_OPTION,
    chrgCodeToAccount: DEFAULT_GL_CODE_OPTION,
    chrgCodeToCompanyCode: DEFAULT_GL_CODE_OPTION,
    chrgCodeToCostCenter: DEFAULT_GL_CODE_OPTION,
    chrgCodeToLocation: DEFAULT_GL_CODE_OPTION,
    chrgCodeToProductLine: DEFAULT_GL_CODE_OPTION,
    chrgCodeToProject: DEFAULT_GL_CODE_OPTION,
    chrgCodeToSalesChannel: DEFAULT_GL_CODE_OPTION
}

export const DEFAULT_GL_ERROR_OBJECT: GLCodeErrorObject = {
    accountToAccount: constants.EMPTY_STRING,
    accountToCompanyCode: constants.EMPTY_STRING,
    accountToCostCenter: constants.EMPTY_STRING,
    accountToLocation: constants.EMPTY_STRING,
    accountToProductLine: constants.EMPTY_STRING,
    accountToProject: constants.EMPTY_STRING,
    accountToSalesChannel: constants.EMPTY_STRING,
    chrgCodeToAccount: constants.EMPTY_STRING,
    chrgCodeToCompanyCode: constants.EMPTY_STRING,
    chrgCodeToCostCenter: constants.EMPTY_STRING,
    chrgCodeToLocation: constants.EMPTY_STRING,
    chrgCodeToProductLine: constants.EMPTY_STRING,
    chrgCodeToProject: constants.EMPTY_STRING,
    chrgCodeToSalesChannel: constants.EMPTY_STRING
}

export const excelSheetMessage="Please make sure to enter values in the mandatory constraints (marked in yellow) before uploading any new account configuration."
