import React, {useEffect, useState} from 'react';
import { PaymentConfigurationObject, PaymentConfigurationProps } from "../../../interfaces";
import {
    Button,
    Container,
    Header,
    SpaceBetween,
    Input,
    Form,
    FormField,
    StatusIndicator,
    FlashbarProps
} from "@amzn/awsui-components-react-v3";
import { AxiosError, AxiosResponse } from "axios";
import { placePostRequest } from "../../../utils";
import constants from "../../../constants/strings";
import { isFieldValid } from "../../../utils/errorChecks";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";
import MatrixFlashMessage from "../CommonComponents/MatrixFlashMessage";
import {DEFAULT_EMPTY_FLASH_MESSAGE, UPDATE_SUCCESS_FLASH_MESSAGE} from "../../../constants/objects";
import MatrixSaveModal from "../CommonComponents/MatrixSaveModal";


function PaymentConfiguration(props:PaymentConfigurationProps) {
    const [vendorNumber, setVendorNumber] = useState<string>(props.paymentData.ofaVendor);
    const [site, setSite] = useState<string>(props.paymentData.site);
    const [payee, setPayee] = useState<string>(props.paymentData.payee);
    const [currency, setCurrency] = useState<string>(props.paymentData.currency);
    const [ofaOrg, setOfaOrg] = useState<string>(props.paymentData.ofaOrg);
    const [payGroup, setPayGroup] = useState<string>(props.paymentData.payGroup);
    const [ofaHeader, setOfaHeader] = useState<string>(props.paymentData.ofaHeader);
    const [paymentDataUpdating, setPaymentDataUpdating] = useState<boolean>(false);
    const [vendorNumberError, setVendorNumberError] = useState<string>(constants.EMPTY_STRING);
    const [siteError, setSiteError] = useState<string>(constants.EMPTY_STRING);
    const [payeeError, setPayeeError] = useState<string>(constants.EMPTY_STRING);
    const [currencyError, setCurrencyError] = useState<string>(constants.EMPTY_STRING);
    const [ofaOrgError, setOfaOrgError] = useState<string>(constants.EMPTY_STRING);
    const [payGroupError, setPayGroupError] = useState<string>(constants.EMPTY_STRING);
    const [message, setMessage] = useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);

    function setStateToProps() {
        setVendorNumber(props.paymentData.ofaVendor);
        setSite(props.paymentData.site);
        setPayee(props.paymentData.payee);
        setCurrency(props.paymentData.currency);
        setOfaOrg(props.paymentData.ofaOrg);
        setPayGroup(props.paymentData.payGroup);
        setOfaHeader(props.paymentData.ofaHeader);
        setVendorNumberError(constants.EMPTY_STRING);
        setSiteError(constants.EMPTY_STRING);
        setPayeeError(constants.EMPTY_STRING);
        setCurrencyError(constants.EMPTY_STRING);
        setOfaOrgError(constants.EMPTY_STRING);
        setPayGroupError(constants.EMPTY_STRING);
        setMessage(DEFAULT_EMPTY_FLASH_MESSAGE);
    }

    useEffect(() => {
        setStateToProps();
    }, [props.paymentData]);

    function updatePaymentConfigClicked() {

        // TODO : Need to uncomment the below code, once the preprod and prod maps to different tables.
        /*
        // If values are same do not make a call.
            if ((props.paymentData.ofaVendor === vendorNumber)
                && (props.paymentData.site === site)
                && (props.paymentData.payee === payee)
                && (props.paymentData.currency === currency)
                && (props.paymentData.ofaOrg === ofaOrg)
                && (props.paymentData.payGroup === payGroup)
                && (props.paymentData.ofaHeader === ofaHeader)) {
                return;
            }
         */

        // If values are empty set alert and do not make a call.
        if (!isFieldValid(vendorNumber, constants.VENDOR_NUMBER_ERROR_TEXT, setVendorNumberError)
            || !isFieldValid(site, constants.SITE_ERROR_TEXT, setSiteError)
            || !isFieldValid(payee, constants.PAYEE_ERROR_TEXT, setPayeeError)
            || !isFieldValid(currency, constants.CURRENCY_ERROR_TEXT, setCurrencyError)
            || !isFieldValid(ofaOrg, constants.OFA_ORG_ERROR_TEXT, setOfaOrgError)
            || !isFieldValid(payGroup, constants.PAY_GROUP_ERROR_TEXT, setPayGroupError)) {
            return;
        }

        setPaymentDataUpdating(true);
        const updatedPaymentConfiguration: PaymentConfigurationObject = {
            id: props.paymentData.id,
            ofaVendor: vendorNumber,
            site: site,
            payee: payee,
            currency: currency,
            ofaOrg: ofaOrg,
            payGroup: payGroup,
            ofaHeader: ofaHeader,
            spsVersionId: props.paymentData.spsVersionId
        }

        const data = {
            "operation": constants.SAVE_PAYMENT_CONFIGURATION,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "paymentConfiguration": {
                    "vendorNumber": vendorNumber,
                    "vendorSiteCode": site,
                    "payeeCode": payee,
                    "paymentCurrencyCode": currency,
                    "region": ofaOrg,
                    "payGroup": payGroup,
                    "ofaHeaderDesc": ofaHeader,
                    "spsVersionId": props.paymentData.spsVersionId
                }
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            props.updatePaymentConfiguration(updatedPaymentConfiguration);
            setPaymentDataUpdating(false);
            setMessage(UPDATE_SUCCESS_FLASH_MESSAGE);
        }
        const onError = (err: AxiosError) => {
            setPaymentDataUpdating(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    return (
        <div>
            <Container header={
                <Header variant="h2">
                    {constants.PAYMENT_CONFIGURATION}
                </Header>
            }>
            {
                props.paymentDataLoading ?
                    <StatusIndicator type="loading">{constants.LOADING}</StatusIndicator>
                    :
                    <div>
                        {
                            <SpaceBetween size={"l"}>
                                <MatrixFlashMessage content={[message]} />
                                <Form
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <Button onClick={setStateToProps} variant="normal">{constants.RESET}</Button>
                                            <MatrixSaveModal buttonName={constants.SAVE} buttonLoading={paymentDataUpdating} onClickConfirm={updatePaymentConfigClicked}/>
                                        </SpaceBetween>
                                    }
                                >
                                    <SpaceBetween size={"l"} direction={"vertical"}>
                                        <FormField
                                            errorText={vendorNumberError}
                                            label={constants.OFA_VENDOR}>
                                            <Input
                                                onChange={({ detail }) => {
                                                    setVendorNumber(detail.value);
                                                    if (detail.value.length === 0) {
                                                        setVendorNumberError("Vendor Number is Required");
                                                    } else {
                                                        setVendorNumberError(constants.EMPTY_STRING);
                                                    }
                                                }}
                                                value={vendorNumber}
                                            />
                                        </FormField>
                                        <FormField
                                            errorText={siteError}
                                            label={constants.SITE_NAME}>
                                            <Input
                                                onChange={({ detail }) => {
                                                    setSite(detail.value);
                                                    if (detail.value.length === 0) {
                                                        setSiteError("Vendor Site Code is Required");
                                                    } else {
                                                        setSiteError(constants.EMPTY_STRING);
                                                    }
                                                }}
                                                value={site}
                                            />
                                        </FormField>
                                        <FormField
                                            errorText={payeeError}
                                            label={constants.PAYEE_CODE}>
                                            <Input
                                                onChange={({ detail }) => {
                                                    setPayee(detail.value);
                                                    if (detail.value.length === 0) {
                                                        setPayeeError("Payee Code is Required");
                                                    } else {
                                                        setPayeeError(constants.EMPTY_STRING);
                                                    }
                                                }}
                                                value={payee}
                                            />
                                        </FormField>
                                        <FormField
                                            errorText={currencyError}
                                            label={constants.CURRENCY}>
                                            <Input
                                                onChange={({ detail }) => {
                                                    setCurrency(detail.value);
                                                    if (detail.value.length === 0) {
                                                        setCurrencyError("Payment Currency Code is Required");
                                                    } else {
                                                        setCurrencyError(constants.EMPTY_STRING);
                                                    }
                                                }}
                                                value={currency}
                                            />
                                        </FormField>
                                        <FormField
                                            errorText={ofaOrgError}
                                            label={constants.OFA_ORG}>
                                            <Input
                                                onChange={({ detail }) => {
                                                    setOfaOrg(detail.value);
                                                    if (detail.value.length === 0) {
                                                        setOfaOrgError("Region is Required");
                                                    } else {
                                                        setOfaOrgError(constants.EMPTY_STRING);
                                                    }
                                                }}
                                                value={ofaOrg}
                                            />
                                        </FormField>
                                        <FormField
                                            errorText={payGroupError}
                                            label={constants.PAY_GROUP}>
                                            <Input
                                                onChange={({ detail }) => {
                                                    setPayGroup(detail.value);
                                                    if (detail.value.length === 0) {
                                                        setPayGroupError("Pay Group is Required");
                                                    } else {
                                                        setPayGroupError(constants.EMPTY_STRING);
                                                    }
                                                }}
                                                value={payGroup}
                                            />
                                        </FormField>
                                        <FormField label={constants.OFA_HEADER_DESCRIPTION}>
                                            <Input
                                                onChange={({ detail }) => {
                                                    setOfaHeader(detail.value);
                                                }}
                                                value={ofaHeader}
                                            />
                                        </FormField>
                                    </SpaceBetween>
                                </Form>
                            </SpaceBetween>
                        }
                    </div>
            }
            </Container>
        </div>
    )
}

export default PaymentConfiguration;
