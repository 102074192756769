import constants from "../constants/strings";
import {ChargeGroupConfigProps} from "../interfaces";
import {Dispatch} from "react";
import {SelectProps} from "@amzn/awsui-components-react-v3";

export function isScacValid(scac: string, setScacErrorText: Function) {
    if (scac === constants.EMPTY_STRING) {
        setScacErrorText(constants.EMPTY_SCAC_ERROR_TEXT);
        return false;
    } else if (scac.length < 3 || scac.length > 6) {
        setScacErrorText(constants.SCAC_LENGTH_ERROR_TEXT);
        return false;
    } else {
        setScacErrorText(constants.EMPTY_STRING);
        return true;
    }
}

export function isAccountTypeValid(accountType: string, setAccountTypeErrorText: Function) {
    if (accountType === constants.EMPTY_STRING) {
        setAccountTypeErrorText(constants.EMPTY_ACCOUNT_TYPE_ERROR_TEXT);
        return false;
    } else if (accountType.length > 30) {
        setAccountTypeErrorText(constants.ACCOUNT_TYPE_LENGTH_ERROR_TEXT);
        return false;
    } else {
        setAccountTypeErrorText(constants.EMPTY_STRING);
        return true;
    }
}

export function isChargeGroupConfigsValid(chargeGroupConfigs: ChargeGroupConfigProps[]) {
    chargeGroupConfigs.forEach(chargeGroupConfig => {
        if (chargeGroupConfig.chargeGroup) return false;
    });
    return true;
}

export function isFieldValid(field: string, errorString: string, setFieldError: Function) {
    if (field === constants.EMPTY_STRING) {
        setFieldError(errorString);
        return false;
    } else {
        setFieldError(constants.EMPTY_STRING);
        return true;
    }
}

export function isDropdownFieldValid(field: SelectProps.Option, defaultField: SelectProps.Option, errorString: string, setFieldError: Dispatch<string>) {
    if (field.value === defaultField.value) {
        setFieldError(errorString);
        return false;
    } else {
        setFieldError(constants.EMPTY_STRING);
        return true;
    }
}

export function isTransportCarrierIDValid(transportCarrierId: String, setAccountTypeErrorText: Function) {
    if (transportCarrierId === constants.EMPTY_STRING) {
        setAccountTypeErrorText(constants.EMPTY_TRANSPORT_CARRIER_ID_ERROR_TEXT);
        return false;
    } else if (isNaN(Number(transportCarrierId)) || Number(transportCarrierId)<0){
        setAccountTypeErrorText(constants.TRANSPORT_CARRIER_ID_SHOULD_BE_NUMBER);
        return false;
    } else {
        setAccountTypeErrorText(constants.EMPTY_STRING);
        return true;
    }
}

export const isEmailValid = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function isInvoiceChargeCodeInputValid(invoiceChargeCode: string, setInvoiceChargeCodeError: Function, ) {
    const invoiceChargeCodePattern : RegExp = new RegExp(/^\s*[a-zA-Z0-9_/\s](\s*,?\s*[a-zA-Z0-9_/])*\s*$/);

    if (invoiceChargeCode.trim().length === 0) {
        setInvoiceChargeCodeError(constants.INVOICE_CHARGE_CODES_ERROR_TEXT);
        return false;
    } else if (!invoiceChargeCodePattern.test(invoiceChargeCode)) {
        setInvoiceChargeCodeError(constants.INVOICE_CHARGE_CODE_FORMAT_ERROR);
        return false;
    } else {
        setInvoiceChargeCodeError(constants.EMPTY_STRING);
        return true;
    }
}

export function isManifestChargeCodeInputValid(manifestChargeCode: string, setManifestChargeCodeError: Function, ) {
    const manifestChargeCodePattern : RegExp = new RegExp(/^[a-zA-Z0-9_/\s]+$/);

    if (manifestChargeCode.trim().length === 0) {
        setManifestChargeCodeError(constants.MANIFEST_CHARGE_CODE_ERROR_TEXT);
        return false;
    } else if (!manifestChargeCodePattern.test(manifestChargeCode)) {
        setManifestChargeCodeError(constants.MANIFEST_CHARGE_CODE_FORMAT_ERROR);
        return false;
    } else {
        setManifestChargeCodeError(constants.EMPTY_STRING);
        return true;
    }
}
