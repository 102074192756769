const config = {
    NA_REGION: {
        RETAIL_REGION: "na",
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_nqL9HEIZV",
        APP_CLIENT_ID: "48r350thikgc5e7nh6t5q85gkj",
        IDENTITY_POOL_ID: "us-east-1:3bcafd84-e9e6-475f-bea8-7e9dc3db31a2",
        STAGE: "BETA",
        IS_PROD: false
    },
    EU_REGION: {
        RETAIL_REGION: "eu",
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_8MhmwUNnm",
        APP_CLIENT_ID: "6n68a3l119fgbrhqeio2naiq5m",
        IDENTITY_POOL_ID: "eu-west-1:a835368a-3be1-4f62-a35c-410ac075503d",
        STAGE: "BETA",
        IS_PROD: false
    },
    FE_REGION: {
        RETAIL_REGION: "fe",
        REGION: "ap-northeast-1",
        USER_POOL_ID: "ap-northeast-1_YWIqKO0F1",
        APP_CLIENT_ID: "3kdc7r4jmcitul9h8eh1itjcu0",
        IDENTITY_POOL_ID: "ap-northeast-1:4cf05811-129a-4c26-b297-718aafed05d4",
        STAGE: "BETA",
        IS_PROD: false
    },
    CN_REGION: {
        RETAIL_REGION: "cn",
        REGION: "ap-northeast-1",
        USER_POOL_ID: "ap-northeast-1_WmJzoDHhU",
        APP_CLIENT_ID: "17s0p02s9pec8m0cpk3jhdvcho",
        IDENTITY_POOL_ID: "ap-northeast-1:197a76d0-e3c2-4e01-a3fa-ad04454529e5",
        STAGE: "BETA",
        IS_PROD: false
    },
    NA_UAT2_REGION: {
        RETAIL_REGION: "na",
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_vGyMOGmAM",
        APP_CLIENT_ID: "1it757r7lsntb22ir12trpe0hs",
        IDENTITY_POOL_ID: "us-east-1:4322169c-3a3e-4408-8310-a02ff2ac1fe4",
        STAGE: "UAT2",
        IS_PROD: true
    },
    EU_UAT2_REGION: {
        RETAIL_REGION: "eu",
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_CH6rdqek6",
        APP_CLIENT_ID: "70aduqnf3vk9nrgk09sr0csikl",
        IDENTITY_POOL_ID: "eu-west-1:072fa1aa-05b7-4f05-84f7-57fa21bbe44c",
        STAGE: "UAT2",
        IS_PROD: true
    },
    NA_PROD_REGION: {
        RETAIL_REGION: "na",
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_N4x2eCSdJ",
        APP_CLIENT_ID: "1fkr9h6bqcpiu0gvj3p4mhefa9",
        IDENTITY_POOL_ID: "us-east-1:446d24a1-b3f8-4a2e-ae23-f4115b9d5369",
        STAGE: "PROD",
        IS_PROD: true
    },
    EU_PROD_REGION: {
        RETAIL_REGION: "eu",
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_7hWRrOUEa",
        APP_CLIENT_ID: "5ad54f1hii2rv9vtn95emicrbt",
        IDENTITY_POOL_ID: "eu-west-1:11bb3fcc-74c0-4f75-a6b6-2fbd5c818182",
        STAGE: "PROD",
        IS_PROD: true
    },
    FE_PROD_REGION: {
        RETAIL_REGION: "fe",
        REGION: "ap-northeast-1",
        USER_POOL_ID: "ap-northeast-1_DZWTjnUl9",
        APP_CLIENT_ID: "400kr6lqh7af8upjpo4a3el0i9",
        IDENTITY_POOL_ID: "ap-northeast-1:6ea36a8e-9e34-4420-8030-95ae7684be39",
        STAGE: "PROD",
        IS_PROD: true
    },
    CN_PROD_REGION: {
        RETAIL_REGION: "cn",
        REGION: "ap-northeast-1",
        USER_POOL_ID: "ap-northeast-1_zdVbBo7T1",
        APP_CLIENT_ID: "32ujsdbdvr7j8jla2fdc8vr8r9",
        IDENTITY_POOL_ID: "ap-northeast-1:14ad77a4-f297-4a03-b900-eb8ab87d55a6",
        STAGE: "PROD",
        IS_PROD: true
    },
};

export default config;
