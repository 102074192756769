import {ChargeGroupConfigTableProps} from "../../../interfaces";
import constants from "../../../constants/strings";
import {Box, Button, Table} from "@amzn/awsui-components-react-v3";
import React from "react";

function ChargeGroupConfigTable(props: ChargeGroupConfigTableProps) {
    return (
        <Table
            columnDefinitions={[
                {
                    id: constants.CHARGE_GROUP,
                    header: constants.CHARGE_GROUP,
                    cell: item => item.chargeGroup || constants.EMPTY_STRING
                },
                {
                    id: constants.CLIENT_ID,
                    header: constants.CLIENT_ID,
                    cell: item => item.clientId || constants.EMPTY_STRING
                },
                {
                    id: constants.CLIENT_PROGRAM,
                    header: constants.CLIENT_PROGRAM,
                    cell: item => item.clientProgram || constants.EMPTY_STRING
                }, {
                    id: constants.COST_CENTER_TYPE,
                    header: constants.COST_CENTER_TYPE,
                    cell: item => item.costCenterType || constants.EMPTY_STRING
                },
                {
                    id: constants.BUSINESS_UNIT_ID,
                    header: constants.BUSINESS_UNIT_ID,
                    cell: item => item.businessUnitId || constants.EMPTY_STRING
                },
                {
                    id: constants.CONTRACTUAL_LOCATION_NODE_ID,
                    header: constants.CONTRACTUAL_LOCATION_NODE_ID,
                    cell: item => item.contractualLocationNodeId || constants.EMPTY_STRING
                },
                {
                    id: constants.CONTRACTUAL_LOCATION_NAMESPACE,
                    header: constants.CONTRACTUAL_LOCATION_NAMESPACE,
                    cell: item => item.contractualLocationNamespace || constants.EMPTY_STRING
                },
                {
                    id: constants.PLANNER,
                    header: constants.PLANNER,
                    cell: item => item.planner || constants.EMPTY_STRING
                },
                {
                    id: constants.EDIT,
                    header: constants.EDIT,
                    cell: item =>
                        <Button
                            iconName="edit"
                            variant="primary"
                            onClick={() => {
                                props.setAddOrEditChargeGroupConfig(item);
                                props.setAddNewChargeGroup(false);
                                props.setShowAddOrEditChargeGroup(true);
                            }}
                        />
                }
            ]}
            items={props.chargeGroupConfigs}

            empty={
                <Box textAlign="center" color="inherit">
                    <Box
                        padding={{bottom: "s"}}
                        variant="p"
                        color="inherit"
                    >
                        {constants.NO_RESOURCES_TO_DISPLAY}
                    </Box>
                </Box>
            }
        />
    )
}
export default ChargeGroupConfigTable;