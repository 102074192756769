import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import constants from "./constants/strings";
import reportWebVitals from './reportWebVitals';
import { applyDensity, Density } from "@amzn/awsui-global-styles";
import { Amplify, Auth } from 'aws-amplify';
import { getDomain, getRegionSpecificConfiguration } from "./utils";
import '@amzn/awsui-global-styles/polaris.css';
import { Header } from "@amzn/awsui-components-react-v3";

applyDensity(Density.Compact);
const url = window.location.href;

function getConfigurationsObject() {
    try {
        return getRegionSpecificConfiguration(url);
    } catch (err) {
        console.error(err);
        ReactDOM.render(
            <React.StrictMode>
                <Header>
                    No Such Page Exists
                </Header>
            </React.StrictMode>,
            document.getElementById(constants.ROOT)
        );
        reportWebVitals();
        return null;
    }
}

/**
 * The purpose of this function is to return the complete url without any path.
 */
function getRedirectSignInUrl() {
    const SPLIT_REGEX: string = "matrix.tfs.amazon.dev";
    if (url.split(SPLIT_REGEX)[0] === url) {
        return "https://localhost:3000"; // For localhost testing.
    }
    return `${url.split(SPLIT_REGEX)[0]}${SPLIT_REGEX}`;
}

const configurations = getConfigurationsObject();
if (configurations) {
    const domain: string = getDomain(configurations.IS_PROD, configurations.STAGE, configurations.RETAIL_REGION, configurations.REGION);
    const oauth = {
        domain: domain,
        scope: ['openid', 'profile'],
        redirectSignIn: getRedirectSignInUrl(),
        responseType: 'code'
    };
    Amplify.configure({
        Auth: {
            mandatorySignIn: true,
            region: configurations.REGION,
            userPoolId: configurations.USER_POOL_ID,
            identityPoolId: configurations.IDENTITY_POOL_ID,
            userPoolWebClientId: configurations.APP_CLIENT_ID,
            oauth: oauth
        }
    });
    Auth.currentAuthenticatedUser()
        .then(() => {
            // process logged in use
            ReactDOM.render(
                <React.StrictMode>
                    <App />
                </React.StrictMode>,
                document.getElementById(constants.ROOT)
            );
            reportWebVitals();
        })
        .catch(async () => {
            // User not authenticated
            try{
                await Auth.federatedSignIn({customProvider: `FederateProvider-${configurations.RETAIL_REGION}`});
            }
            catch(e) {
                alert(e.message);
            }
        });
}
