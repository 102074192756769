import {Button, SpaceBetween} from "@amzn/awsui-components-react-v3";
import {useState} from "react";
import * as XLSX from 'xlsx';
import {
    IMPSConfigBatch,
    IMPSConfigObject,
    IMPSConfigUploadReportObject,
    IMPSUploadProps,
    InvoiceMatchPropertyConfigs
} from "../../../interfaces";
import constants from "../../../constants/strings";
import strings from "../../../constants/strings";
import {IMPS_EXCEL_COLUMNS} from "../../../constants/objects";
import {AxiosError, AxiosResponse} from "axios";
import {getErrorMessage} from "../../../utils/errorMessage";
import {placePostRequest} from "../../../utils";
import MatrixAlert from "../CommonComponents/MatrixAlert";

function IMPSExcelFileParse(props: IMPSUploadProps) {
    const [impsConfigObjects, setIMPSConfigObjects] = useState<IMPSConfigObject[]>([]);
    const [fileTypeAlertVisible, setFileTypeAlertVisible] = useState<boolean>(false);
    const [fileSizeAlertVisible, setFileSizeAlertVisible] = useState<boolean>(false);
    const [fileColumnsAlertVisible, setFileColumnsAlertVisible] = useState<boolean>(false);
    const [fileDuplicatesAlertVisible, setFileDuplicatesAlertVisible] = useState<boolean>(false);
    const [impsKeysFormatAlertVisible, setImpsKeysFormatAlertVisible] = useState<boolean>(false);
    const [impsKeysSizeAlert, setImpsKeysSizeAlert] = useState<boolean>(false);

    function Onclick() {
        props.setShowImpsConfigUploadReport(true)
        props.setUploadProgress(() => 0)
        props.setImpsConfigUploadReportObjects(() => [])

        if (impsConfigObjects.length > constants.LSM_MAX_EXCEL_RECORDS) {
            setFileSizeAlertVisible(true);
            return;
        }

        const [impsConfigBatchList, apiCallLimitExceeded] = getIMPSConfigListToConfigBatchList(impsConfigObjects);
        const incr = Math.ceil(10000 * (100 / impsConfigObjects.length)) / 10000;

        if (apiCallLimitExceeded) {
            return;
        }

        for (let i = 0; i < impsConfigBatchList.length; i++) {
            saveIMPSConfig(impsConfigBatchList[i], incr);
        }
    }

    function saveIMPSConfig(impsConfigBatch: IMPSConfigBatch, inc: number) {

        const data = {
            "operation": constants.SAVE_IMPS_CONFIGURATION,
            "request_body": {
                "scac": impsConfigBatch.scac,
                "accountNumber": impsConfigBatch.accountNumber,
                "invoiceMatchPropertyConfigs": impsConfigBatch.invoiceMatchPropertyConfigs
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            props.setUploadProgress(uploadProgress => uploadProgress
                + inc * impsConfigBatch.invoiceMatchPropertyConfigs.length);
        }
        const onError = (err: AxiosError) => {
            const failedLSMConfigUploadReportObjects: IMPSConfigUploadReportObject[] = [];
            const impsConfigList: IMPSConfigObject[] = getIMPSConfigListFromConfigBatch(impsConfigBatch)
            for (const impsConfig of impsConfigList) {
                failedLSMConfigUploadReportObjects.push({
                    impsConfigObject: impsConfig,
                    impsErrorText: `Failed to save IMPS configuration | Message: ${getErrorMessage(err)}`
                })
            }
            props.setImpsConfigUploadReportObjects(
                impsConfigUploadReportObjects => [...impsConfigUploadReportObjects, ...failedLSMConfigUploadReportObjects]
            );
        }
        placePostRequest(data, onSuccess, onError);
    }

    function getIMPSConfigListFromConfigBatch(impsConfigBatch: IMPSConfigBatch) {
        const impsConfigList: IMPSConfigObject[] = [];

        impsConfigBatch.invoiceMatchPropertyConfigs.forEach((impsConfig) => {
            const impsConfigObject: IMPSConfigObject = {
                scac: impsConfigBatch.scac,
                accountNumber: impsConfigBatch.accountNumber,
                manifestKey: impsConfig.manifestKey,
                invoiceKey: impsConfig.invoiceKey
            }
            impsConfigList.push(impsConfigObject);
        })
        return impsConfigList;
    }

    function getIMPSConfigListToConfigBatchList(impsConfigObjects: IMPSConfigObject[]): [Array<any>, boolean] {
        const impsConfigBatchMap = new Map();
        let apiCallLimitExceeded = false

        impsConfigObjects.forEach((impsConfigObject) => {
            const key = impsConfigObject.scac + impsConfigObject.accountNumber
            const impsConfig: InvoiceMatchPropertyConfigs = {
                manifestKey: impsConfigObject.manifestKey,
                invoiceKey: impsConfigObject.invoiceKey
            }

            if (impsConfigBatchMap.has(key)) {
                let impsConfigBatch: IMPSConfigBatch = {
                    scac: impsConfigObject.scac,
                    accountNumber: impsConfigObject.accountNumber,
                    invoiceMatchPropertyConfigs: [...impsConfigBatchMap.get(key).invoiceMatchPropertyConfigs, impsConfig]
                };
                impsConfigBatchMap.set(key, impsConfigBatch)

            } else {
                impsConfigBatchMap.set(key, {
                    scac: impsConfigObject.scac,
                    accountNumber: impsConfigObject.accountNumber,
                    invoiceMatchPropertyConfigs: [impsConfig]
                });
            }

            if (impsConfigBatchMap.get(key)?.invoiceMatchPropertyConfigs.length > 5) {
                setImpsKeysSizeAlert(true);
                apiCallLimitExceeded = true
            }
        })

        return [Array.from(impsConfigBatchMap.values()), apiCallLimitExceeded]
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        props.setUploadProgress(() => 0)
        props.setShowImpsConfigUploadReport(false)
        props.setImpsConfigUploadReportObjects(() => [])
        setFileTypeAlertVisible(false)
        setFileSizeAlertVisible(false)
        setFileColumnsAlertVisible(false)
        setFileDuplicatesAlertVisible(false)
        setImpsKeysFormatAlertVisible(false)
        setImpsKeysSizeAlert(false);
        const files = e.target.files;
        if (files) {
            const impsCodePattern: RegExp = new RegExp(/^[A-Z_/]+$/);
            const f = files[0]
            let impsConfig = new Set<string>();

            if (!f.name.endsWith(constants.LSM_EXCEL_FILE_EXTENSION)) {
                setFileTypeAlertVisible(true);
                return;
            }
            const reader = new FileReader();
            reader.onload = function (e) {
                if (e.target) {
                    const data = e.target.result
                    let rawData = XLSX.read(data, {type: 'binary'})
                    const sheetName = rawData.SheetNames[0]
                    const ws = rawData.Sheets[sheetName]
                    const dataParse = XLSX.utils.sheet_to_json(ws, {
                        raw: true,
                        defval: constants.EMPTY_STRING,
                    });

                    const excel_columns = XLSX.utils.sheet_to_json(ws, {header: 1})[0];
                    // @ts-ignore
                    if (excel_columns.sort().join(',') !== IMPS_EXCEL_COLUMNS.sort().join(',')) {
                        setFileColumnsAlertVisible(true)
                        return
                    }

                    let impsConfigObjects: IMPSConfigObject[] = []
                    for (let i = 0; i < dataParse.length; i++) {
                        const impsConfigObject = {
                            // @ts-ignore
                            scac: dataParse[i].scac,
                            // @ts-ignore
                            accountNumber: dataParse[i].accountNumber,
                            // @ts-ignore
                            manifestKey: dataParse[i].manifestKey,
                            // @ts-ignore
                            invoiceKey: dataParse[i].invoiceKey,
                        }

                        // @ts-ignore
                        if (!impsCodePattern.test(dataParse[i].manifestKey)
                            // @ts-ignore
                            || !impsCodePattern.test(dataParse[i].invoiceKey)) {
                            setImpsKeysFormatAlertVisible(true);
                            return
                        }

                        if (impsConfig.has(JSON.stringify(impsConfigObject))) {
                            setFileDuplicatesAlertVisible(true);
                            return false;
                        }

                        impsConfigObjects.push(impsConfigObject)
                        impsConfig.add(JSON.stringify(impsConfigObject));

                    }
                    setIMPSConfigObjects(impsConfigObjects)
                }
            };
            reader.readAsBinaryString(f)
        }
    }

    return (
        <div>
            <SpaceBetween size={"xl"} direction={"vertical"}>
                <input
                    type={"file"}
                    onChange={(e) => handleChange(e)}
                />

                {(fileTypeAlertVisible || fileSizeAlertVisible || fileColumnsAlertVisible
                    || impsKeysSizeAlert || fileDuplicatesAlertVisible || impsKeysFormatAlertVisible) ?
                    <Button disabled iconName={"upload"} variant={"primary"}>Upload</Button> :
                    <Button iconName={"upload"} variant={"primary"} onClick={Onclick}>Upload</Button>}
            </SpaceBetween>
            {
                fileTypeAlertVisible ? <MatrixAlert
                    type={"error"}
                    header={"Error"}
                    dismissible={true}
                    message={strings.FILE_FORMAT_ALERT_TEXT_BULK}
                /> : constants.EMPTY_STRING
            }
            {
                fileSizeAlertVisible ? <MatrixAlert
                    type={"error"}
                    header={"Error"}
                    dismissible={true}
                    message={strings.FILE_SIZE_ALERT_BULK}
                /> : constants.EMPTY_STRING
            }
            {
                fileColumnsAlertVisible ? <MatrixAlert
                    type={"error"}
                    header={"Error"}
                    dismissible={true}
                    message={strings.INVALID_COLUMNS_ALERT_TEXT}
                /> : constants.EMPTY_STRING
            }
            {
                impsKeysSizeAlert ? <MatrixAlert
                    type={"error"}
                    header={"Error"}
                    dismissible={true}
                    message={strings.IMPS_KEYS_SIZE_ALERT_BULK}
                /> : constants.EMPTY_STRING
            }
            {
                fileDuplicatesAlertVisible ? <MatrixAlert
                    type={"error"}
                    header={"Error"}
                    dismissible={true}
                    message={strings.KEYS_DUPLICATE_ALERT_BULK}
                /> : constants.EMPTY_STRING
            }
            {
                impsKeysFormatAlertVisible ? <MatrixAlert
                    type={"error"}
                    header={"Error"}
                    dismissible={true}
                    message={strings.IMPS_KEY_FORMAT_ALERT_TEXT_BULK}
                /> : constants.EMPTY_STRING
            }
        </div>
    )
}

export default IMPSExcelFileParse