import React from 'react';
import "./index.css";
import { Container, Header } from "@amzn/awsui-components-react-v3/polaris";
import { CarrierDetailsProps, CarrierObject } from "../../../interfaces";
import {getFormattedDate} from "../../../utils";
import constants from "../../../constants/strings";

function CarrierDetailComponent(props: CarrierDetailsProps) {
    let launchData: CarrierObject = props.launchData;
    let launchDate: string = props.launchData.launchDate ? getFormattedDate(props.launchData.launchDate) : constants.NA;
    return(
        <Container
                   header={
                       <Header variant="h2">
                           {constants.CARRIER_DETAILS}
                       </Header>
                   }
        >
            <div className="carrier-details">
                <p >
                    <strong>{constants.CARRIER_NAME}:</strong> {launchData.carrierName}
                </p>
                <p>
                    <strong>{constants.CLIENT_NAME}:</strong> {launchData.clientName}
                </p>
                <p>
                    <strong>{constants.LAUNCH_DATE}:</strong> {launchDate}
                </p>
                <p>
                    <strong>{constants.LAUNCHED_BY}:</strong> {launchData.launchedBy}
                </p>
            </div>
        </Container>

    );
}

export default CarrierDetailComponent;
