import React, {Dispatch, useEffect, useState} from 'react';
import { GlCodingContext } from "../../contexts/contexts";
import {
    ConfigurationDisplayProps,
    CarrierObject,
    PaymentConfigurationObject,
    ShipmentAccountObject,
    AccountTableObject,
    InputAccountConfiguration,
    chargeCodeMapObject,
    chargeCodeConfigurationObject,
    RuleObject,
    GLRulePossibleValuesProps,
    EmailIdDataProps
} from "../../interfaces";
import { Tabs } from "@amzn/awsui-components-react-v3/polaris";
import LaunchConfiguration from "./LaunchConfiguration";
import AccountConfiguration from "./AccountConfiguration";
import ChargeCodeConfiguration from "./ChargeCodeConfiguration";
import PaymentConfiguration from "./PaymentConfiguration";
import GLConfiguration from "./GLConfiguration";
import EmailIdListConfiguration from "./EmailIdListConfiguration";
import { AxiosError, AxiosResponse } from "axios";
import constants from "../../constants/strings";
import {DEFAULT_CARRIER_OBJECT, DEFAULT_CHARGE_GROUP_CONFIG_MAP, DEFAULT_PAYMENT_OBJECT,
    DEFAULT_EMAIL_ID_DATA_OBJECT, DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG_LIST} from "../../constants/objects";
import { placeGetRequest } from "../../utils";
import {
    getPossibleValueForRuleArgument,
    isAccount,
    isCompanyCode,
    isCostCenter,
    isLocation, isProductLine, isProject, isSalesChannel
} from "./GLConfiguration/helper";
import {getErrorMessage} from "../../utils/errorMessage";
import {CHECK_CN_REGION} from "../../utils/checkCnRegion";


/**
 * This is rendered when the user enters valid SCAC and account type and hits the "Show Configurations" button.
 * Data for the all the children configuration components are loaded in this page to make sure that
 * unnecessary API calls are avoid whenever the children components re-render which happens a lot of times.
 *
 * @param props ConfigurationDisplayProps
 */
function ConfigurationDisplayComponent (props: ConfigurationDisplayProps) {

    const emptyRuleObject: RuleObject = {
        scac: constants.EMPTY_STRING,
        accountType: constants.EMPTY_STRING,
        ruleName: constants.EMPTY_STRING,
        ruleTreeId: 0,  // TODO: This is not required when configuring a new rule. But for consistency we should set the proper ruleTreeId.
        ruleType: "0",
        ruleValidationType: constants.EMPTY_STRING,
        description: constants.EMPTY_STRING,
        ruleArgumentsMap: {},
        mapOfRuleArgumentValues: new Map()
    };

    const [activeTabId, setActiveTabId] = useState<string>(constants.LAUNCH);
    const [launchData, setLaunchData] = useState<CarrierObject>(DEFAULT_CARRIER_OBJECT);
    const [paymentData, setPaymentData] = useState<PaymentConfigurationObject>(DEFAULT_PAYMENT_OBJECT);
    const [launchDataLoading, setLaunchDataLoading] = useState<boolean>(false);
    const [paymentDataLoading, setPaymentDataLoading] = useState<boolean>(false);
    const [chargeCodeMapList, setChargeCodeMapList] = useState<chargeCodeMapObject[]>([]);
    const [chargeCodeConfigurationList, setChargeCodeConfigurationList] = useState<chargeCodeConfigurationObject[]>([]);
    const [allChargeTypes, setAllChargeTypes] = useState<string[]>([]);
    const [chargeCodeLoading, setChargeCodeLoading] = useState<boolean>(false);
    const [inputAccountConfigurationMap, setInputAccountConfigurationMap] = useState<Map<string, AccountTableObject>>(new Map<string, AccountTableObject>());
    const [shipmentAccountList, setShipmentAccountList] = useState<ShipmentAccountObject[]>([]);
    const [tableLoading, setTableLoading] = useState<boolean>(true);
    const [previousDisabled, setPreviousDisabled] = useState<boolean>(true);
    const [nextDisabled, setNextDisabled] = useState<boolean>(false);
    const [maxPageToken, setMaxPageToken] = useState<number>(0);
    const [pageToken, setPageToken] = useState<number>(0);
    const [businessTypes, setBusinessTypes] = useState<string[]>([]);
    const [contractTypes, setContractTypes] = useState<string[]>([]);
    const [countryCodes, setCountryCodes] = useState<string[]>([]);
    const [companyCode, setCompanyCode] = useState<RuleObject>(emptyRuleObject);
    const [locationCode, setLocationCode] = useState<RuleObject>(emptyRuleObject);
    const [costCenter, setCostCenter] = useState<RuleObject>(emptyRuleObject);
    const [account, setAccount] = useState<RuleObject>(emptyRuleObject);
    const [productLine, setProductLine] = useState<RuleObject>(emptyRuleObject);
    const [salesChannel, setSalesChannel] = useState<RuleObject>(emptyRuleObject);
    const [projectCode, setProjectCode] = useState<RuleObject>(emptyRuleObject);
    const [description, setDescription] = useState<RuleObject>(emptyRuleObject);
    const [isGlCodingLoading, setIsGlCodingLoading] = useState<boolean>(false);
    const [isGlDescriptionLoading, setIsGlDescriptionLoading] = useState<boolean>(false);
    const [isGlConfigTableLoading, setIsGlConfigTableLoading] = useState<boolean>(false);
    const [launchErrorText, setLaunchErrorText] = useState<string>(constants.EMPTY_STRING);
    const [accountErrorText, setAccountErrorText] = useState<string>(constants.EMPTY_STRING);
    const [paymentErrorText, setPaymentErrorText] = useState<string>(constants.EMPTY_STRING);
    const [chargeCodeErrorText, setChargeCodeErrorText] = useState<string>(constants.EMPTY_STRING);
    const [gLErrorText, setGLErrorText] = useState<string>(constants.EMPTY_STRING);
    const [gLCodingRuleConfigurationMap, setGLCodingRuleConfigurationMap] = useState<any>({});
    const [gLCodingRulePossibleValues, setGLCodingRulePossibleValues] = useState<GLRulePossibleValuesProps>();
    const [gLCodingRuleTreeId, setGLCodingRuleTreeId] = useState<number>();
    const [emailIDFetchError, setEmailIDFetchError] = useState<string>("");
    const [isEmailIdDataLoading, setIsEmailIdDataLoading] = useState<boolean>(false);
    const [emailIdData, setEmailIdData] = useState<EmailIdDataProps>(DEFAULT_EMAIL_ID_DATA_OBJECT);

    useEffect(() => {
        setMaxPageToken(Math.max(maxPageToken, pageToken));
        if(pageToken > 0) {
            setPreviousDisabled(false);
        } else {
            setNextDisabled(false);
            setPreviousDisabled(true);
        }
    }, [pageToken]);

    /*
        This also corresponds to the Account Configurations component. This is sent as props to that component and is called
        when the next or previous button (At the bottom of the table) is clicked. Change is either +1 (when next button clicked)
        or -1 (When previous button clicked)
     */
    function changeToken(change: number) {
        const newPageToken: number = pageToken + change;
        if(newPageToken > maxPageToken) {
            const data = {
                "operation": constants.GET_ACCOUNT_CONFIGURATION,
                "request_body": {
                    "scac": props.scac,
                    "shipmentAccountType": props.accountType,
                    "pageToken": newPageToken,
                    "pageSize": constants.DEFAULT_PAGE_SIZE
                }
            }
            const onSuccess = (res: AxiosResponse) => {
                const newShipmentAccountList: ShipmentAccountObject[] = res.data["accountConfigurationOutput"]["accountConfigurations"]["shipmentAccountList"] || [];
                setShipmentAccountList(shipmentAccountList.concat(newShipmentAccountList));
                setPageToken(newPageToken);
                setTableLoading(false);
                if (newShipmentAccountList.length < constants.DEFAULT_PAGE_SIZE) {
                    setNextDisabled(true);
                }
            }
            const onError = (err: AxiosError) => {
                setNextDisabled(true);
                setTableLoading(false);
            }
            setTableLoading(true);
            placeGetRequest(data, onSuccess, onError);
        } else {
            setPageToken(newPageToken);
            setNextDisabled(false);
        }
    }

    /*
        This function is sent as props to the Launch Configuration Component and is called when the user updates the Launch
        Config Data.
     */
    function updateLaunchConfiguration(updatedCarrierConfiguration: CarrierObject) {
        setLaunchData(updatedCarrierConfiguration);
    }

    /*
        This function is sent as props to the Payment Configuration Component and is called when the user updates the Payment
        Config Data.
     */
    function updatePaymentConfiguration(updatePaymentConfiguration: PaymentConfigurationObject) {
        setPaymentData(updatePaymentConfiguration);
    }
    /*
        This function is sent as props to the Charge Code Configuration Component and is called when the user adds a new Charge Code
     */
    function updateChargeCodeTable() {
        setChargeCodeLoading(true);
        const data = {
            "operation": constants.GET_CHARGE_CODE_MAPPING,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setChargeCodeLoading(false);
            setChargeCodeMapList(res.data["chargeCodeMappingOutput"]["chargeCodeMapList"]);
            setChargeCodeConfigurationList(res.data["chargeCodeMappingOutput"]["chargeCodeConfigurationList"]);
        }
        const onError = (err: AxiosError) => {
            setChargeCodeErrorText(getErrorMessage(err));
            setChargeCodeLoading(false);
        }
        placeGetRequest(data, onSuccess, onError);
    }
    /*
        This function is sent as props to the Email list Configuration Component and is called when the user modifieds the emails
     */
    const getEmailIdData = () => {
        setIsEmailIdDataLoading(true);
        const emailIdData = {
            "operation": constants.GET_EMAIL_IDS,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType
            }
        };
        const onEmailIdFetchSuccess = (res: AxiosResponse) => {
            const emailList = res.data.emailIds;
            setEmailIdData({
                version: res.data.version,
                emailIds: emailList.length > 0 ? emailList : [],
                scac: props.scac,
                accountType: props.accountType,
            });
            setIsEmailIdDataLoading(false);
        };
        const onEmailIdFetchError = (err: AxiosError) => {
            setEmailIDFetchError(getErrorMessage(err));
            setIsEmailIdDataLoading(false);
        };
        placeGetRequest(emailIdData, onEmailIdFetchSuccess, onEmailIdFetchError);
    };

    function setDefaultValues() {
        setPreviousDisabled(true);
        setNextDisabled(false);
        setMaxPageToken(constants.DEFAULT_PAGE_TOKEN);
        setPageToken(constants.DEFAULT_PAGE_TOKEN);
    }

    function getAccountConfiguration() {
        const data = {
            "operation": constants.GET_ACCOUNT_CONFIGURATION,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "pageToken": constants.DEFAULT_PAGE_TOKEN,
                "pageSize": constants.DEFAULT_PAGE_SIZE
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setDefaultValues();
            const inputAccountConfigurationList: InputAccountConfiguration[] = res.data["accountConfigurationOutput"]["inputAccountConfigurationList"] || [];
            const shipmentAccountList: ShipmentAccountObject[] = res.data["accountConfigurationOutput"]["accountConfigurations"]["shipmentAccountList"] || [];
            setShipmentAccountList(shipmentAccountList);
            setBusinessTypes(res.data["BusinessType"]["values"] || []);
            setContractTypes(res.data["ContractType"]["values"] || []);
            setCountryCodes(res.data["CountryCode"]["values"] || []);
            setTableLoading(false);
            let mapAccount = new Map<string, AccountTableObject>();
            inputAccountConfigurationList.forEach(inputAccountConfiguration => {
                mapAccount.set(inputAccountConfiguration.accountNumber, {
                    accountNumber: inputAccountConfiguration.accountNumber,
                    status: inputAccountConfiguration.enabled,
                    businessType: inputAccountConfiguration.businessType || constants.EMPTY_STRING,
                    contractType: inputAccountConfiguration.contractType || constants.EMPTY_STRING,
                    countryCode: inputAccountConfiguration.countryCode || constants.EMPTY_STRING,
                    chargeGroupConfigMap: inputAccountConfiguration.chargeGroupConfigurationMap || DEFAULT_CHARGE_GROUP_CONFIG_MAP,
                    InvoiceMatchPropertyList: inputAccountConfiguration.invoiceMatchPropertySetConfiguration ?
                        inputAccountConfiguration.invoiceMatchPropertySetConfiguration['matchKeys'] : DEFAULT_INVOICE_MATCH_PROPERTY_CONFIG_LIST,
                    version: inputAccountConfiguration.version || constants.EMPTY_STRING
                });
            });
            setInputAccountConfigurationMap(mapAccount);
            if (shipmentAccountList.length < constants.DEFAULT_PAGE_SIZE) {
                setNextDisabled(true);
            }
        }
        const onError = (err: AxiosError) => {
            setAccountErrorText(getErrorMessage(err));
            setTableLoading(false);
        }
        setTableLoading(true);
        placeGetRequest(data, onSuccess, onError);
    }

    function getNonNullString(text: string) {
        return text ? text : constants.EMPTY_STRING;
    }

    useEffect(() => {
        setIsGlConfigTableLoading(isGlCodingLoading || isGlDescriptionLoading);
    }, [isGlCodingLoading, isGlDescriptionLoading]);

    function getGlConfiguration() {
        setIsGlCodingLoading(true);
        setIsGlDescriptionLoading(true);

        // Generic function to set the rule values.
        const setRuleObject = (ruleFunction: Dispatch<RuleObject>, ruleName: string, ruleArgumentMap: any, description: string,
            ruleValidationType: string, ruleTreeId: number, ruleType: string) => {
            const mapOfRuleArgumentValues: Map<string, string[]> = new Map<string, string[]>();
            Object.keys(ruleArgumentMap ? ruleArgumentMap : {}).forEach(ruleArgumentName => {
                const possibleValues = getPossibleValueForRuleArgument(ruleArgumentName, ruleType, props.scac, props.accountType,
                    ruleArgumentMap[ruleArgumentName]["listofPossibleValuesExist"]);
                setGLCodingRulePossibleValues((lastVal) => {
                    return { ...lastVal, [ruleArgumentName]: possibleValues }
                })
                mapOfRuleArgumentValues.set(
                    ruleArgumentName, possibleValues
                );
            });
            ruleFunction({
                scac: props.scac,
                accountType: props.accountType,
                ruleName: ruleName,
                ruleTreeId: ruleTreeId,
                ruleType: ruleType,
                ruleValidationType: ruleValidationType,
                description: description,
                ruleArgumentsMap: ruleArgumentMap ? ruleArgumentMap : {},
                mapOfRuleArgumentValues: mapOfRuleArgumentValues
            });
        };

        const glCodingData = {
            "operation": constants.GET_CONFIGURED_SIMPLE_RULES,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "ruleValidationType": constants.GL_CODING
            }
        }
        const onGlCodingSuccess = (res: AxiosResponse) => {
            setCompanyCode(emptyRuleObject);
            setLocationCode(emptyRuleObject);
            setCostCenter(emptyRuleObject);
            setAccount(emptyRuleObject);
            setProductLine(emptyRuleObject);
            setSalesChannel(emptyRuleObject);
            setProjectCode(emptyRuleObject);

            // GLCoding related mappings.
            const gLCodingRuleTreeId: number = res.data["GLCoding"]["ruleTreeId"];
            const gLCodingRuleConfigurationMap = res.data["GLCoding"]["ruleConfigurationMap"];
            setGLCodingRuleTreeId(res.data["GLCoding"]["ruleTreeId"]);
            setGLCodingRuleConfigurationMap(gLCodingRuleConfigurationMap);

            // The following rules names are defined here - https://tiny.amazon.com/1fsmr67ra/codeamazpackTIPSblob6c7bsrc.
            // The following rule arguments are defined here - https://tiny.amazon.com/1mx1n1cn/codeamazpackTIPSblobd5e6src.
            Object.keys(gLCodingRuleConfigurationMap).forEach(ruleConfigurationKey => {
                const ruleType: string = gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleType"];
                if (isCompanyCode(ruleConfigurationKey)) {
                    setRuleObject(setCompanyCode, ruleConfigurationKey, gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleArgumentMap"],
                        gLCodingRuleConfigurationMap[ruleConfigurationKey]["description"], "GLCoding", gLCodingRuleTreeId, ruleType);
                } else if (isLocation(ruleConfigurationKey)) {
                    setRuleObject(setLocationCode, ruleConfigurationKey, gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleArgumentMap"],
                        gLCodingRuleConfigurationMap[ruleConfigurationKey]["description"], "GLCoding", gLCodingRuleTreeId, ruleType);
                } else if (isCostCenter(ruleConfigurationKey)) {
                    setRuleObject(setCostCenter, ruleConfigurationKey, gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleArgumentMap"],
                        gLCodingRuleConfigurationMap[ruleConfigurationKey]["description"], "GLCoding", gLCodingRuleTreeId, ruleType);
                } else if (isAccount(ruleConfigurationKey)) {
                    setRuleObject(setAccount, ruleConfigurationKey, gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleArgumentMap"],
                        gLCodingRuleConfigurationMap[ruleConfigurationKey]["description"], "GLCoding", gLCodingRuleTreeId, ruleType);
                } else if (isProductLine(ruleConfigurationKey)) {
                    setRuleObject(setProductLine, ruleConfigurationKey, gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleArgumentMap"],
                        gLCodingRuleConfigurationMap[ruleConfigurationKey]["description"], "GLCoding", gLCodingRuleTreeId, ruleType);
                } else if (isSalesChannel(ruleConfigurationKey)) {
                    setRuleObject(setSalesChannel, ruleConfigurationKey, gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleArgumentMap"],
                        gLCodingRuleConfigurationMap[ruleConfigurationKey]["description"], "GLCoding", gLCodingRuleTreeId, ruleType);
                } else if (isProject(ruleConfigurationKey)) {
                    setRuleObject(setProjectCode, ruleConfigurationKey, gLCodingRuleConfigurationMap[ruleConfigurationKey]["ruleArgumentMap"],
                        gLCodingRuleConfigurationMap[ruleConfigurationKey]["description"], "GLCoding", gLCodingRuleTreeId, ruleType);
                }
            });

            setIsGlCodingLoading(false);
        }
        const onGlCodingError = (err: AxiosError) => {
            setGLErrorText(getErrorMessage(err));
            setIsGlCodingLoading(false);
        }
        placeGetRequest(glCodingData, onGlCodingSuccess, onGlCodingError);

        const glDescriptionData = {
            "operation": constants.GET_CONFIGURED_SIMPLE_RULES,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "ruleValidationType": constants.GL_DESCRIPTION
            }
        }
        const onGlDescriptionSuccess = (res: AxiosResponse) => {
            setDescription(emptyRuleObject);

            // GLDescription related mappings.
            const gLDescriptionRuleTreeId: number = res.data["GLDescription"]["ruleTreeId"];
            // Here we can get either "FixedGLCodingDescription" or "GlCodingDescription".
            const gLDescriptionRuleConfigurationMap = res.data["GLDescription"]["ruleConfigurationMap"];

            if (gLDescriptionRuleConfigurationMap.hasOwnProperty("FixedGLCodingDescription")) {
                setRuleObject(setDescription, "FixedGLCodingDescription", gLDescriptionRuleConfigurationMap["FixedGLCodingDescription"]["ruleArgumentMap"],
                    gLDescriptionRuleConfigurationMap["FixedGLCodingDescription"]["description"], "GLDescription", gLDescriptionRuleTreeId,
                    gLDescriptionRuleConfigurationMap["FixedGLCodingDescription"]["ruleType"]);
            } else if (gLDescriptionRuleConfigurationMap.hasOwnProperty("GlCodingDescription")) {
                setRuleObject(setDescription, "GlCodingDescription", gLDescriptionRuleConfigurationMap["GlCodingDescription"]["ruleArgumentMap"],
                    gLDescriptionRuleConfigurationMap["GlCodingDescription"]["description"], "GLDescription", gLDescriptionRuleTreeId,
                    gLDescriptionRuleConfigurationMap["GlCodingDescription"]["ruleType"]);
            }

            setIsGlDescriptionLoading(false);
        }
        const onGlDescriptionError = (err: AxiosError) => {
            setGLErrorText(getErrorMessage(err));
            setIsGlDescriptionLoading(false);
        }
        placeGetRequest(glDescriptionData, onGlDescriptionSuccess, onGlDescriptionError);
    }

    /*
        Here, all the API Calls are made to get the data when the Show Configurations component is loaded.
        SCAC and Account Type are the dependencies, whenever these change then this useEffect Hook is called and
        the API calls will be made again
        TODO: Replace the logic for data retrieval in the success blocks of API Calls. Currently the 0th index of
        res.data is being queried, but that might change depending on how CarrierConfigurationService API might
        give the data.
     */
    useEffect(() => {
        setLaunchDataLoading(true);
        setPaymentDataLoading(true);
        setChargeCodeLoading(true);
        setIsEmailIdDataLoading(true);

        const launchData = {
            "operation": constants.GET_LAUNCHED_CARRIERS,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType
            }
        }
        const onLaunchSuccess = (res: AxiosResponse) => {
            setLaunchDataLoading(false);
            if(res.data["launchedCarriersOutput"].hasOwnProperty("launchedCarrierList")) {
                const data = res.data["launchedCarriersOutput"]["launchedCarrierList"];
                if (data.length > 0) {
                    const firstLaunchedCarrier = data[0];
                    setLaunchData({
                        carrierName: firstLaunchedCarrier.carrierName,
                        clientName: firstLaunchedCarrier.clientName,
                        complianceCheckEnabled: firstLaunchedCarrier.complianceCheckEnabled,
                        enabled: firstLaunchedCarrier.enabled,
                        launchDate: firstLaunchedCarrier.launchDate,
                        launchedBy: firstLaunchedCarrier.launchedBy,
                        paymentEnabled: firstLaunchedCarrier.paymentEnabled,
                        scac: firstLaunchedCarrier.scac,
                        shipmentAccountType: firstLaunchedCarrier.shipmentAccountType,
                        id: firstLaunchedCarrier["id"]
                    });
                }
            }
        }
        const onLaunchError = (err: AxiosError) => {
            setLaunchDataLoading(false);
            setLaunchData(DEFAULT_CARRIER_OBJECT);
            setLaunchErrorText(getErrorMessage(err));
        }
        placeGetRequest(launchData, onLaunchSuccess, onLaunchError);

        const paymentData = {
            "operation": constants.GET_PAYMENT_CONFIGURATION,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType
            }
        }
        const onPaymentSuccess = (res: AxiosResponse) => {
            setPaymentErrorText(constants.EMPTY_STRING);
            setPaymentDataLoading(false);

            if (res.data["paymentConfigurationOutput"].hasOwnProperty("paymentConfig")) {
                const data = res.data["paymentConfigurationOutput"]["paymentConfig"];
                setPaymentData({
                    id: getNonNullString(data["id"]),
                    currency: getNonNullString(data.paymentCurrencyCode),
                    ofaHeader: getNonNullString(data.ofaHeaderDesc),
                    ofaOrg: getNonNullString(data.region),
                    payGroup: getNonNullString(data.payGroup),
                    payee: getNonNullString(data.payeeCode),
                    site: getNonNullString(data.vendorSiteCode),
                    ofaVendor: getNonNullString(data.vendorNumber),
                    spsVersionId: getNonNullString(data.spsVersionId)
                });
            } else {
                setPaymentData({
                    id: getNonNullString(constants.EMPTY_STRING),
                    currency: getNonNullString(constants.EMPTY_STRING),
                    ofaHeader: getNonNullString(constants.EMPTY_STRING),
                    ofaOrg: getNonNullString(constants.EMPTY_STRING),
                    payGroup: getNonNullString(constants.EMPTY_STRING),
                    payee: getNonNullString(constants.EMPTY_STRING),
                    site: getNonNullString(constants.EMPTY_STRING),
                    ofaVendor: getNonNullString(constants.EMPTY_STRING),
                    spsVersionId: getNonNullString(constants.EMPTY_STRING)
                });
            }
        }
        const onPaymentError = (err: AxiosError) => {
            setPaymentData(DEFAULT_PAYMENT_OBJECT);
            setPaymentErrorText(getErrorMessage(err));
            setPaymentDataLoading(false);
        }
        placeGetRequest(paymentData, onPaymentSuccess, onPaymentError);

        const chargeCodeData = {
            "operation": constants.GET_CHARGE_CODE_MAPPING,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType
            }
        }
        const onChargeCodeSuccess = (res: AxiosResponse) => {

            setChargeCodeLoading(false);

            setChargeCodeMapList(res.data["chargeCodeMappingOutput"]["chargeCodeMapList"]);
            setChargeCodeConfigurationList(res.data["chargeCodeMappingOutput"]["chargeCodeConfigurationList"]);
            setAllChargeTypes(res.data["ChargeType"]["values"]);
        }
        const onChargeCodeError = (err: AxiosError) => {
            setChargeCodeErrorText(getErrorMessage(err));
            setChargeCodeLoading(false);
        }
        placeGetRequest(chargeCodeData, onChargeCodeSuccess, onChargeCodeError);

        getEmailIdData();
        getAccountConfiguration();
        getGlConfiguration();
    }, [props.scac, props.accountType]);

    const tabs = [
        {
            label: constants.LAUNCH,
            id: constants.LAUNCH,
            content: <LaunchConfiguration launchErrorText={launchErrorText} launchDataLoading={launchDataLoading} launchData={launchData} updateLaunchConfiguration={updateLaunchConfiguration}/>,
        },
        {
            label: constants.ACCOUNT,
            id: constants.ACCOUNT,
            content: <AccountConfiguration accountErrorText={accountErrorText} scac={props.scac} accountType={props.accountType} businessTypes={businessTypes} contractTypes={contractTypes} countryCodes={countryCodes} inputAccountConfigurationMap={inputAccountConfigurationMap} shipmentAccountList={shipmentAccountList} nextDisabled={nextDisabled} maxPageToken={maxPageToken} pageToken={pageToken} previousDisabled={previousDisabled} tableLoading={tableLoading} changeToken={changeToken} getAccountConfiguration={getAccountConfiguration}/>,
        },
        {
            label: constants.PAYMENT,
            id: constants.PAYMENT,
            content: <PaymentConfiguration paymentErrorText={paymentErrorText} scac={props.scac} accountType={props.accountType} paymentDataLoading={paymentDataLoading} paymentData={paymentData} updatePaymentConfiguration={updatePaymentConfiguration}/>,
        },
        {
            label: constants.CHARGE_CODE,
            id: constants.CHARGE_CODE,
            content: <ChargeCodeConfiguration chargeCodeErrorText={chargeCodeErrorText} scac={props.scac} accountType={props.accountType} allChargeTypes={allChargeTypes} chargeCodeLoading={chargeCodeLoading} chargeCodeConfigurationList={chargeCodeConfigurationList} chargeCodeMapList={chargeCodeMapList} updateChargeCodeTable={updateChargeCodeTable}/>,
        },
        {
            label: constants.GL,
            id: constants.GL,
            content: <GLConfiguration gLErrorText={gLErrorText} scac={props.scac} accountType={props.accountType} companyCode={companyCode!} locationCode={locationCode!} costCenter={costCenter!} account={account!} productLine={productLine!} salesChannel={salesChannel!} projectCode={projectCode!} description={description!} isGlConfigTableLoading={isGlConfigTableLoading} getGlConfiguration={getGlConfiguration}/>,
        }
    ];

    //[TODO] Add this emailComponent to the `tabs` and remove the if condition once the TFSCommunicationService is onboarded in CN Region
    const emailComponent = {
        label: constants.EMAIL,
        id: constants.EMAIL,
        content: <EmailIdListConfiguration {...{ emailIDFetchError, emailIdData, isEmailIdDataLoading, getEmailIdData }} />,
    }
    if(!CHECK_CN_REGION) {
        tabs.push(emailComponent)
    }

    return (
        <GlCodingContext.Provider value={{ gLCodingRuleConfigurationMap, gLCodingRulePossibleValues, gLCodingRuleTreeId }}>
            <Tabs
                activeTabId={activeTabId}
                tabs={tabs}
                onChange={event => {
                    setActiveTabId(event.detail.activeTabId);
                }}
            />
        </GlCodingContext.Provider>
    );
}
export default ConfigurationDisplayComponent;
