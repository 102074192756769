import React from 'react';
import {LSMConfigUploadReportProps} from "../../../interfaces";
import {Box, Header, Table} from "@amzn/awsui-components-react-v3";
import constants from "../../../constants/strings";

function LSMConfigUploadReport(props: LSMConfigUploadReportProps) {

    return (
        <Table
            columnDefinitions={[
                {
                    id: constants.SCAC,
                    header: constants.SCAC,
                    cell: item => item.lsmConfigObject.scac || constants.EMPTY_STRING
                },
                {
                    id: constants.ACCOUNT_NUMBER,
                    header: constants.ACCOUNT_NUMBER,
                    cell: item => item.lsmConfigObject.accountNumber || constants.EMPTY_STRING
                },
                {
                    id: constants.CHARGE_GROUP,
                    header: constants.CHARGE_GROUP,
                    cell: item => item.lsmConfigObject.chargeGroup || constants.EMPTY_STRING
                },
                {
                    id: constants.CLIENT_ID,
                    header: constants.CLIENT_ID,
                    cell: item => item.lsmConfigObject.clientId || constants.EMPTY_STRING
                },
                {
                    id: constants.CLIENT_PROGRAM,
                    header: constants.CLIENT_PROGRAM,
                    cell: item => item.lsmConfigObject.clientProgram || constants.EMPTY_STRING
                }, {
                    id: constants.COST_CENTER_TYPE,
                    header: constants.COST_CENTER_TYPE,
                    cell: item => item.lsmConfigObject.costCenterType || constants.EMPTY_STRING
                },
                {
                    id: constants.BUSINESS_UNIT_ID,
                    header: constants.BUSINESS_UNIT_ID,
                    cell: item => item.lsmConfigObject.businessUnitId || constants.EMPTY_STRING
                },
                {
                    id: constants.CONTRACTUAL_LOCATION_NODE_ID,
                    header: constants.CONTRACTUAL_LOCATION_NODE_ID,
                    cell: item => item.lsmConfigObject.contractualLocationNodeId || constants.EMPTY_STRING
                },
                {
                    id: constants.CONTRACTUAL_LOCATION_NAMESPACE,
                    header: constants.CONTRACTUAL_LOCATION_NAMESPACE,
                    cell: item => item.lsmConfigObject.contractualLocationNamespace || constants.EMPTY_STRING
                },
                {
                    id: constants.PLANNER,
                    header: constants.PLANNER,
                    cell: item => item.lsmConfigObject.planner || constants.EMPTY_STRING
                },
                {
                    id: constants.LSM_ERROR_TEXT,
                    header: constants.LSM_ERROR_TEXT,
                    cell: item => item.lsmErrorText || constants.EMPTY_STRING
                }
            ]}
            items={props.lsmConfigUploadReportObjects}

            empty={
                <Box textAlign="center" color="inherit">
                    <Box
                        padding={{bottom: "s"}}
                        variant="p"
                        color="inherit"
                    >
                        {constants.NO_RESOURCES_TO_DISPLAY}
                    </Box>
                </Box>
            }
            header={<Header> Failure Report </Header>}
        />
    )
}
export default LSMConfigUploadReport;