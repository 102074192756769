import { chargeCodeConfigurationObject, chargeCodeMapObject, chargeCodeTableObject } from "../interfaces";

export function getChargeCodeListFromMapping(chargeCodeConfigurationList: chargeCodeConfigurationObject[], chargeCodeMapList: chargeCodeMapObject[]) {
    let chargeCodeFromMap: Map<string, Map<string, string[]>> = new Map<string, Map<string, string[]>>();
    let mapInvoiceToManifestCode: Map<string, string> = new Map<string, string>();
    chargeCodeMapList.forEach(chargeCodeMap => {
        chargeCodeMap.invoiceChargeCodeList.forEach(invoiceChargeCode => {
            mapInvoiceToManifestCode.set(invoiceChargeCode, chargeCodeMap.manifestChargeCodeList[0]);
        });
    });

    chargeCodeConfigurationList.forEach(chargeCodeConfiguration => {
        let chargeType: string = chargeCodeConfiguration.chargeType;
        let invoiceCode: string = chargeCodeConfiguration.invoiceChargeCode;
        let manifestCode: string = mapInvoiceToManifestCode.get(invoiceCode) || '';
        if(chargeCodeFromMap.has(chargeType)) {
            let manifestToInvoiceList: Map<string, string[]> = chargeCodeFromMap.get(chargeType)!;
            manifestToInvoiceList.set(manifestCode, manifestToInvoiceList.has(manifestCode) ?
                [...manifestToInvoiceList.get(manifestCode)!, invoiceCode]:[invoiceCode]);
        } else {
            let newManifestToInvoiceCodeMap: Map<string, string[]> = new Map<string, string[]>();
            newManifestToInvoiceCodeMap.set(manifestCode, [invoiceCode]);
            chargeCodeFromMap.set(chargeType, newManifestToInvoiceCodeMap);
        }
    });

    let tableData: chargeCodeTableObject[] = [];
    chargeCodeFromMap.forEach((manifestInvoiceMap: Map<string, string[]>, chargeType: string) => {
        manifestInvoiceMap.forEach((invoiceChargeCodes: string[], manifestChargeCode: string) => {
            tableData.push({
                chargeType: chargeType,
                manifestChargeCode: manifestChargeCode,
                invoiceChargeCodes: invoiceChargeCodes
            });
        })
    });
    return tableData;
}
