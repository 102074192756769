import * as React from "react"
import {InvoiceMatchPropertyAddKeyProps, InvoiceMatchPropertyConfigs} from "../../../interfaces";
import {Button, Container, FlashbarProps, Form, Header, Input, SpaceBetween} from "@amzn/awsui-components-react-v3";
import constants from "../../../constants/strings";
import {DEFAULT_EMPTY_FLASH_MESSAGE} from "../../../constants/objects";
import MatrixFlashMessage from "../CommonComponents/MatrixFlashMessage";

function InvoiceMatchPropertyAddKey(props: InvoiceMatchPropertyAddKeyProps) {
    const [invoiceKey, setInvoiceKey] = React.useState<string>(constants.EMPTY_STRING)
    const [manifestKey, setmanifestKey] = React.useState<string>(constants.EMPTY_STRING)
    const [message, setMessage] = React.useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);

    return (
        <Container>
            <SpaceBetween size={"l"}>
                <Form
                    actions={
                        <SpaceBetween size={"xs"} direction={"horizontal"}>
                            <Button variant={"primary"}
                                    onClick={() => {
                                        setMessage(DEFAULT_EMPTY_FLASH_MESSAGE);
                                        const IMPSConfig: InvoiceMatchPropertyConfigs = {
                                            invoiceKey: invoiceKey || props.addorDeleteInvoiceMatchPropertyConfig.invoiceKey,
                                            manifestKey: manifestKey || props.addorDeleteInvoiceMatchPropertyConfig.manifestKey
                                        }
                                        props.setAddorDeleteInvoiceMatchPropertyConfig(IMPSConfig)
                                        props.saveInvoiceMatchPropertyConfig(IMPSConfig, false)
                                    }}
                            >
                                Add
                            </Button>
                            <Button
                                variant={"primary"}
                                onClick={() => {
                                    props.setShowAddInvoiceMatchPropertyConfig(false);
                                }}
                            > Close </Button>
                        </SpaceBetween>
                    }
                    header={
                        <Header variant={"h1"}>
                            {constants.ADD_IMPS_CONFIG}
                        </Header>
                    }
                >

                    <SpaceBetween size={"l"} direction={"vertical"}>
                        <div>
                            <h3>Invoice Key</h3>
                            <Input onChange={({detail}) => setInvoiceKey(detail.value)}
                                   value={invoiceKey}
                                   placeholder={constants.INVOICE_KEY_ENTRY}/>
                        </div>
                        <div>
                            <h3>Manifest Key</h3>
                            <Input onChange={({detail}) => setmanifestKey(detail.value)}
                                   value={manifestKey}
                                   placeholder={constants.MANIFEST_KEY_ENTRY}
                            />
                        </div>
                    </SpaceBetween>
                </Form>
            </SpaceBetween>
            <MatrixFlashMessage content={[message]}/>
        </Container>
    )
}

export default InvoiceMatchPropertyAddKey;