import { useState, useContext, useEffect } from 'react';
import { GlCodingContext } from "../../../contexts/contexts";
import AccountConfigTable from "./AccountConfigTable";
import {
    Container,
    SpaceBetween,
    Grid,
    Header,
    Button,
    Input,
    FormField,
    Form, 
    StatusIndicator, 
    Select, 
    FlashbarProps,
    Toggle
} from "@amzn/awsui-components-react-v3/polaris";
import {
    AccountConfigurationProps,
    AccountTableObject, ChargeGroupConfigProps,
    ShipmentAccountObject, GlCodeDropdownProps,
    GLCodeErrorObject, GLCodeObject
} from "../../../interfaces";
import constants from "../../../constants/strings";
import { AxiosError, AxiosResponse } from "axios";
import { placeGetRequest, placePostRequest } from "../../../utils";
import {isDropdownFieldValid, isFieldValid} from "../../../utils/errorChecks";
import AccountConfigPopup from "./AccountConfigPopup";
import ChargeGroupConfigsAdd from "./ChargeGroupConfigsAdd";
import {
    DEFAULT_ACCOUNT_TABLE_OBJECT,
    DEFAULT_BUSINESS_TYPE_OPTION,
    DEFAULT_CONTRACT_TYPE_OPTION,
    DEFAULT_COUNTRY_CODE_OPTION,
    DEFAULT_GL_CODE_OPTION,
    DEFAULT_GL_CODE_OBJECT,
    DEFAULT_GL_ERROR_OBJECT,
    DEFAULT_EMPTY_FLASH_MESSAGE,
    DEFAULT_INPUT_ACCOUNT_TABLE_OBJECT,
    UPDATE_SUCCESS_FLASH_MESSAGE
} from "../../../constants/objects";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";
import MatrixFlashMessage, {MatrixFlashMessageProps} from "../CommonComponents/MatrixFlashMessage";
import MatrixSaveModal from "../CommonComponents/MatrixSaveModal";
import {Badge, SelectProps} from "@amzn/awsui-components-react-v3";
import InvoiceMatchPropertyConfigPopup from "./InvoiceMatchPropertyConfigPopup";


function AccountConfiguration(props: AccountConfigurationProps) {
    const [accountNumber, setAccountNumber] = useState<string>(constants.EMPTY_STRING);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [hideAccountTable, setHideAccountTable] = useState<boolean>(false);
    const [accountNumbers, setAccountNumbers] = useState<string>(constants.EMPTY_STRING);
    const [businessType, setBusinessType] = useState<SelectProps.Option>(DEFAULT_BUSINESS_TYPE_OPTION);
    const [contractType, setContractType] = useState<SelectProps.Option>(DEFAULT_CONTRACT_TYPE_OPTION);
    const [countryCode, setCountryCode] = useState<SelectProps.Option>(DEFAULT_COUNTRY_CODE_OPTION);
    const [makeSearchCall, setMakeSearchCall] = useState<boolean>(false);
    const [searchAccountNumber, setSearchAccountNumber] = useState<boolean>(false);
    const [searchedTableData, setSearchedTableData] = useState<AccountTableObject[]>([]);
    const [accountNumbersError, setAccountNumbersError] = useState<string>(constants.EMPTY_STRING);
    const [businessTypeError, setBusinessTypeError] = useState<string>(constants.EMPTY_STRING);
    const [contractTypeError, setContractTypeError] = useState<string>(constants.EMPTY_STRING);
    const [countryCodeError, setCountryCodeError] = useState<string>(constants.EMPTY_STRING);
    const [showAccountDetails, setShowAccountDetails] = useState<boolean>(false);
    const [showIMPSDetails, setShowIMPSDetails] = useState<boolean>(false);
    const [popupAccountObject, setPopupAccountObject] = useState<AccountTableObject>(DEFAULT_ACCOUNT_TABLE_OBJECT);
    const [chargeGroupConfigs, setChargeGroupConfigs] = useState<ChargeGroupConfigProps[]>([]);
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);
    const [secondaryMessages, setSecondaryMessages] = useState<MatrixFlashMessageProps>({});
    const [toggleStatusMessage, setToggleStatusMessage] = useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);
    const { gLCodingRuleConfigurationMap, gLCodingRulePossibleValues = {}, gLCodingRuleTreeId } = useContext(GlCodingContext);
    const [glCodeDropdownData, setGlCodeDropdownData] = useState<GlCodeDropdownProps[]>([]);
    const [glSelectedData, setGlSelectedData] = useState<GLCodeObject>(DEFAULT_GL_CODE_OBJECT);
    const [glSelectionError, setGlSelectionError] = useState<GLCodeErrorObject>(DEFAULT_GL_ERROR_OBJECT);
    const [enableAccountNumber, setEnableAccountNumber] = useState<boolean>(false);
    
    useEffect(() => {
        const data: GlCodeDropdownProps[] = [];
        const gLCodingRuleKeys = Object.keys(gLCodingRulePossibleValues);
        if (gLCodingRuleKeys.length) {
            gLCodingRuleKeys.forEach(ruleConfigurationKey => {
                if (ruleConfigurationKey.startsWith("account")) {
                    data.push({
                        rule: ruleConfigurationKey?.split("Map")?.[0],
                        values: gLCodingRulePossibleValues[ruleConfigurationKey]
                    });
                }
            });
            setGlCodeDropdownData(data);
        }
    }, [gLCodingRulePossibleValues]);

    const getTableRowData = (data: ShipmentAccountObject) => {
        const inputAccountTableObject: AccountTableObject = props.inputAccountConfigurationMap.get(data.accountNumber) || DEFAULT_INPUT_ACCOUNT_TABLE_OBJECT;
        const tableRowData: AccountTableObject = {
            accountNumber: data.accountNumber,
            status: inputAccountTableObject.status,
            businessType: inputAccountTableObject.businessType,
            contractType: inputAccountTableObject.contractType,
            countryCode: inputAccountTableObject.countryCode,
            chargeGroupConfigMap: inputAccountTableObject.chargeGroupConfigMap,
            InvoiceMatchPropertyList: inputAccountTableObject.InvoiceMatchPropertyList,
            version: inputAccountTableObject.version
        };
        return tableRowData;
    }

    function getDataForSearchedAccount() {
        let _searchedTableData: AccountTableObject[] = [];
        const data = {
            "operation": constants.GET_ACCOUNT_CONFIGURATION,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "accountNumber": accountNumber
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            const searchedData: ShipmentAccountObject[] = res.data["accountConfigurationOutput"]["accountConfigurations"]["shipmentAccountList"] || [];
            searchedData.forEach(data => {
                _searchedTableData.push(getTableRowData(data))
            });
            setSearchedTableData(_searchedTableData);
            setLoading(false);
        }
        const onError = (err: AxiosError) => {
            setLoading(false);
        }
        if (makeSearchCall) {
            placeGetRequest(data, onSuccess, onError);
            setMakeSearchCall(false);
        }
        return searchedTableData;
    }

    function getTableData() {
        const startIndex: number = props.pageToken * constants.DEFAULT_PAGE_SIZE;
        const endIndex: number = Math.min(startIndex + constants.DEFAULT_PAGE_SIZE, props.shipmentAccountList.length);
        let tableData: AccountTableObject[] = [];
        props.shipmentAccountList.slice(startIndex, endIndex).forEach(data => {
            tableData.push(getTableRowData(data));
        });
        return tableData;
    }

    function filterAccountNumber() {
        setLoading(true);
        setSearchAccountNumber(true);
        setMakeSearchCall(true);
    }

    function setDefaultValues() {
        setAccountNumbers(constants.EMPTY_STRING);
        setBusinessType(DEFAULT_BUSINESS_TYPE_OPTION);
        setContractType(DEFAULT_CONTRACT_TYPE_OPTION);
        setCountryCode(DEFAULT_COUNTRY_CODE_OPTION);
        setMessage(DEFAULT_EMPTY_FLASH_MESSAGE);
        setGlSelectedData(DEFAULT_GL_CODE_OBJECT);
        setGlSelectionError(DEFAULT_GL_ERROR_OBJECT);
        setSecondaryMessages({});
    }

    function setGlSelectionErrorWrapper(key: string, value = constants.EMPTY_STRING) {
        setGlSelectionError((lastVal) => { return { ...lastVal, [key]: value } });
    }

    function saveAddedAccounts() {
        // check for GL dropdown errors
        let glErrorExists = false;
        glCodeDropdownData?.forEach((glCode) => {
            if (!isDropdownFieldValid(glSelectedData[glCode.rule], DEFAULT_GL_CODE_OPTION, constants.REQUIRED_GENERIC_ERROR_TEXT, (msg) => { setGlSelectionErrorWrapper(glCode.rule, msg) }))
                glErrorExists = true;
        });
        if (!isFieldValid(accountNumbers, constants.ACCOUNT_NUMBERS_ERROR_TEXT, setAccountNumbersError)
            || !isDropdownFieldValid(businessType, DEFAULT_BUSINESS_TYPE_OPTION, constants.BUSINESS_TYPE_ERROR_TEXT, setBusinessTypeError)
            || !isDropdownFieldValid(contractType, DEFAULT_CONTRACT_TYPE_OPTION, constants.CONTRACT_TYPE_ERROR_TEXT, setContractTypeError)
            || !isDropdownFieldValid(countryCode, DEFAULT_COUNTRY_CODE_OPTION, constants.COUNTRY_CODE_ERROR_TEXT, setCountryCodeError)
            || glErrorExists) {
            return;
        }

        if (!businessType.value || !contractType.value || !countryCode.value) {
            return;
        }

        setIsSaveLoading(true);

        const inputAccountConfigurationMaps = props.inputAccountConfigurationMap;
        const accountNumbersList = accountNumbers.split(",").map((accountNumber) => accountNumber.trim())
            .filter((accountNumber) => (accountNumber != constants.EMPTY_STRING));
        let inputVersionMap: Map<string, string> = new Map<string, string>();

        /*
            To update the existing Business Type, Contract Type and country Code, we need existing version of account number to call the API.
            So if the invoice account number already exists, then add the version value to map(inputVersionMap) and pass it to backend lambda.
        */
        accountNumbersList.forEach((accountNumber) => {
            if(inputAccountConfigurationMaps.has(accountNumber)) {
                const inputAccountTableObject: AccountTableObject = inputAccountConfigurationMaps.get(accountNumber)!;
                inputVersionMap.set(accountNumber, inputAccountTableObject.version);
            }
        });

        const saveGLDetails = () => {
            Object.keys(gLCodingRuleConfigurationMap).forEach(ruleConfigurationKey => {
                if (ruleConfigurationKey.startsWith("account")) {
                    const ruleArgumentsMap = JSON.parse(JSON.stringify(gLCodingRuleConfigurationMap[ruleConfigurationKey].ruleArgumentMap));
                    const accountNumberValueMap: any = {};
                    const ruleConfigurationKeyName = `${ruleConfigurationKey}Map`;
                    accountNumbersList.forEach((accountNo: string) => accountNumberValueMap[accountNo] = glSelectedData[ruleConfigurationKey]?.value);
                    ruleArgumentsMap[ruleConfigurationKeyName].value = { ...ruleArgumentsMap[ruleConfigurationKeyName]?.value, ...accountNumberValueMap };

                    const glData = {
                        "operation": constants.UPDATE_SIMPLE_RULE_ARGUMENTS,
                        "request_body": {
                            "ruleName": ruleConfigurationKey,
                            "ruleTreeId": gLCodingRuleTreeId,
                            "ruleValidationType": "GLCoding",
                            "scac": props.scac,
                            "shipmentAccountType": props.accountType,
                            ruleArgumentsMap
                        }
                    }
                    placePostRequest(glData, () => {
                        setSecondaryMessages((lastVal) => {
                            return { ...lastVal, [ruleConfigurationKey]: { type: "success", content: `${ruleConfigurationKey} successfully updated!` } }
                        })
                    },
                        () => {
                            setSecondaryMessages((lastVal) => {
                                return { ...lastVal, [ruleConfigurationKey]: { type: "error", content: `${ruleConfigurationKey} update failed!` } }
                            })
                        });
                }
            })
        }
        const data = {
            "operation": constants.SAVE_ACCOUNT_CONFIGURATION,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "accountNumbers": accountNumbersList,
                "businessType": businessType.value,
                "contractType": contractType.value,
                "countryCode": countryCode.value,
                "chargeGroupConfigs": chargeGroupConfigs,
                "invoiceMatchPropertyConfigs": [],
                "inputVersionMap": Object.fromEntries(inputVersionMap),
                "isEnabled": enableAccountNumber
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setSecondaryMessages({});
            saveGLDetails();
            setIsSaveLoading(false);
            setMessage(UPDATE_SUCCESS_FLASH_MESSAGE);
            props.getAccountConfiguration();
        }
        const onError = (err: AxiosError) => {
            setSecondaryMessages({});
            setEnableAccountNumber(false);
            setIsSaveLoading(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    function disableSearchAccountNumber() {
        setSearchAccountNumber(false);
    }

    const handelGlCodeSelection = (rule: string, option?: string) => {
        setGlSelectedData((lastValue) => {
            return { ...lastValue, [rule]: { value: option } };
        })
    } 

    return(
        <SpaceBetween direction="vertical" size="l">
            <Container header={
                <Header variant="h2">
                    Account Configuration
                </Header>
            }>
                <div>
                    {
                        props.tableLoading ?
                            <StatusIndicator type="loading">{constants.LOADING}</StatusIndicator>
                            :
                            <div>
                                {
                                    hideAccountTable ?
                                        <SpaceBetween size={"l"}>
                                            <MatrixFlashMessage content={[message]} />
                                            <MatrixFlashMessage content={Object.values(secondaryMessages)} />
                                            <Form
                                                actions={
                                                    <SpaceBetween direction="horizontal" size="xs">
                                                        <Button variant="link" onClick={() => {setHideAccountTable(false); setDefaultValues()}}>{constants.CANCEL}</Button>
                                                        <MatrixSaveModal buttonName={constants.SAVE} buttonLoading={isSaveLoading} onClickConfirm={saveAddedAccounts}/>
                                                    </SpaceBetween>
                                                }
                                                header={
                                                    <Header
                                                        variant="h3"
                                                    >
                                                        {constants.ADD_ACCOUNTS}
                                                    </Header>
                                                }
                                            >
                                                <SpaceBetween direction="vertical" size="l">
                                                    <FormField
                                                        constraintText="Separate multiple Account numbers with comma"
                                                        errorText={accountNumbersError}
                                                        label={constants.ACCOUNT_NUMBERS}>
                                                        <Input value={accountNumbers} onChange={({ detail }) => {
                                                            setAccountNumbers(detail.value);
                                                            if (detail.value.length === 0) {
                                                                setAccountNumbersError("At least one Account Number is Required");
                                                            } else {
                                                                setAccountNumbersError(constants.EMPTY_STRING);
                                                            }
                                                        }}/>
                                                    </FormField>
                                                    <div>
                                                        {constants.ACCOUNT_TYPE} : <strong> {props.accountType} </strong>
                                                    </div>

                                                    <SpaceBetween direction="vertical" size="l">
                                                        <FormField
                                                            errorText={businessTypeError}
                                                            label={constants.BUSINESS_TYPE}>
                                                            <Select
                                                                selectedOption={businessType}
                                                                onChange={({ detail }) => {
                                                                    setBusinessType(detail.selectedOption);
                                                                    setBusinessTypeError(constants.EMPTY_STRING);
                                                                }}
                                                                options={
                                                                    props.businessTypes.sort().map(option => {
                                                                        return {
                                                                            value: option
                                                                        }
                                                                    })
                                                                }
                                                                expandToViewport={true}
                                                                filteringType="auto"
                                                                selectedAriaLabel="Selected"
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            errorText={contractTypeError}
                                                            label={constants.CONTRACT_TYPE}>
                                                            <Select
                                                                selectedOption={contractType}
                                                                onChange={({ detail }) => {
                                                                    setContractType(detail.selectedOption);
                                                                    setContractTypeError(constants.EMPTY_STRING);
                                                                }}
                                                                options={
                                                                    props.contractTypes.sort().map(option => {
                                                                        return {
                                                                            value: option
                                                                        }
                                                                    })
                                                                }
                                                                expandToViewport={true}
                                                                filteringType="auto"
                                                                selectedAriaLabel="Selected"
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            errorText={countryCodeError}
                                                            label={constants.COUNTRY_CODE}>
                                                            <Select
                                                                selectedOption={countryCode}
                                                                onChange={({ detail }) => {
                                                                    setCountryCode(detail.selectedOption);
                                                                    setCountryCodeError(constants.EMPTY_STRING);
                                                                }}
                                                                options={
                                                                    props.countryCodes.sort().map(option => {
                                                                        return {
                                                                            value: option
                                                                        }
                                                                    })
                                                                }
                                                                expandToViewport={true}
                                                                filteringType="auto"
                                                                selectedAriaLabel="Selected"
                                                            />
                                                        </FormField>
                                                        <FormField>
                                                            <Toggle
                                                                onChange={({ detail }) => setEnableAccountNumber(detail.checked)}
                                                                checked={enableAccountNumber}>
                                                                {enableAccountNumber ?
                                                                    constants.ENABLE_ACCOUNT_MESSAGE : constants.DISABLE_ACCOUNT_MESSAGE}
                                                            </Toggle>
                                                        </FormField>
                                                        {!!glCodeDropdownData?.length &&
                                                            <SpaceBetween size={"l"}>
                                                                <hr />
                                                                <div>
                                                                    {constants.GL_SEGMENT_HEADING}
                                                                </div>
                                                                {glCodeDropdownData.map((ruleData) =>
                                                                    <FormField
                                                                        key={ruleData.rule}
                                                                        errorText={glSelectionError[ruleData.rule]}
                                                                        label={ruleData.rule}>
                                                                        <Select
                                                                            selectedOption={glSelectedData[ruleData.rule]}
                                                                            onChange={({ detail }) => {
                                                                                handelGlCodeSelection(ruleData.rule, detail.selectedOption.value);
                                                                                setGlSelectionErrorWrapper(ruleData.rule)
                                                                            }}
                                                                            options={
                                                                                ruleData.values?.sort().map((option: string) => {
                                                                                    return {
                                                                                        value: option
                                                                                    }
                                                                                })
                                                                            }
                                                                            expandToViewport={true}
                                                                            filteringType="auto"
                                                                            selectedAriaLabel="Selected"
                                                                        />
                                                                    </FormField>
                                                                )}
                                                            </SpaceBetween>
                                                        }                                                     
                                                    </SpaceBetween>
                                                    <ChargeGroupConfigsAdd chargeGroupConfigs={chargeGroupConfigs} setChargeGroupConfigs={setChargeGroupConfigs}/>
                                                </SpaceBetween>
                                            </Form>
                                        </SpaceBetween>
                                        :
                                        <SpaceBetween size={"xl"} direction={"vertical"}>
                                            <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
                                                <Input
                                                    onChange={({ detail }) => setAccountNumber(detail.value)}
                                                    value={accountNumber}
                                                />
                                                <Button loading={isLoading} variant="normal" onClick={filterAccountNumber}>{constants.SEARCH}</Button>
                                            </Grid>
                                            <Button variant={"normal"} onClick={() => setHideAccountTable(true)}>+ {constants.ADD_ACCOUNTS}</Button>
                                            <div>
                                                <SpaceBetween size={"l"}>
                                                    <MatrixFlashMessage content={[toggleStatusMessage]} />
                                                    {
                                                        searchAccountNumber ?
                                                            <SpaceBetween size={"xl"} direction={"vertical"}>
                                                                <AccountConfigTable
                                                                    tableData={getDataForSearchedAccount()}
                                                                    scac={props.scac}
                                                                    accountType={props.accountType}
                                                                    setPopupAccountObject={setPopupAccountObject}
                                                                    setShowAccountDetails={setShowAccountDetails}
                                                                    setShowIMPSDetails={setShowIMPSDetails}
                                                                    getAccountConfiguration={props.getAccountConfiguration}
                                                                    disableSearchAccountNumber={disableSearchAccountNumber}
                                                                    setToggleStatusMessage={setToggleStatusMessage}
                                                                />
                                                                <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
                                                                    <div></div>
                                                                    <Button onClick={() => disableSearchAccountNumber()}>{constants.GO_BACK}</Button>
                                                                    <div></div>
                                                                </Grid>
                                                            </SpaceBetween> :
                                                            <SpaceBetween size={"xl"} direction={"vertical"}>
                                                                <AccountConfigTable

                                                                    tableData={getTableData()}
                                                                    scac={props.scac}
                                                                    accountType={props.accountType}
                                                                    setPopupAccountObject={setPopupAccountObject}
                                                                    setShowAccountDetails={setShowAccountDetails}
                                                                    setShowIMPSDetails={setShowIMPSDetails}
                                                                    getAccountConfiguration={props.getAccountConfiguration}
                                                                    disableSearchAccountNumber={disableSearchAccountNumber}
                                                                    setToggleStatusMessage={setToggleStatusMessage}
                                                                />
                                                                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
                                                                    <div></div>
                                                                    <Button onClick={() => props.changeToken(-1)} disabled={props.previousDisabled}>{constants.PREVIOUS}</Button>
                                                                    <Button onClick={() => props.changeToken(1)} disabled={props.nextDisabled}>{constants.NEXT}</Button>
                                                                    <div></div>
                                                                </Grid>
                                                            </SpaceBetween>
                                                    }
                                                </SpaceBetween>
                                            </div>
                                        </SpaceBetween>
                                }
                            </div>
                    }
                </div>
            </Container>
            {
                (showAccountDetails || showIMPSDetails) ?
                    <Container
                        header={<>
                            <Header variant="h1">
                                Account: <strong>{popupAccountObject.accountNumber}</strong>
                            </Header>
                            <SpaceBetween direction="horizontal" size="xxl">
                                <Badge color={popupAccountObject.status ? "green" : "red"}>
                                    {constants.STATUS} :<strong> {popupAccountObject.status ? constants.ENABLED : constants.DISABLED} </strong>
                                </Badge>
                                <Badge
                                    color="blue"> {constants.BUSINESS_TYPE} :<strong> {popupAccountObject.businessType} </strong>
                                </Badge>
                                <Badge
                                    color="blue"> {constants.CONTRACT_TYPE} :<strong> {popupAccountObject.contractType} </strong>
                                </Badge>
                                <Badge
                                    color="blue"> {constants.COUNTRY_CODE} :<strong> {popupAccountObject.countryCode} </strong>
                                </Badge>
                            </SpaceBetween>
                        </>}
                    >
                        <SpaceBetween size={"xxl"}>
                        <Container>
                            {
                                showAccountDetails ?
                                <AccountConfigPopup
                                    scac={props.scac}
                                    accountType={props.accountType}
                                    popupAccountObject={popupAccountObject}
                                    setShowAccountDetails={setShowAccountDetails}
                                />
                                : constants.EMPTY_STRING
                            }
                        </Container>
                            {
                                showIMPSDetails ?
                                <InvoiceMatchPropertyConfigPopup
                                    scac={props.scac}
                                    showIMPSDetails={showIMPSDetails}
                                    accountType={props.accountType}
                                    setPopupAccountObject = {setPopupAccountObject}
                                    popupAccountObject={popupAccountObject}
                                    setShowIMPSDetails={setShowIMPSDetails}
                                /> : constants.EMPTY_STRING
                            }
                        </SpaceBetween>
                    </Container>
                    : constants.EMPTY_STRING
            }
        </SpaceBetween>
    );
}

export default AccountConfiguration;
