import * as React from "react";
import "./index.css";
import { Header } from "@amzn/awsui-components-react-v3/polaris";
/*
    This is the home page that the user sees. A fitting description can be added
 */
function LandingPageComponent(){
    return (
        <Header className = "tfs-header" variant="h1">
            Welcome to TFS Matrix
        </Header>
    );
}

export default LandingPageComponent;
