import config from "../config";

/**
 * Assumptions:
 * 1. All our URLS have this structure https://<region>-<stage>.domain, hence this check would work
 * 2. For CN region, the domain naming might be different and the approach would differ, hence
 * it can be included in a different method.
 * @param url url
 */
export function getRegionSpecificConfiguration(url: string) {

    try {
        const urlExtract = url.split(".")[0].split("//")[1]
        switch (urlExtract) {
            // beta stack
            case "na-beta":
                return config.NA_REGION;
            case "eu-beta":
                return config.EU_REGION;
            case "fe-beta":
                return config.FE_REGION;
            case "cn-beta":
                return config.CN_REGION;
            // gamma stack
            case "na-gamma":
                return config.NA_REGION;
            case "eu-gamma":
                return config.EU_REGION;
            case "fe-gamma":
                return config.FE_REGION;
            case "cn-gamma":
                return config.CN_REGION;
            // preprod stack
            case "na-preprod":
                return config.NA_REGION;
            case "eu-preprod":
                return config.EU_REGION;
            case "fe-preprod":
                return config.FE_REGION;
            case "cn-preprod":
                return config.CN_REGION;
            // Uat2 stack
            case "na-uat2":
                return config.NA_UAT2_REGION;
            case "eu-uat2":
                return config.EU_UAT2_REGION;
            // prod stack
            case "na":
                return config.NA_PROD_REGION;
            case "eu":
                return config.EU_PROD_REGION;
            case "fe":
                return config.FE_PROD_REGION;
            case "cn":
                return config.CN_PROD_REGION;
            default:
                // Localhost testing. Replace with required config to use. Must use beta configs.
                // Should use the same stage and region as used for ApiGateway endpoint.
                return config.CN_REGION;
        }
    } catch (error) {
        throw error;
    }

}

export function getDomain(isProd: boolean, stage: string, retailRegion: string, region: string) {
    if (isProd) {
        if(stage === "PROD") {
            return `${retailRegion}-tfs-matrix.auth.${region}.amazoncognito.com`;
        }
        else {
            return `${retailRegion}-uat-tfs-matrix.auth.${region}.amazoncognito.com`;
        }
    }
    return `${retailRegion}-dev-tfs-matrix.auth.${region}.amazoncognito.com`;
}
