import React, { useCallback, useMemo } from 'react';
import { AttributeEditor, Input } from '@amzn/awsui-components-react-v3/polaris';
import {ChargeGroupConfigInputProps, ChargeGroupConfigProps, ChargeGroupConfigsAddProps} from "../../../interfaces";
import { DEFAULT_CHARGE_GROUP_CONFIG } from "../../../constants/objects";
import constants from "../../../constants/strings";

const i18nStrings = {
    addButtonText: '+ Add ChargeGroup(s)',
    removeButtonText: constants.REMOVE,
    empty: 'No charge group associated with account(s)'
};

const Control = React.memo(({ value, index, placeholder, setItems, prop }: ChargeGroupConfigInputProps) => {
    return (
        <>
            <Input
                value={value}
                placeholder={placeholder}
                onChange={({ detail }) => {
                    setItems((items: ChargeGroupConfigProps[]) => {
                        const updatedItems = [...items];
                        updatedItems[index] = {
                            ...updatedItems[index],
                            [prop]: detail.value,
                        };
                        return updatedItems;
                    });
                }}
            />
            {prop=="planner" ? <br/> : constants.EMPTY_STRING}
        </>
    );
});

function ChargeGroupConfigsAdd(props: ChargeGroupConfigsAddProps) {

    const definition = useMemo(
        () => [
            {
                control: ({ chargeGroup = '' }, itemIndex: number) => (
                    <Control
                        prop="chargeGroup"
                        value={chargeGroup}
                        index={itemIndex}
                        placeholder={constants.ENTER_CHARGE_GROUP}
                        setItems={props.setChargeGroupConfigs}
                    />
                ),
            },
            {
                control: ({ clientId = '' }, itemIndex: number) => (
                    <Control
                        prop="clientId"
                        value={clientId}
                        index={itemIndex}
                        placeholder={constants.ENTER_CLIENT_ID}
                        setItems={props.setChargeGroupConfigs} />
                ),
            },
            {
                control: ({ clientProgram = '' }, itemIndex: number) => (
                    <Control
                        prop="clientProgram"
                        value={clientProgram}
                        index={itemIndex}
                        placeholder={constants.ENTER_CLIENT_PROGRAM}
                        setItems={props.setChargeGroupConfigs} />
                ),
            },
            {
                control: ({ costCenterType = '' }, itemIndex: number) => (
                    <Control
                        prop="costCenterType"
                        value={costCenterType}
                        index={itemIndex}
                        placeholder={constants.ENTER_COST_CENTER_TYPE}
                        setItems={props.setChargeGroupConfigs}
                    />
                ),
            },
            {
                control: ({ businessUnitId = '' }, itemIndex: number) => (
                    <Control
                        prop="businessUnitId"
                        value={businessUnitId}
                        index={itemIndex}
                        placeholder={constants.ENTER_BUSINESS_UNIT_ID}
                        setItems={props.setChargeGroupConfigs}
                    />
                ),
            },
            {
                control: ({ contractualLocationNodeId = '' }, itemIndex: number) => (
                    <Control
                        prop="contractualLocationNodeId"
                        value={contractualLocationNodeId}
                        index={itemIndex}
                        placeholder={constants.ENTER_CONTRACTUAL_LOCATION_NODE_ID}
                        setItems={props.setChargeGroupConfigs}
                    />
                ),
            },
            {
                control: ({ contractualLocationNamespace = '' }, itemIndex: number) => (
                    <Control
                        prop="contractualLocationNamespace"
                        value={contractualLocationNamespace}
                        index={itemIndex}
                        placeholder={constants.ENTER_CONTRACTUAL_LOCATION_NAMESPACE}
                        setItems={props.setChargeGroupConfigs}
                    />
                ),
            },
            {
                control: ({ planner = '' }, itemIndex: number) => (
                    <Control
                        prop="planner"
                        value={planner}
                        index={itemIndex}
                        placeholder={constants.ENTER_PLANNER}
                        setItems={props.setChargeGroupConfigs}
                    />
                ),
            }
        ],
        []
    );

    const onAddButtonClick = useCallback(() => {
        props.setChargeGroupConfigs(items => [...items, DEFAULT_CHARGE_GROUP_CONFIG]);}, []);

    const onRemoveButtonClick = useCallback(({ detail: { itemIndex } }) => {
        props.setChargeGroupConfigs(items => {
            const newItems = items.slice();
            newItems.splice(itemIndex, 1);
            return newItems;
        });
    }, []);

    return (
        <AttributeEditor
            {...i18nStrings}
            items={props.chargeGroupConfigs}
            definition={definition}
            onAddButtonClick={onAddButtonClick}
            onRemoveButtonClick={onRemoveButtonClick}
        />
    );
}

export default ChargeGroupConfigsAdd;
