import {API_GATEWAY_ENDPOINT} from "../constants/endpoints";

export const CHECK_CN_REGION = checkCnRegion();

//[TODO] This logic is added to disbale emailId component in CN Region. Remove this logic once we have TFSCommunicationService in CN Region.
function checkCnRegion () : boolean {
    const cnRegionSuffix = ["cn-prod", "cn-preprod", "cn-gamma", "cn-beta" ];
    let isCNRegion = false;
    cnRegionSuffix.forEach((region) => {
        if(API_GATEWAY_ENDPOINT.endsWith(region)) {
            isCNRegion = true;
        }
    })
    return isCNRegion;
}
