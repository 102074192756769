import React, {Dispatch, useEffect, useState} from "react";
import {AxiosError, AxiosResponse} from "axios";
import constants from "../../../constants/strings";
import {placePostRequest} from "../../../utils";
import {Button, FlashbarProps} from "@amzn/awsui-components-react-v3";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";

export interface StatusButtonProps {
    accountNumber: string;
    isAccountStatusEnabled: boolean;
    scac: string;
    accountType: string;
    getAccountConfiguration: () => void;
    disableSearchAccountNumber: () => void;
    setToggleStatusMessage: Dispatch<FlashbarProps.MessageDefinition>;
}

function StatusButton(props: StatusButtonProps) {
    const [isStatusButtonLoading, setIsStatusButtonLoading] = useState<boolean>(false);
    const [isAccountStatusEnabled, setIsAccountStatusEnabled] = useState<boolean>(props.isAccountStatusEnabled);

    /**
     * isAccountStatusEnabled is the dependency, whenever this changes then useEffect Hook is called and
     * will update the status with latest account status.
     */
    useEffect(() => {
        setIsAccountStatusEnabled(props.isAccountStatusEnabled);
    }, [props.isAccountStatusEnabled]);

    function ToggleStatus() {
        setIsStatusButtonLoading(true);
        const data = {
            "operation": isAccountStatusEnabled ? constants.UPDATE_RULE_REMOVE_ACCOUNT_NUMBER : constants.UPDATE_RULE_ADD_ACCOUNT_NUMBER,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "accountNumber": props.accountNumber
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setIsAccountStatusEnabled(!isAccountStatusEnabled);
            props.getAccountConfiguration();
            setIsStatusButtonLoading(false);
            props.disableSearchAccountNumber();
            props.setToggleStatusMessage({type: "success", content: `Values successfully updated for accountNumber : ${props.accountNumber}.`});
        }
        const onError = (err: AxiosError) => {
            setIsStatusButtonLoading(false);
            props.setToggleStatusMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    return (
        <Button
            loading={isStatusButtonLoading}
            onClick={() => ToggleStatus()}
        >
            {isAccountStatusEnabled ? constants.ENABLED : constants.DISABLED}
        </Button>
    );
}

export default StatusButton;
