import React from 'react';
import AppLayout from "@amzn/awsui-components-react-v3/polaris/app-layout";
import SideNavbar from "./SideNavbar";
import { NavbarProps } from "../../interfaces";

/*
    This component will be present always and the 'content' attribute of AppLayout needs to be changed when some other
    use cases is added in future apart from CarrierConfiguration Component
 */
function Navbar(props : NavbarProps) {
    return (
        <div>
            <AppLayout
                navigation = {<SideNavbar />}
                content = {props.content}
                toolsHide={true}
                navigationWidth={200}
            />
        </div>
    );
}

export default Navbar;
