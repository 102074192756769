import React, {useContext, useEffect, useState} from 'react';
import { GlCodingContext } from "../../../contexts/contexts";
import {
    chargeCodeConfigurationMapObject,
    chargeCodeConfigurationObject,
    chargeCodeProps,
    GlCodeDropdownProps,
    GLCodeErrorObject,
    GLCodeObject
} from "../../../interfaces";
import { getChargeCodeListFromMapping, placePostRequest } from "../../../utils";
import {
    Alert,
    Box,
    Button,
    Container,
    FlashbarProps,
    Form,
    FormField,
    Header,
    Input,
    Select,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
    Table
} from "@amzn/awsui-components-react-v3/polaris";
import { AxiosError, AxiosResponse } from "axios";
import constants from "../../../constants/strings";
import {
    isDropdownFieldValid,
    isFieldValid,
    isInvoiceChargeCodeInputValid,
    isManifestChargeCodeInputValid
} from "../../../utils/errorChecks";
import {getErrorFlashMessageProps} from "../../../utils/errorMessage";
import MatrixFlashMessage, { MatrixFlashMessageProps } from "../CommonComponents/MatrixFlashMessage";
import {
    DEFAULT_CHARGE_CODE_OPTION,
    DEFAULT_CHARGE_TYPE_OPTION,
    DEFAULT_EMPTY_FLASH_MESSAGE,
    DEFAULT_GL_CODE_OBJECT,
    DEFAULT_GL_CODE_OPTION,
    DEFAULT_GL_ERROR_OBJECT,
    DELETE_SUCCESS_FLASH_MESSAGE,
    UPDATE_SUCCESS_FLASH_MESSAGE
} from "../../../constants/objects";
import MatrixSaveModal from "../CommonComponents/MatrixSaveModal";
import MatrixDeleteModal from "../CommonComponents/MatrixDeleteModal";

function ChargeCodeConfiguration(props: chargeCodeProps) {
    const [addNewChargeCode, setAddNewChargeCode] = useState<boolean>(false);
    const [deleteChargeCode, setDeleteChargeCode] = useState<boolean>(false);
    const [selectedChargeType, setSelectedChargeType] = useState<SelectProps.Option>(DEFAULT_CHARGE_TYPE_OPTION);
    const [manifestChargeCode, setManifestChargeCode] = useState<string>(constants.EMPTY_STRING);
    const [invoiceChargeCodes, setInvoiceChargeCodes] = useState<string>(constants.EMPTY_STRING);
    const [selectedInvoiceChargeCode, setSelectedInvoiceChargeCode] = useState<SelectProps.Option>(DEFAULT_CHARGE_CODE_OPTION);
    const [commentsToDeleteChargeCode, setCommentsToDeleteChargeCode] = useState<string>(constants.EMPTY_STRING);
    const [manifestChargeCodeError, setManifestChargeCodeError] = useState<string>(constants.EMPTY_STRING);
    const [selectedChargeTypeError, setSelectedChargeTypeError] = useState<string>(constants.EMPTY_STRING);
    const [selectedInvoiceChargeCodeError, setSelectedInvoiceChargeCodeError] = useState<string>(constants.EMPTY_STRING);
    const [invoiceChargeCodesError, setInvoiceChargeCodesError] = useState<string>(constants.EMPTY_STRING);
    const [commentsToDeleteChargeCodeError, setCommentsToDeleteChargeCodeError] = useState<string>(constants.EMPTY_STRING);
    const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
    const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<FlashbarProps.MessageDefinition>(DEFAULT_EMPTY_FLASH_MESSAGE);
    const {
        gLCodingRuleConfigurationMap,
        gLCodingRulePossibleValues = {},
        gLCodingRuleTreeId
    } = useContext(GlCodingContext);
    const [secondaryMessages, setSecondaryMessages] = useState<MatrixFlashMessageProps>({});
    const [glCodeDropdownData, setGlCodeDropdownData] = useState<GlCodeDropdownProps[]>([]);
    const [glSelectedData, setGlSelectedData] = useState<GLCodeObject>(DEFAULT_GL_CODE_OBJECT);
    const [glSelectionError, setGlSelectionError] = useState<GLCodeErrorObject>(DEFAULT_GL_ERROR_OBJECT);

    useEffect(() => {
        const data: GlCodeDropdownProps[] = [];
        const gLCodingRuleKeys = Object.keys(gLCodingRulePossibleValues);
        if (gLCodingRuleKeys.length) {
            gLCodingRuleKeys.forEach(ruleConfigurationKey => {
                if (ruleConfigurationKey.startsWith("chrgCode")) {
                    data.push({
                        rule: ruleConfigurationKey?.split("Map")?.[0],
                        values: gLCodingRulePossibleValues[ruleConfigurationKey]
                    });
                }
            });
            setGlCodeDropdownData(data);
        }
    }, [gLCodingRulePossibleValues]);

    function setDefaultValues() {
        setSelectedChargeType(DEFAULT_CHARGE_TYPE_OPTION);
        setSelectedInvoiceChargeCode(DEFAULT_CHARGE_CODE_OPTION);
        setManifestChargeCode(constants.EMPTY_STRING);
        setInvoiceChargeCodes(constants.EMPTY_STRING);
        setCommentsToDeleteChargeCode(constants.EMPTY_STRING);
        setMessage(DEFAULT_EMPTY_FLASH_MESSAGE);
        setGlSelectedData(DEFAULT_GL_CODE_OBJECT);
        setGlSelectionError(DEFAULT_GL_ERROR_OBJECT);
        setManifestChargeCodeError(constants.EMPTY_STRING);
        setSelectedChargeTypeError(constants.EMPTY_STRING);
        setInvoiceChargeCodesError(constants.EMPTY_STRING);
        setSelectedInvoiceChargeCodeError(constants.EMPTY_STRING);
        setCommentsToDeleteChargeCodeError(constants.EMPTY_STRING);
        setSecondaryMessages({});
    }

    /*
        This toggles the state that corresponds to whether new charge code button has been clicked or not
     */
    function toggleNewChargeCode() {
        setDefaultValues();
        setDeleteChargeCode(false);
        setAddNewChargeCode(!addNewChargeCode);
    }

    /*
        This toggles the state that corresponds to whether delete charge code button has been clicked or not
     */
    function toggleDeleteChargeCode() {
        setDefaultValues();
        setAddNewChargeCode(false);
        setDeleteChargeCode(!deleteChargeCode);
    }

    /*
        This adds new charge codes to the table. No error checks added yet.
     */
    function saveNewChargeCode() {
        // check for GL dropdown errors
        let glErrorExists = false;
        glCodeDropdownData?.forEach((glCode) => {
            if (!isDropdownFieldValid(glSelectedData[glCode.rule], DEFAULT_GL_CODE_OPTION, constants.REQUIRED_GENERIC_ERROR_TEXT, (msg) => { setGlSelectionErrorWrapper(glCode.rule, msg) }))
                glErrorExists = true;
        });

        if (!isManifestChargeCodeInputValid(manifestChargeCode, setManifestChargeCodeError)
            || !isDropdownFieldValid(selectedChargeType, DEFAULT_CHARGE_TYPE_OPTION, constants.SELECTED_CHARGE_TYPE_ERROR_TEXT, setSelectedChargeTypeError)
            || !isInvoiceChargeCodeInputValid(invoiceChargeCodes, setInvoiceChargeCodesError)
            || glErrorExists) {
            return;
        }

        const chargeCodeConfigurationList: chargeCodeConfigurationObject[] = props.chargeCodeConfigurationList.slice();
        const allInvoiceChargeCodes = invoiceChargeCodes.split(",").map((invoiceChargeCode) => invoiceChargeCode.trim());
        const chargeCodeConfigurationMap = new Map<string, chargeCodeConfigurationMapObject>();
        const invoiceChargeCodeList: Array<string> = invoiceChargeCodes.split(",").map((invoiceChargeCode) => invoiceChargeCode.trim());
        const updatedChargeCodeConfigurationList: Array<chargeCodeConfigurationObject> = [];


        chargeCodeConfigurationList.forEach((chargeCodeConfiguration: chargeCodeConfigurationObject) => {
            chargeCodeConfigurationMap.set(chargeCodeConfiguration.invoiceChargeCode, {
                version: chargeCodeConfiguration.version,
                chargeType: chargeCodeConfiguration.chargeType
            });
        });


        /*
            If the invoice charge code doesn't exist, then add it with version as 0, else the version value .
            INPUT Service internally increment the version value by 1.
        */
        allInvoiceChargeCodes.forEach((invoiceChargeCode, index) => {

            let chargeCodeVersion = constants.ZERO_VERSION;

            if(chargeCodeConfigurationMap.has(invoiceChargeCode)) {
                let chargeCodeConfiguration: chargeCodeConfigurationMapObject = chargeCodeConfigurationMap.get(invoiceChargeCode)!;
                chargeCodeVersion = chargeCodeConfiguration.version;
            }
            if (selectedChargeType.value != null) {
                updatedChargeCodeConfigurationList[index] = {
                    chargeType: selectedChargeType.value,
                    invoiceChargeCode: invoiceChargeCode,
                    version: chargeCodeVersion
                };
            }
        });

        setIsSaveLoading(true);
        const saveGLDetails = () => {
            Object.keys(gLCodingRuleConfigurationMap).forEach(ruleConfigurationKey => {
                if (ruleConfigurationKey.startsWith("chrgCode")) {
                    const ruleArgumentsMap = JSON.parse(JSON.stringify(gLCodingRuleConfigurationMap[ruleConfigurationKey].ruleArgumentMap));
                    const accountNumberValueMap: any = {};
                    const ruleConfigurationKeyName = `${ruleConfigurationKey}Map`;
                    allInvoiceChargeCodes.forEach((accountNo: string) => accountNumberValueMap[accountNo] = glSelectedData[ruleConfigurationKey]?.value);
                    ruleArgumentsMap[ruleConfigurationKeyName].value = { ...ruleArgumentsMap[ruleConfigurationKeyName]?.value, ...accountNumberValueMap };

                    const glData = {
                        "operation": constants.UPDATE_SIMPLE_RULE_ARGUMENTS,
                        "request_body": {
                            "ruleName": ruleConfigurationKey,
                            "ruleTreeId": gLCodingRuleTreeId,
                            "ruleValidationType": "GLCoding",
                            "scac": props.scac,
                            "shipmentAccountType": props.accountType,
                            ruleArgumentsMap
                        }
                    }
                    placePostRequest(glData, () => {
                        setSecondaryMessages((lastVal) => {
                            return { ...lastVal, [ruleConfigurationKey]: { type: "success", content: `${ruleConfigurationKey} successfully updated!` } }
                        })
                    },
                        () => {
                            setSecondaryMessages((lastVal) => {
                                return { ...lastVal, [ruleConfigurationKey]: { type: "error", content: `${ruleConfigurationKey} update failed!` } }
                            })
                        });
                }
            })
        }
        const data = {
            "operation": constants.CONFIGURE_CHARGE_CODE_MAPPING,
            "request_body": {
                "scac": props.scac,
                "shipmentAccountType": props.accountType,
                "manifestChargeCode": manifestChargeCode,
                "invoiceChargeCodeList": invoiceChargeCodeList,
                "chargeCodeConfigurationList": updatedChargeCodeConfigurationList
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setSecondaryMessages({});
            saveGLDetails();
            setIsSaveLoading(false);
            setMessage(UPDATE_SUCCESS_FLASH_MESSAGE);
            props.updateChargeCodeTable();
        }
        const onError = (err: AxiosError) => {
            setSecondaryMessages({});
            setIsSaveLoading(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }


    /*
        This deletes charge code configurations on SCAC level.
    */
    function removeChargeCode() {

        if (!isDropdownFieldValid(selectedInvoiceChargeCode, DEFAULT_CHARGE_CODE_OPTION,
                constants.SELECTED_CHARGE_CODE_ERROR_TEXT, setSelectedInvoiceChargeCodeError)
            || !isFieldValid(commentsToDeleteChargeCode, constants.COMMENTS_ERROR_TEXT, setCommentsToDeleteChargeCodeError)) {
            return;
        }

        const chargeCodeConfigurationList: chargeCodeConfigurationObject[] = props.chargeCodeConfigurationList.slice();
        let version: string = constants.EMPTY_STRING;
        chargeCodeConfigurationList.forEach((chargeCodeConfiguration: chargeCodeConfigurationObject) => {
            if (chargeCodeConfiguration.invoiceChargeCode == selectedInvoiceChargeCode.value)
                version = chargeCodeConfiguration.version;
        });
        setIsDeleteLoading(true);

        const data = {
            "operation": constants.REMOVE_CHARGE_CODE_FOR_SCAC,
            "request_body": {
                "scac": props.scac,
                "invoiceChargeCode": selectedInvoiceChargeCode.value,
                "version": version,
                "comments": commentsToDeleteChargeCode
            }
        }
        const onSuccess = (res: AxiosResponse) => {
            setSecondaryMessages({});
            setIsDeleteLoading(false);
            setMessage(DELETE_SUCCESS_FLASH_MESSAGE);
            props.updateChargeCodeTable();
        }
        const onError = (err: AxiosError) => {
            setSecondaryMessages({});
            setIsDeleteLoading(false);
            setMessage(getErrorFlashMessageProps(err));
        }
        placePostRequest(data, onSuccess, onError);
    }

    function setGlSelectionErrorWrapper(key: string, value = constants.EMPTY_STRING) {
        setGlSelectionError((lastVal) => { return { ...lastVal, [key]: value } });
    }

    const handelGlCodeSelection = (rule: string, option?: string) => {
        setGlSelectedData((lastValue) => {
            return { ...lastValue, [rule]: { value: option } };
        })
    } 

    return(
        <div>
            <Container
                header = {
                    <Header variant="h2">
                        {constants.CHARGE_CODE_CONFIGURATION}
                    </Header>
                }
            >
                <div>
                    {
                        props.chargeCodeLoading ?
                            <StatusIndicator type="loading">{constants.LOADING}</StatusIndicator>
                            :
                            <div>
                                {
                                    addNewChargeCode ?
                                        <SpaceBetween size={"l"}>
                                            <MatrixFlashMessage content={[message]} />
                                            <MatrixFlashMessage content={Object.values(secondaryMessages)} />
                                            <Form
                                                actions={
                                                    <SpaceBetween direction="horizontal" size="xs">
                                                        <Button variant="link" onClick={toggleNewChargeCode}>{constants.CANCEL}</Button>
                                                        <MatrixSaveModal buttonName={constants.SAVE} buttonLoading={isSaveLoading} onClickConfirm={saveNewChargeCode}/>
                                                    </SpaceBetween>
                                                }
                                                header={
                                                    <Header
                                                        variant="h3"
                                                    >
                                                        {constants.ADD_NEW_CHARGE_CODE}
                                                    </Header>
                                                }
                                            >
                                                <SpaceBetween direction="vertical" size="l">
                                                    <FormField
                                                        errorText={manifestChargeCodeError}
                                                        label={constants.MANIFEST_CHARGE_CODE}>
                                                        <Input value={manifestChargeCode} onChange={({ detail }) => {
                                                            isManifestChargeCodeInputValid(detail.value, setManifestChargeCodeError);
                                                            setManifestChargeCode(detail.value);
                                                        }}/>
                                                    </FormField>
                                                    <FormField
                                                        errorText={selectedChargeTypeError}
                                                        label={constants.CHARGE_TYPE}>
                                                        <Select
                                                            selectedOption={selectedChargeType}
                                                            onChange={({ detail }) => {
                                                                setSelectedChargeType(detail.selectedOption);
                                                                setSelectedChargeTypeError(constants.EMPTY_STRING);
                                                            }}
                                                            options={
                                                                props.allChargeTypes.sort().map(option => {
                                                                    return {
                                                                        value: option
                                                                    }
                                                                })
                                                            }
                                                            expandToViewport={true}
                                                            filteringType="auto"
                                                            selectedAriaLabel="Selected"
                                                        />
                                                    </FormField>
                                                    <FormField
                                                        constraintText="Multiple charges can be separated by comma."
                                                        errorText={invoiceChargeCodesError}
                                                        label={constants.INVOICE_CHARGE_CODES}>
                                                        <Input value={invoiceChargeCodes} onChange={({ detail }) => {
                                                            isInvoiceChargeCodeInputValid(detail.value, setInvoiceChargeCodesError);
                                                            setInvoiceChargeCodes(detail.value);
                                                        }}/>
                                                    </FormField>
                                                    {!!glCodeDropdownData?.length &&
                                                        <SpaceBetween size={"l"}>
                                                            <hr />
                                                            <div>
                                                                {constants.GL_SEGMENT_HEADING}
                                                            </div>
                                                            {glCodeDropdownData.map((ruleData) =>
                                                                <FormField
                                                                    key={ruleData.rule}
                                                                    errorText={glSelectionError[ruleData.rule]}
                                                                    label={ruleData.rule}>
                                                                    <Select
                                                                        selectedOption={glSelectedData[ruleData.rule]}
                                                                        onChange={({ detail }) => {
                                                                            handelGlCodeSelection(ruleData.rule, detail.selectedOption.value);
                                                                            setGlSelectionErrorWrapper(ruleData.rule)
                                                                        }}
                                                                        options={
                                                                            ruleData.values?.sort().map((option: string) => {
                                                                                return {
                                                                                    value: option
                                                                                }
                                                                            })
                                                                        }
                                                                        expandToViewport={true}
                                                                        filteringType="auto"
                                                                        selectedAriaLabel="Selected"
                                                                    />
                                                                </FormField>
                                                            )}
                                                        </SpaceBetween>
                                                    }  
                                                </SpaceBetween>
                                            </Form>
                                        </SpaceBetween>
                                        :
                                        deleteChargeCode ?
                                            <SpaceBetween size={"l"}>
                                                <MatrixFlashMessage content={[message]}/>
                                                <MatrixFlashMessage content={Object.values(secondaryMessages)}/>
                                                <Form
                                                    actions={
                                                        <SpaceBetween direction="horizontal" size="xs">
                                                            <Button variant="link"
                                                                    onClick={toggleDeleteChargeCode}>{constants.CANCEL}</Button>
                                                            <MatrixDeleteModal buttonName="Delete Charge Code"
                                                                               alertMessage={`Deletion of charge code will happen on SCAC level. 
                                                                                    Charge Code will be deleted from all the Shipment Account Types.`}
                                                                               buttonLoading={isDeleteLoading}
                                                                               onClickConfirm={removeChargeCode}/>
                                                        </SpaceBetween>
                                                    }
                                                    header={
                                                        <Header
                                                            variant="h3"
                                                        >
                                                            {constants.DELETE_CHARGE_CODE}
                                                        </Header>
                                                    }
                                                >
                                                    <SpaceBetween direction="vertical" size="l">
                                                        <FormField
                                                            errorText={selectedInvoiceChargeCodeError}
                                                            label={constants.INVOICE_CHARGE_CODE}>
                                                            <Select
                                                                selectedOption={selectedInvoiceChargeCode}
                                                                onChange={({ detail }) => {
                                                                    setSelectedInvoiceChargeCode(detail.selectedOption);
                                                                    setSelectedInvoiceChargeCodeError(constants.EMPTY_STRING);
                                                                }}
                                                                options={
                                                                    props.chargeCodeConfigurationList.slice().sort().map(option => {
                                                                        return {
                                                                            value: option.invoiceChargeCode
                                                                        }
                                                                    })
                                                                }
                                                                expandToViewport={true}
                                                                filteringType="auto"
                                                                selectedAriaLabel="Selected"
                                                            />
                                                        </FormField>
                                                        <FormField
                                                            errorText={commentsToDeleteChargeCodeError}
                                                            label={constants.COMMENTS}>
                                                            <Input value={commentsToDeleteChargeCode}
                                                                   placeholder="Enter Reason for deletion, Sim Link."
                                                                   onChange={({detail}) => {
                                                                       isFieldValid(detail.value, constants.COMMENTS_ERROR_TEXT, setCommentsToDeleteChargeCodeError);
                                                                       setCommentsToDeleteChargeCode(detail.value);
                                                                   }}/>
                                                        </FormField>
                                                        <Alert
                                                            type={"warning"}
                                                            header={"Please note that charge code deletion is at SCAC level. " +
                                                                "Deletion of Charge Code will delete from all the shipment account types associated with this SCAC."}
                                                        />
                                                    </SpaceBetween>
                                                </Form>
                                            </SpaceBetween>
                                            :
                                            <SpaceBetween size={"l"} direction={"horizontal"}>
                                                <Button variant={"normal"} onClick={toggleNewChargeCode}>+ Add Charge Code</Button>
                                                <Button variant={"normal"} onClick={toggleDeleteChargeCode}>+ Delete Charge Code</Button>
                                                <Table
                                                    columnDefinitions={[
                                                        {
                                                            id: constants.CHARGE_TYPE,
                                                            header: constants.CHARGE_TYPE,
                                                            cell: item => item.chargeType || "-"
                                                        },
                                                        {
                                                            id: constants.MANIFEST_CHARGE_CODE,
                                                            header: constants.MANIFEST_CHARGE_CODE,
                                                            cell: item => item.manifestChargeCode || "-"
                                                        },
                                                        {
                                                            id: constants.INVOICE_CHARGE_CODES,
                                                            header: constants.INVOICE_CHARGE_CODES,
                                                            cell: item => item.invoiceChargeCodes.join(", ") || "-"
                                                        }
                                                    ]}
                                                    wrapLines={true}
                                                    items={getChargeCodeListFromMapping(props.chargeCodeConfigurationList, props.chargeCodeMapList)}
                                                    empty={
                                                        <Box textAlign="center" color="inherit">
                                                            <b>No resources</b>
                                                            <Box
                                                                padding={{bottom: "s"}}
                                                                variant="p"
                                                                color="inherit"
                                                            >
                                                                {constants.NO_RESOURCES_TO_DISPLAY}
                                                            </Box>
                                                        </Box>
                                                    }
                                                />
                                            </SpaceBetween>
                                }
                            </div>
                    }
                </div>
            </Container>
        </div>
    );
}

export default ChargeCodeConfiguration;
